.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
.navbar {
  background-color: #fff;
}
.navbar-nav-wrap-content-left .navbar-nav > .nav-item:not(:last-child),
.navbar-nav-wrap-content-right .navbar-nav > .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}
.navbar:not(.navbar-vertical).nav-item:not(:last-child) {
  margin-right: 0.5rem;
}
.navbar .nav-link {
  color: #132144;
}
.navbar .nav-link:hover {
  color: #01684b;
}
.navbar .nav-link.active {
  color: #01684b;
}
.navbar .active > .nav-link,
.navbar .nav-link.active,
.navbar .nav-link.show,
.navbar .show > .nav-link {
  color: #01684b;
}
.navbar .nav-pills .show > .nav-link {
  color: #fff;
}
.navbar:not(.navbar-vertical) .nav-link-toggle::after,
.navbar:not(.navbar-vertical) .nav-link-toggle[data-toggle="collapse"]::after {
  padding-left: 1.5rem;
}
.navbar .navbar-dropdown-menu .dropdown-menu:not(.navbar-dropdown-sub-menu) {
  position: absolute;
}
.navbar .dropdown-menu.hs-mega-menu {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar .hs-sub-menu.dropdown-menu .dropdown-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.navbar .dropdown-item-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.navbar .dropdown-item-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.78,11.28a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-5.14,5.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L13.67,12,9.22,7.56a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: "";
  margin-left: auto;
  transition: 0.3s;
}
.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.main-page {
  padding-top: 3.75rem;
}
@media (min-width: 576px) {
  .navbar-fixed-sm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-sm ~ .main {
    padding-top: 3.75rem;
  }
}
@media (min-width: 768px) {
  .navbar-fixed-md {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-md ~ .main {
    padding-top: 3.75rem;
  }
}
@media (min-width: 992px) {
  .navbar-fixed-lg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-lg ~ .main {
    padding-top: 3.75rem;
  }
}
@media (min-width: 1200px) {
  .navbar-fixed-xl {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-xl ~ .main {
    padding-top: 3.75rem;
  }
}
@media (min-width: 1400px) {
  .navbar-fixed-xxl {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .navbar-fixed-xxl ~ .main {
    padding-top: 3.75rem;
  }
}
.navbar-flush .navbar {
  padding-right: 1rem;
}
.navbar-bordered {
  border-width: 0 0 0.0625rem;
  border-style: solid;
  border-color: #e7eaf3;
}
.navbar-bordered.navbar-vertical {
  border-width: 0 0.0625rem 0 0;
}
.navbar-shadow {
  box-shadow: 0 6px 24px 0 rgba(140, 152, 164, 0.125);
}
.navbar-brand {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-brand-logo {
  height: 44px;
  max-width: 6.5rem;
}
.navbar-brand-logo-short {
  width: 100%;
  min-width: 2rem;
  max-width: 2rem;
}
.navbar-brand-wrapper {
  position: relative;
  overflow-x: hidden;
}
.navbar-toggler {
  background-color: #f8fafd;
  border-color: transparent;
}
.navbar-toggler:hover {
  background-color: #f0f4fb;
}
.navbar-toggler.btn-white {
  border-color: #e7eaf3;
}
.navbar-toggler.btn-white.active,
.navbar-toggler.btn-white:active,
.navbar-toggler.btn-white:focus,
.navbar-toggler.btn-white:hover {
  color: #0052ea;
  background-color: #fff;
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.navbar-toggler .navbar-toggle-default {
  display: -ms-flexbox;
  display: flex;
}
.navbar-toggler .navbar-toggle-toggled {
  display: none;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggle-default {
  display: none;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggle-toggled {
  display: -ms-flexbox;
  display: flex;
}
.navbar-collapse .navbar-nav {
  margin-top: 0;
}
.navbar-nav-mega-menu {
  width: 100%;
}
.navbar-nav-mega-menu .dropdown-item,
.navbar-nav-mega-menu .navbar-nav-mega-menu-title {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.navbar-nav-mega-menu .navbar-nav-mega-menu-title {
  display: block;
}
.navbar-input-group {
  min-width: 21rem;
}
.navbar-input-group input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.navbar-input-group input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.navbar-input-group input[type="search"]::-webkit-search-cancel-button,
.navbar-input-group input[type="search"]::-webkit-search-decoration,
.navbar-input-group input[type="search"]::-webkit-search-results-button,
.navbar-input-group input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.navbar-dropdown-menu {
  position: absolute !important;
  margin-top: 1.25rem;
}
.navbar-dropdown-menu:not(.navbar-dropdown-account) {
  padding: 0;
}
.navbar-dropdown-account {
  margin-top: 0.5rem;
}
.navbar-dropdown-account-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #1e2022;
  background-color: transparent;
  padding: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 6.1875rem;
  transition: 0.3s;
}
.navbar-dropdown-account-wrapper:focus,
.navbar-dropdown-account-wrapper:hover {
  background-color: rgba(55, 125, 255, 0.1);
}
.navbar-dropdown-account-body {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.navbar-card-list-group {
  margin: 0.5rem 0 0.5rem 0.5rem;
}
.navbar-card-list-group .list-group-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.navbar-expand .navbar-vertical.navbar-collapse {
  display: block !important;
}
.navbar-expand .navbar-vertical.navbar-collapse .navbar-nav {
  -ms-flex-direction: column;
  flex-direction: column;
}
.navbar-expand .navbar-vertical.navbar-vertical-fixed {
  height: 100%;
}
.navbar-expand.navbar {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand.navbar-height {
  height: 3.75rem;
}
.navbar-expand .navbar-brand-wrapper-width {
  width: 100%;
  max-width: 16.25rem;
}
.navbar-expand .navbar-nav .navbar-nav-link {
  line-height: 2.3125rem;
}
.navbar-expand .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
  padding-left: 0;
}
.navbar-expand .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
  margin-top: 0;
}
.navbar-expand.navbar:not(.navbar-vertical) .nav-indicator-icon {
  display: none;
}
.navbar-expand .navbar-nav-mega-menu-body {
  padding: 1rem 0.5rem;
  margin-left: 0;
  margin-right: 0;
}
.navbar-expand .dropdown-menu {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
}
.navbar-expand .dropdown-menu .hs-has-sub-menu .dropdown-menu {
  margin-top: -1rem;
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-sm .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-sm .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-sm.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-sm .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-sm .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-sm .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }
  .navbar-expand-sm.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-sm .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-sm .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-md .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-md .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-md.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-md .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-md .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-md .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }
  .navbar-expand-md.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-md .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-md .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-md .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-lg .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-lg .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-lg.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-lg .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-lg .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-lg .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }
  .navbar-expand-lg.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-lg .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-lg .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-xl .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-xl .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-xl.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-xl .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-xl .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-xl .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }
  .navbar-expand-xl.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-xl .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-vertical.navbar-collapse {
    display: block !important;
  }
  .navbar-expand-xxl .navbar-vertical.navbar-collapse .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-expand-xxl .navbar-vertical.navbar-vertical-fixed {
    height: 100%;
  }
  .navbar-expand-xxl.navbar {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xxl.navbar-height {
    height: 3.75rem;
  }
  .navbar-expand-xxl .navbar-brand-wrapper-width {
    width: 100%;
    max-width: 16.25rem;
  }
  .navbar-expand-xxl .navbar-nav .navbar-nav-link {
    line-height: 2.3125rem;
  }
  .navbar-expand-xxl .navbar-nav .navbar-nav-item:first-child .navbar-nav-link {
    padding-left: 0;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu:not(.navbar-dropdown-menu) {
    margin-top: 0;
  }
  .navbar-expand-xxl.navbar:not(.navbar-vertical) .nav-indicator-icon {
    display: none;
  }
  .navbar-expand-xxl .navbar-nav-mega-menu-body {
    padding: 1rem 0.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xxl .dropdown-menu {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  }
  .navbar-expand-xxl .dropdown-menu .hs-has-sub-menu .dropdown-menu {
    margin-top: -1rem;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-sm .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-sm
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-sm.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-sm .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-sm .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-sm .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-sm .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-sm .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-sm.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }
  .navbar-expand-sm .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-sm.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-sm
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-sm.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-sm .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-sm .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-sm .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-sm .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-sm .dropdown-item-toggle::after,
  .navbar-expand-sm .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-sm .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-sm .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-sm
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-sm .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-sm .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-sm .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-sm
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-sm
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-sm .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-sm .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-sm
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-sm ~ .sidebar-detached-content {
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-md .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-md
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-md.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-md .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-md .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-md .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-md .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-md .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-md.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }
  .navbar-expand-md .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-md.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-md
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-md.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-md .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-md .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-md .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-md .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-md .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-md .dropdown-item-toggle::after,
  .navbar-expand-md .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-md .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-md .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-md
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-md .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-md .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-md .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-md .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-md
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-md
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-md .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-md .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-md
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-md ~ .sidebar-detached-content {
    margin-left: 0;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-lg .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-lg.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-lg .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-lg .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-lg .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-lg .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-lg .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-lg.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }
  .navbar-expand-lg .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-lg.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-lg
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-lg.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-lg .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-lg .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-lg .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-lg .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-lg .dropdown-item-toggle::after,
  .navbar-expand-lg .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-lg .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-lg .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-lg
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-lg .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-lg .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-lg .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-lg
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-lg
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-lg .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-lg .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-lg
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-lg ~ .sidebar-detached-content {
    margin-left: 0;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xl .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xl.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-xl .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-xl .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xl .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-xl .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xl .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xl.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }
  .navbar-expand-xl .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-xl.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-xl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-xl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-xl .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-xl .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-xl .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-xl .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-xl .dropdown-item-toggle::after,
  .navbar-expand-xl .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xl .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xl .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xl
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xl .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xl .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xl .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xl
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xl
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-xl .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-xl .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-xl
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-xl ~ .sidebar-detached-content {
    margin-left: 0;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl.navbar-spacer-y {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    background-color: #fff;
  }
  .navbar-expand-xxl .navbar-collapse .navbar-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xxl .navbar-vertical .navbar-vertical-footer {
    display: none;
  }
  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xxl
    .navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu),
  .navbar-expand-xxl.navbar-light
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu) {
    background-color: transparent;
  }
  .navbar-expand-xxl .navbar-body {
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .navbar-expand-xxl .navbar-body::-webkit-scrollbar {
    width: 0.6125rem;
  }
  .navbar-expand-xxl .navbar-body::-webkit-scrollbar-thumb {
    background-color: rgba(189, 197, 209, 0.6);
    visibility: hidden;
  }
  .navbar-expand-xxl .navbar-body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xxl .navbar-dark .navbar-nav .nav-link,
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-item,
  .navbar-expand-xxl.navbar-dark .navbar-nav .nav-link {
    color: #96a2c1;
  }
  .navbar-expand-xxl .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
  .navbar-expand-xxl.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
    color: #fff;
  }
  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider,
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    .dropdown-divider {
    border-color: #192b58;
  }
  .navbar-expand-xxl
    .navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover,
  .navbar-expand-xxl.navbar-dark
    .navbar-nav
    .dropdown-menu:not(.navbar-dropdown-menu)
    [href].dropdown-item:hover {
    color: #fff;
  }
  .navbar-expand-xxl .navbar-nav-mega-menu .dropdown-item,
  .navbar-expand-xxl .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xxl .hs-mega-menu {
    max-width: 100% !important;
  }
  .navbar-expand-xxl .hs-mega-menu-opened .nav-link-toggle::after,
  .navbar-expand-xxl .hs-sub-menu-opened .nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  .navbar-expand-xxl .dropdown-item-toggle::after,
  .navbar-expand-xxl .navbar-dropdown-submenu-item-invoker::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xxl .hs-sub-menu-opened > .dropdown-item-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xxl .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .navbar-expand-xxl
    .navbar-dropdown-submenu-item.hs-active
    .navbar-dropdown-submenu-item-invoker {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .navbar-expand-xxl
    .hs-has-sub-menu
    .dropdown-menu:not(.navbar-dropdown-menu) {
    box-shadow: none;
    padding: 0;
    margin-top: 0;
  }
  .navbar-expand-xxl .dropdown-menu.navbar-dropdown-sub-menu {
    box-shadow: none;
    border-left: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .navbar-expand-xxl .navbar-nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xxl .navbar-nav-item .navbar-nav-item {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xxl .navbar-nav-item .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xxl .navbar-nav-item > .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xxl
    .hs-has-sub-menu
    .navbar-nav-item
    .hs-sub-menu
    .dropdown-item {
    padding-left: 1.75rem;
  }
  .navbar-expand-xxl
    .hs-has-sub-menu
    .hs-sub-menu
    .hs-has-sub-menu:not(.navbar-nav-item)
    .hs-sub-menu {
    padding-left: 1.75rem;
  }
  .navbar-expand-xxl .hs-has-sub-menu .dropdown-item-toggle {
    padding-right: 0.8125rem;
  }
  .navbar-expand-xxl .navbar.navbar-vertical-detached {
    position: relative;
    width: auto;
  }
  .navbar-expand-xxl
    .navbar.navbar-vertical-detached
    .navbar-vertical-container {
    height: auto;
    min-height: auto;
    max-height: none;
  }
  .navbar-expand-xxl ~ .sidebar-detached-content {
    margin-left: 0;
  }
}
.navbar-expand.navbar-spacer-y {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-expand .navbar-collapse {
  background-color: #fff;
}
.navbar-expand .navbar-collapse .navbar-nav {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.navbar-expand .navbar-vertical .navbar-vertical-footer {
  display: none;
}
.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu),
.navbar-expand
  .navbar-light
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu),
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu),
.navbar-expand.navbar-light
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu) {
  background-color: transparent;
}
.navbar-expand .navbar-body {
  max-height: 75vh;
  overflow: hidden;
  overflow-y: auto;
}
.navbar-expand .navbar-body::-webkit-scrollbar {
  width: 0.6125rem;
}
.navbar-expand .navbar-body::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
  visibility: hidden;
}
.navbar-expand .navbar-body:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-item,
.navbar-expand .navbar-dark .navbar-nav .nav-link,
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-item,
.navbar-expand.navbar-dark .navbar-nav .nav-link {
  color: #96a2c1;
}
.navbar-expand .navbar-dark .navbar-nav .navbar-nav-mega-menu-title,
.navbar-expand.navbar-dark .navbar-nav .navbar-nav-mega-menu-title {
  color: #fff;
}
.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-divider,
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .dropdown-divider {
  border-color: #192b58;
}
.navbar-expand
  .navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  [href].dropdown-item:hover,
.navbar-expand.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  [href].dropdown-item:hover {
  color: #fff;
}
.navbar-expand .navbar-nav-mega-menu .dropdown-item,
.navbar-expand .navbar-nav-mega-menu .navbar-nav-mega-menu-title {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.navbar-expand .hs-mega-menu {
  max-width: 100% !important;
}
.navbar-expand .hs-mega-menu-opened .nav-link-toggle::after,
.navbar-expand .hs-sub-menu-opened .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.navbar-expand .dropdown-item-toggle::after,
.navbar-expand .navbar-dropdown-submenu-item-invoker::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navbar-expand .hs-sub-menu-opened > .dropdown-item-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.navbar-expand .navbar-dropdown-submenu-item-invoker {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navbar-expand
  .navbar-dropdown-submenu-item.hs-active
  .navbar-dropdown-submenu-item-invoker {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.navbar-expand .hs-has-sub-menu .dropdown-menu:not(.navbar-dropdown-menu) {
  box-shadow: none;
  padding: 0;
  margin-top: 0;
}
.navbar-expand .dropdown-menu.navbar-dropdown-sub-menu {
  box-shadow: none;
  border-left: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
}
.navbar-expand .navbar-nav-item {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-expand .navbar-nav-item .navbar-nav-item {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav-item .dropdown-item {
  padding-left: 1.75rem;
}
.navbar-expand .navbar-nav-item > .dropdown-item {
  padding-left: 1.75rem;
}
.navbar-expand .hs-has-sub-menu .navbar-nav-item .hs-sub-menu .dropdown-item {
  padding-left: 1.75rem;
}
.navbar-expand
  .hs-has-sub-menu
  .hs-sub-menu
  .hs-has-sub-menu:not(.navbar-nav-item)
  .hs-sub-menu {
  padding-left: 1.75rem;
}
.navbar-expand .hs-has-sub-menu .dropdown-item-toggle {
  padding-right: 0.8125rem;
}
.navbar-expand .navbar.navbar-vertical-detached {
  position: relative;
  width: auto;
}
.navbar-expand .navbar.navbar-vertical-detached .navbar-vertical-container {
  height: auto;
  min-height: auto;
  max-height: none;
}
.navbar-expand ~ .sidebar-detached-content {
  margin-left: 0;
}
.navbar.navbar-container {
  padding-right: 2rem;
  padding-left: 2rem;
}
@media (max-width: 575.98px) {
  [class*="navbar-expand"].navbar {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.navbar-nav-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-nav-wrap-content-left {
  -ms-flex-align: center;
  align-items: center;
}
.navbar-nav-wrap-content-right {
  margin-left: auto;
}
.navbar-expand .navbar-nav-wrap-content-left {
  -ms-flex-order: 2;
  order: 2;
  display: -ms-flexbox;
  display: flex;
  padding-left: 2rem;
}
.navbar-expand .navbar-nav-wrap-content-right {
  -ms-flex-order: 4;
  order: 4;
}
.navbar-expand .navbar-nav-wrap-toggle {
  -ms-flex-order: 4;
  order: 4;
  text-align: right;
}
.navbar-expand .navbar-nav-wrap-navbar {
  -ms-flex-order: 3;
  order: 3;
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-sm .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-sm .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-sm .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-md .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-md .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-md .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-lg .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-lg .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-lg .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-xl .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-xl .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-xl .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-nav-wrap-content-left {
    -ms-flex-order: 2;
    order: 2;
    display: -ms-flexbox;
    display: flex;
    padding-left: 2rem;
  }
  .navbar-expand-xxl .navbar-nav-wrap-content-right {
    -ms-flex-order: 4;
    order: 4;
  }
  .navbar-expand-xxl .navbar-nav-wrap-toggle {
    -ms-flex-order: 4;
    order: 4;
    text-align: right;
  }
  .navbar-expand-xxl .navbar-nav-wrap-navbar {
    -ms-flex-order: 3;
    order: 3;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl .navbar-nav-wrap {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav-wrap-content-right {
    margin-left: auto;
  }
}
.navbar-expand .navbar-nav-wrap {
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav-wrap-content-right {
  margin-left: auto;
}
.navbar-vertical.navbar {
  display: block;
  padding: 0;
}
.navbar-vertical.navbar.collapse:not(.show) {
  display: none;
}
.navbar-vertical.navbar .nav-item {
  white-space: nowrap;
}
.navbar-vertical.navbar .nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-vertical.navbar .nav-subtitle-replacer {
  display: none;
  color: #677788;
  text-align: center;
  font-size: 1.3125rem;
  padding: 0.5rem 0;
}
.navbar-vertical-container {
  position: relative;
  height: 100%;
  min-height: 100%;
}
.navbar-vertical-fixed .navbar-vertical-container {
  max-height: 100%;
}
.navbar-vertical-content {
  overflow: hidden;
}
.navbar-vertical .nav-link {
  color: #132144;
}
.navbar-vertical .nav-link.disabled {
  color: #99a7ba;
}
.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 2.75rem;
}
.navbar-vertical .nav-sub {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: left;
}
.navbar-vertical .nav-sub .nav-link {
  font-size: 0.875rem;
}
.navbar-vertical .nav-sub.nav .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-vertical .nav-subtitle {
  padding: 0.75rem 0.8125rem;
}
.navbar-vertical .navbar-nav.nav-tabs {
  border-bottom-width: 0;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-item:not(:last-child) {
  margin: 0;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-link,
.navbar-vertical .navbar-nav.nav-tabs .nav-link.active {
  border-bottom-width: 0;
  border-radius: 0;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.navbar-vertical .navbar-nav.nav-tabs .nav-link {
  border-width: 0 0 0 0.2rem;
  margin-bottom: 0;
}
.navbar-vertical .navbar-nav.nav-tabs .active .nav-link,
.navbar-vertical .navbar-nav.nav-tabs .active.nav-link {
  border-left-color: #01684b;
}
.navbar-vertical
  .navbar-nav.nav-tabs
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  margin-right: 0;
}
.navbar-vertical .nav-link-toggle::after,
.navbar-vertical .nav-link-toggle[data-toggle="collapse"]::after {
  transition: 0.3s ease;
}
.navbar-vertical .nav-link-toggle[aria-expanded="true"]::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.navbar-vertical .navbar-vertical-has-menu {
  position: relative;
}
.navbar-vertical .active .nav-indicator-icon,
.navbar-vertical .nav-link:hover .nav-indicator-icon,
.navbar-vertical .show > .nav-link > .nav-indicator-icon {
  color: #01684b;
}
.navbar-vertical .nav-compact,
.navbar-vertical .nav-compact-icon {
  -ms-flex-direction: column;
  flex-direction: column;
}
.navbar-vertical .nav-compact .nav-compact-title,
.navbar-vertical .nav-compact-icon .nav-compact-title {
  max-width: 8.5rem;
}
.navbar-vertical .nav-compact .nav-link,
.navbar-vertical .nav-compact-icon .nav-link {
  display: block;
}
.navbar-vertical .nav-compact .nav-sub .nav-link,
.navbar-vertical .nav-compact-icon .nav-sub .nav-link {
  display: -ms-flexbox;
  display: flex;
}
.navbar-vertical-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16.25rem;
  margin-left: -16.25rem;
}
.navbar-vertical-fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 16.25rem;
  /* margin-left: 0; */
  margin-left: -16.25rem;
}
.navbar-vertical-fixed .navbar-vertical-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.navbar-vertical-fixed .navbar-vertical-footer-offset {
  height: 100%;
}
.navbar-vertical:not([class*="container"]) .navbar-nav.navbar-nav-lg .nav-link {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.navbar-vertical:not([class*="container"]) .navbar-nav .nav-link {
  padding: 0.375rem 1.75rem;
}
.navbar-vertical:not([class*="container"]) .navbar-nav .nav .nav-link {
  padding-right: 1.75rem;
  padding-left: 3.25rem;
  margin-top: 0;
  margin-bottom: 0;
}
.navbar-vertical:not([class*="container"]) .navbar-nav .nav .nav .nav-link {
  padding-right: 1.75rem;
  padding-left: 4.25rem;
}
.navbar-vertical:not([class*="container"])
  .navbar-nav
  .nav
  .nav
  .nav
  .nav-link {
  padding-left: 5.25rem;
}
.navbar-vertical:not([class*="container"]) .navbar-nav .nav-subtitle,
.navbar-vertical:not([class*="container"]) .navbar-nav .nav-subtitle-replacer,
.navbar-vertical:not([class*="container"]) .navbar-nav .nav-title {
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}
.navbar-vertical:not([class*="container"])
  .navbar-vertical-without-icons
  .nav
  .nav-link {
  padding-left: 3.25rem;
}
.navbar-vertical:not([class*="container"])
  .navbar-vertical-without-icons
  .nav
  .nav
  .nav-link {
  padding-left: 4.25rem;
}
.navbar-vertical:not([class*="container"]) .navbar-nav.nav-compact,
.navbar-vertical:not([class*="container"]) .navbar-nav.nav-compact-icon {
  margin-left: 0;
}
.navbar-vertical:not([class*="container"]) .navbar-nav.nav-compact .nav-link,
.navbar-vertical:not([class*="container"])
  .navbar-nav.nav-compact-icon
  .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-vertical-footer {
  border-top: 0.0625rem solid #e7eaf3;
  padding: 0.75rem 2rem;
}
.navbar-vertical-footer-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.navbar-vertical-footer-list .navbar-vertical-footer-list-item {
  position: relative;
  margin: 0 0.5rem;
}
.navbar-vertical-footer-dropdown {
  margin-bottom: 1.5rem;
}
.navbar-vertical-detached {
  top: auto;
  left: auto;
  width: 16.25rem;
  margin-left: 0;
  bottom: auto;
}
.navbar-vertical-detached .navbar-vertical-footer {
  position: static;
}
.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav-link,
.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav-subtitle {
  padding-right: 0;
  padding-left: 0;
}
.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav .nav-link {
  padding-right: 0;
  padding-left: 2rem;
}
.navbar.navbar-vertical-detached-no-gutters .navbar-nav .nav .nav .nav-link {
  padding-right: 0;
  padding-left: 3rem;
}
.navbar-vertical-aside-transition-on .footer,
.navbar-vertical-aside-transition-on .main,
.navbar-vertical-aside-transition-on .navbar,
.navbar-vertical-aside-transition-on .navbar-vertical-aside {
  transition: 0.2s ease-in-out;
}
.navbar-vertical-aside-transition-on
  .navbar-vertical-aside
  .navbar-vertical-footer {
  opacity: 0;
}
.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode .main {
  padding-left: 0;
}
.navbar-fixed ~ .main .navbar-vertical-aside {
  top: 0;
}
[class*="navbar-vertical-aside-show"]
  .navbar.splitted-content-navbar
  .navbar-brand-wrapper {
  display: block;
}
[class*="navbar-vertical-aside-show"]
  .navbar-vertical.splitted-content-navbar
  .navbar-brand-wrapper {
  display: -ms-flexbox;
  display: flex;
}
[class*="navbar-vertical-aside-show"] .navbar-nav-wrap-content-left {
  padding-left: 0;
}
.navbar-vertical-aside-mini-mode-hidden-elements {
  -ms-flex: 1;
  flex: 1;
}
.navbar-vertical-aside .navbar-brand-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 3.75rem;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}
.navbar-vertical-aside .navbar-brand-logo {
  display: block;
}
.navbar-vertical-aside .navbar-brand-logo-mini {
  width: 1.9125rem;
  display: none;
}
.navbar-vertical-content {
  height: calc(100% - 3.75rem);
  overflow-y: auto;
}
.navbar-vertical-content::-webkit-scrollbar {
  width: 6px;
}
.navbar-vertical-content::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.nav-footer-item {
  display: none;
}
.nav-footer-item:last-child {
  margin-bottom: 2rem;
}
.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
  position: fixed;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-content {
  height: auto;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer {
  position: static;
  margin-top: -0.0625rem;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer
  .navbar-vertical-footer-list-item {
  position: static;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer
  .navbar-vertical-footer-list-item
  .hs-unfold {
  position: static;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-offset {
  padding-bottom: 0;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-list-item {
  margin: 0.5rem 0;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-dropdown {
  top: 0;
  left: calc(100% + 1rem);
  margin: 0;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-dropdown.hs-unfold-reverse-y {
  top: auto;
  bottom: 0;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-vertical-footer-dropdown::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: "";
}
.navbar-vertical-aside.navbar-vertical-aside-initialized {
  margin-left: 0;
}
.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside.navbar-vertical-aside-initialized {
  margin-left: -16.25rem;
}
.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside.splitted-content-navbar.navbar-vertical-aside-initialized {
  margin-left: -21.5rem;
}
.navbar-vertical-aside-closed-mode .navbar-vertical-aside {
  margin-left: -16.25rem;
}
.navbar-vertical-aside-mini-mode
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  opacity: 1;
}
.navbar-vertical-aside-has-menu > .nav-link-toggle {
  position: relative;
}
.navbar-vertical-aside-has-menu > .nav-link-toggle::before {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #bdc5d1;
  opacity: 0;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: opacity 0.3s;
}
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::before,
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle.active::before,
.navbar-vertical-aside
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle:hover::before {
  background-color: #01684b;
}
.has-navbar-vertical-aside:not(.navbar-vertical-aside-mini-mode)
  .navbar-vertical
  .nav-tabs
  .navbar-vertical-aside-has-menu
  > .active.nav-link {
  border-color: transparent;
}
.has-navbar-vertical-aside:not(.navbar-vertical-aside-mini-mode)
  .navbar-vertical-aside-has-menu.show
  > .nav-sub {
  display: block;
}
.navbar-vertical-aside-has-menu {
  position: relative;
}
.navbar-vertical-aside-has-menu > .nav {
  display: none;
}

.navbar-vertical-aside-has-menu.show > .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-initialized
  .navbar-vertical-container {
  overflow-y: scroll;
  overflow-y: overlay;
}
.navbar-vertical-aside-mini-mode .js-navbar-vertical-aside-toggle-invoker {
  display: block;
}
.navbar-vertical-aside-compact-mode .navbar-vertical-aside {
  width: 9rem !important;
}
.navbar-vertical-aside-compact-mode .navbar-vertical .navbar-brand {
  margin-right: 0;
}
.navbar-vertical-aside-compact-mode .js-navbar-vertical-aside-toggle-invoker {
  display: block;
}
.navbar-vertical-aside-compact-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 15rem;
  background-color: #fff;
  padding-left: 0;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.3125rem;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-mini-mode-hidden-elements {
  display: none;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: none;
}
.navbar-vertical-aside-compact-mini-mode
  .nav-compact-icon
  .navbar-vertical-aside-has-menu.show
  > .nav-link
  svg {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  left: -0.5rem;
  opacity: 1;
}
.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside .nav-subtitle {
  display: none;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside
  .nav-subtitle-replacer {
  display: block;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 15rem;
  background-color: #fff;
  padding-left: 0;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.3125rem;
}
@media (max-width: 575.98px) {
  .navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    min-width: 100%;
  }
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: "";
}
.navbar-vertical-aside-compact-mini-mode
  .splitted-content-navbar
  .splitted-content-mini
  .navbar-nav {
  margin-left: 0;
}
.navbar-vertical-aside-compact-mini-mode
  .splitted-content-navbar
  .splitted-content-mini
  .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav-link-toggle::before {
  left: 0.75rem;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: none;
}
.navbar-vertical-aside-compact-mini-mode .navbar-vertical-container,
.navbar-vertical-aside-mini-mode .navbar-vertical-container {
  position: static;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-container::-webkit-scrollbar,
.navbar-vertical-aside-mini-mode .navbar-vertical-container::-webkit-scrollbar {
  width: 0.6125rem;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-container::-webkit-scrollbar-thumb,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-container::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.navbar-vertical-aside-compact-mini-mode .navbar-vertical-aside-has-menu,
.navbar-vertical-aside-mini-mode .navbar-vertical-aside-has-menu {
  position: static;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu
  .navbar-vertical-aside-has-menu
  > .nav-link-toggle::after {
  display: inline-block;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-has-menu.show
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-has-menu.show
  .navbar-vertical-aside-has-menu.show
  > .nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-absolute:not([class*="container"])
  .nav
  .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-fixed:not([class*="container"])
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-absolute:not([class*="container"])
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-fixed:not([class*="container"])
  .nav
  .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-absolute:not([class*="container"])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-fixed:not([class*="container"])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-absolute:not([class*="container"])
  .nav
  .nav
  .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-vertical-fixed:not([class*="container"])
  .nav
  .nav
  .nav-link {
  padding-left: 2.25rem;
}
.navbar-vertical-aside .splitted-content-mini {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
}
.navbar-vertical-aside-mobile-overlay,
.navbar-vertical-aside-sub-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.2s ease;
}
.navbar-vertical-aside-mobile-overlay {
  background-color: rgba(19, 33, 68, 0.25);
}
.navbar-vertical-aside-mobile-overlay-transparent {
  background-color: transparent;
}
.has-navbar-vertical-aside.navbar-vertical-aside-sub-menu-opened
  .navbar-vertical-aside-sub-menu-overlay,
.has-navbar-vertical-aside:not(.navbar-vertical-aside-closed-mode)
  .navbar-vertical-aside-mobile-overlay {
  z-index: 99;
  opacity: 1;
  width: 100%;
  height: 100%;
}
.navbar-vertical.navbar-expand {
  -ms-flex-flow: column;
  flex-flow: column;
}
.navbar-vertical.navbar-expand .navbar-nav {
  -ms-flex-direction: column;
  flex-direction: column;
}
.navbar-vertical-aside-show .main {
  padding-left: 16.25rem;
}

.navbar-vertical-aside-show .navbar:not(.navbar-vertical) {
  margin-left: 16.25rem;
}
.navbar-vertical-aside-show .splitted-content-navbar {
  margin-left: 21.5rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode .main {
  padding-left: 9rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode .footer {
  margin-left: 9rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode
  .navbar:not(.navbar-vertical) {
  margin-left: 9rem;
}
.navbar-vertical-aside-show.splitted-content .main {
  padding-left: 21.5rem;
}
.navbar-vertical-aside-show.splitted-content .footer {
  margin-left: 21.5rem;
}
.navbar-vertical-aside-show.splitted-content .navbar:not(.navbar-vertical) {
  margin-left: 21.5rem;
}
.navbar-vertical-aside-show .navbar-vertical-aside {
  margin-left: 0;
}
.navbar-vertical-aside-show
  .navbar:not(.navbar-vertical-aside)
  .navbar-brand-wrapper {
  display: none;
}
.navbar-vertical-aside-show
  .navbar-vertical.splitted-content-navbar
  .navbar-brand-wrapper {
  display: none;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar:not(.navbar-vertical) {
  margin-left: 5.25rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .main {
  padding-left: 5.25rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .footer {
  margin-left: 5.25rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside {
  width: 5.25rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .splitted-content-navbar {
  width: 21.5rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .footer,
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .navbar:not(.navbar-vertical),
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .splitted-content-main {
  padding-left: 21.5rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode.splitted-content
  .navbar:not(.navbar-vertical) {
  margin-left: 2rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-mini-mode-hidden-elements {
  display: none;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-brand-logo-mini {
  display: block;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-brand-logo {
  display: none;
}
.navbar-vertical-aside-show:not(.navbar-vertical-closed-mode)
  .navbar-vertical-aside-mobile-overlay {
  display: none;
}
.navbar-vertical-aside-show
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-full-align,
.navbar-vertical-aside-show
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-short-align {
  display: none;
}
.navbar-vertical-aside-show .navbar-vertical-aside-toggle {
  display: none;
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside-initialized
  .navbar-vertical-content {
  overflow-y: scroll;
  overflow-y: overlay;
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside
  .nav,
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mini-mode
  .navbar-vertical-aside
  .nav
  .nav,
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav
  .nav {
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav-subtitle {
  display: none;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .nav-subtitle-replacer {
  display: block;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav {
  position: absolute;
  top: 0;
  left: calc(100% + 0.9125rem);
  z-index: 2;
  min-width: 15rem;
  background-color: #fff;
  padding-left: 0;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.3125rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1.75rem;
  height: 100%;
  content: "";
}
.navbar-vertical-aside-show.navbar-vertical-aside-compact-mode
  .navbar-vertical-content
  > .navbar-nav
  > .navbar-vertical-aside-has-menu
  > .nav::before {
  width: 2rem;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode .nav-footer-item {
  display: block;
}
.navbar-vertical-aside-show.navbar-vertical-aside-mini-mode
  .navbar-vertical-footer {
  display: none;
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-sm .main {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-sm .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-sm .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-sm .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-sm.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-sm
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-sm
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-sm:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-sm
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-sm
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }
  .navbar-vertical-aside-show-sm .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-md .main {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-md .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-md .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-md .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-md.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-md
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-md
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-md:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-md
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-md
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }
  .navbar-vertical-aside-show-md .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-lg .main {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-lg .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-lg .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-lg .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-lg.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-lg
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-lg
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-lg:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-lg
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-lg
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }
  .navbar-vertical-aside-show-lg .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical-fixed {
    
    margin-left: 0;
  }
  .navbar-vertical.navbar-expand-xl {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .main-page {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xl .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xl .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xl .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xl.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-xl
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-xl
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-xl:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-xl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-xl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }
  .navbar-vertical-aside-show-xl .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-xxl .main {
    padding-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xxl .footer {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xxl .navbar:not(.navbar-vertical) {
    margin-left: 16.25rem;
  }
  .navbar-vertical-aside-show-xxl .splitted-content-navbar {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode .main {
    padding-left: 9rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode .footer {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 9rem;
  }
  .navbar-vertical-aside-show-xxl.splitted-content .main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.splitted-content .footer {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl .navbar-vertical-aside {
    margin-left: 0;
  }
  .navbar-vertical-aside-show-xxl
    .navbar:not(.navbar-vertical-aside)
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-xxl
    .navbar-vertical.splitted-content-navbar
    .navbar-brand-wrapper {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar:not(.navbar-vertical) {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .main {
    padding-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode .footer {
    margin-left: 5.25rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside {
    width: 5.25rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-navbar {
    width: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .footer,
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical),
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .splitted-content-main {
    padding-left: 21.5rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode.splitted-content
    .navbar:not(.navbar-vertical) {
    margin-left: 2rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-mini-mode-hidden-elements {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo-mini {
    display: block;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .navbar-brand-logo {
    display: none;
  }
  .navbar-vertical-aside-show-xxl:not(.navbar-vertical-closed-mode)
    .navbar-vertical-aside-mobile-overlay {
    display: none;
  }
  .navbar-vertical-aside-show-xxl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-full-align,
  .navbar-vertical-aside-show-xxl
    .splitted-content-navbar
    .navbar-vertical-aside-toggle-short-align {
    display: none;
  }
  .navbar-vertical-aside-show-xxl .navbar-vertical-aside-toggle {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside-initialized
    .navbar-vertical-content {
    overflow-y: scroll;
    overflow-y: overlay;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav,
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mini-mode
    .navbar-vertical-aside
    .nav
    .nav,
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav
    .nav {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-aside
    .nav-subtitle-replacer {
    display: block;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav {
    position: absolute;
    top: 0;
    left: calc(100% + 0.9125rem);
    z-index: 2;
    min-width: 15rem;
    background-color: #fff;
    padding-left: 0;
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
    border-radius: 0.3125rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    position: absolute;
    top: 0;
    display: block;
    right: calc(100% - 0.0625rem);
    width: 1.75rem;
    height: 100%;
    content: "";
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-compact-mode
    .navbar-vertical-content
    > .navbar-nav
    > .navbar-vertical-aside-has-menu
    > .nav::before {
    width: 2rem;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .nav-footer-item {
    display: block;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-vertical-aside-show-sm .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-sm.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-sm.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-sm.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}
@media (max-width: 767.98px) {
  .navbar-vertical-aside-show-md .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-md.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-md.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-md.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-vertical-aside-show-lg .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-lg.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-lg.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-lg.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-vertical-aside-show-xl .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-xl.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-xl.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}
@media (max-width: 1399.98px) {
  .navbar-vertical-aside-show-xxl .navbar-brand-logo-mini {
    display: none;
  }
  .navbar-vertical-aside-show-xxl.has-navbar-vertical-aside .navbar-height {
    height: 3.75rem;
  }
  .navbar-vertical-aside-show-xxl.has-navbar-vertical-aside
    .navbar-nav-wrap-content-left {
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-aside-show-xxl.navbar-vertical-aside-closed-mode
    .navbar-vertical-aside {
    margin-left: -100%;
  }
}
.navbar-vertical-aside-show .navbar-brand-logo-mini {
  display: none;
}
.navbar-vertical-aside-show.has-navbar-vertical-aside .navbar-height {
  height: 3.75rem;
}
.navbar-vertical-aside-show.has-navbar-vertical-aside
  .navbar-nav-wrap-content-left {
  display: -ms-flexbox;
  display: flex;
}
.navbar-vertical-aside-show.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside {
  margin-left: -100%;
}
.has-navbar-vertical-aside .navbar-vertical-aside-toggle-short-align {
  display: block;
}
.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside-toggle-short-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-toggle-short-align {
  display: none;
}
.has-navbar-vertical-aside .navbar-vertical-aside-toggle-full-align {
  display: none;
}
.has-navbar-vertical-aside.navbar-vertical-aside-closed-mode
  .navbar-vertical-aside-toggle-full-align,
.has-navbar-vertical-aside.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside-toggle-full-align {
  display: block;
}
.splitted-content-navbar .navbar-vertical-aside-toggle-short-align {
  display: block;
}
.navbar-vertical-aside-closed-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-short-align,
.navbar-vertical-aside-mini-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-short-align {
  display: none;
}
.splitted-content-navbar .navbar-vertical-aside-toggle-full-align {
  display: none;
}
.navbar-vertical-aside-closed-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-full-align,
.navbar-vertical-aside-mini-mode
  .splitted-content-navbar
  .navbar-vertical-aside-toggle-full-align {
  display: block;
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.navbar-light {
  background-color: #f9fafc;
}
.navbar-light .navbar-collapse {
  background-color: transparent;
}
.navbar-light .navbar-nav .nav-link {
  color: #132144;
}
.navbar-light .navbar-brand-wrapper {
  background-color: #f9fafc;
}
.navbar-light .navbar-toggler {
  border-color: transparent;
}
.navbar-dark {
  background-color: #132144 !important;
}
.navbar-dark .navbar-collapse {
  background-color: transparent;
}
.navbar-dark .navbar-toggler {
  border-color: transparent;
}
.navbar-dark .navbar-vertical-aside-has-menu.show > .nav-link-toggle::before,
.navbar-dark .navbar-vertical-aside-has-menu > .nav-link-toggle.active::before,
.navbar-dark .navbar-vertical-aside-has-menu > .nav-link-toggle:hover::before {
  background-color: #fff;
}
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .active
  > .nav-link,
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .nav-link.active,
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .nav-link.show,
.navbar-dark
  .navbar-nav
  .dropdown-menu:not(.navbar-dropdown-menu)
  .show
  > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-indicator-icon {
  color: #96a2c1;
}
.navbar-dark .navbar-nav .nav-link {
  color: #96a2c1;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .dropdown-item.active,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.active .nav-indicator-icon,
.navbar-dark .navbar-nav .nav-link:hover .nav-indicator-icon {
  color: #fff;
}
.navbar-dark .navbar-nav .active .nav-indicator-icon,
.navbar-dark .navbar-nav .show > .nav-link > .nav-indicator-icon {
  color: #96a2c1;
}
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link {
  color: #132144;
}
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link.active,
.navbar-dark .navbar-nav .navbar-dropdown-menu .nav-link:hover {
  color: #0052ea;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-sub
  .nav-link,
.navbar-vertical-aside-mini-mode .navbar-dark .navbar-nav .nav-sub .nav-link {
  color: #677788;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-sub
  .nav-link:hover,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-sub
  .nav-link:hover {
  color: #01684b;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .active
  > .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .nav-link.show,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .show
  > .nav-link,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu.show
  .nav-sub
  .nav-link.active,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .active
  > .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .nav-link.show,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu
  .show
  > .nav-link,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .navbar-vertical-aside-has-menu.show
  .nav-sub
  .nav-link.active {
  color: #01684b;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .active
  .nav-indicator-icon,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-link:hover
  .nav-indicator-icon,
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .navbar-nav
  .show
  > .nav-link
  > .nav-indicator-icon,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .active
  .nav-indicator-icon,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .nav-link:hover
  .nav-indicator-icon,
.navbar-vertical-aside-mini-mode
  .navbar-dark
  .navbar-nav
  .show
  > .nav-link
  > .nav-indicator-icon {
  color: #01684b;
}
.navbar-vertical-aside-compact-mini-mode
  .navbar-dark
  .nav-compact-icon
  .navbar-vertical-aside-has-menu.show
  > .nav-link
  svg {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .nav-compact-title {
  color: #96a2c1;
}
.navbar-dark .nav-compact > .nav-item > .nav-link.active,
.navbar-dark .nav-compact > .nav-item > .nav-link:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .nav-compact > .nav-item > .nav-link.active .nav-compact-title,
.navbar-dark .nav-compact > .nav-item > .nav-link:hover .nav-compact-title {
  color: #fff;
}
.navbar-dark .nav-compact > .show > .nav-link {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .nav-compact > .show > .nav-link .nav-compact-title {
  color: #fff;
}
.navbar-dark .nav-compact-icon svg {
  color: #96a2c1;
}
.navbar-dark .nav-compact-icon svg.active,
.navbar-dark .nav-compact-icon svg:focus,
.navbar-dark .nav-compact-icon svg:hover {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .nav-compact-icon .nav-link.active svg {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .nav-compact-icon > .show > .nav-link svg {
  color: #96a2c1;
}
.navbar-dark.navbar-bordered,
.navbar-dark.navbar-bordered .navbar-vertical-footer {
  border-color: #192b58;
}
.navbar-dark .input-group-hover-light .form-control,
.navbar-dark .input-group-hover-light .form-control:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .input-group-hover-light .form-control:focus {
  background-color: #fff;
}
.navbar-dark .navbar-nav .nav-divider {
  border-color: #192b58;
}
.search-fullwidth {
  position: fixed;
  top: 0;
  left: 0 !important;
  width: 100%;
  z-index: 101;
}
.search-fullwidth .form-control {
  height: 3.75rem;
}
.avatar-primary .avatar-initials {
  color: #fff;
  background-color: #01684b;
}
.avatar-status-primary {
  color: #fff;
  background-color: #01684b;
}
.avatar-secondary .avatar-initials {
  color: #fff;
  background-color: #363636;
}
.avatar-status-secondary {
  color: #fff;
  background-color: #363636;
}
.avatar-success .avatar-initials {
  color: #fff;
  background-color: #00c9a7;
}
.avatar-status-success {
  color: #fff;
  background-color: #00c9a7;
}
.avatar-info .avatar-initials {
  color: #fff;
  background-color: #00c9db;
}
.avatar-status-info {
  color: #fff;
  background-color: #00c9db;
}
.avatar-warning .avatar-initials {
  color: #1e2022;
  background-color: #f5ca99;
}
.avatar-status-warning {
  color: #1e2022;
  background-color: #f5ca99;
}
.avatar-danger .avatar-initials {
  color: #fff;
  background-color: #ed4c78;
}
.avatar-status-danger {
  color: #fff;
  background-color: #ed4c78;
}
.avatar-light .avatar-initials {
  color: #1e2022;
  background-color: #f9fafc;
}
.avatar-status-light {
  color: #1e2022;
  background-color: #f9fafc;
}
.avatar-dark .avatar-initials {
  color: #fff;
  background-color: #132144;
}
.avatar-status-dark {
  color: #fff;
  background-color: #132144;
}
.avatar-soft-primary .avatar-initials {
  color: #363636;
  background-color: #01684b;
}
.avatar-status-soft-primary {
  color: #363636;
  background-color: #01684b;
}
.avatar-soft-secondary .avatar-initials {
  color: #363636;
  background-color: rgba(113, 134, 157, 0.1);
}
.avatar-status-soft-secondary {
  color: #363636;
  background-color: rgba(113, 134, 157, 0.1);
}
.avatar-soft-success .avatar-initials {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
.avatar-status-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
.avatar-soft-info .avatar-initials {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}
.avatar-status-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}
.avatar-soft-warning .avatar-initials {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}
.avatar-status-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}
.avatar-soft-danger .avatar-initials {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}
.avatar-status-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}
.avatar-soft-light .avatar-initials {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}
.avatar-status-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}
.avatar-soft-dark .avatar-initials {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}
.avatar-status-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}
.avatar {
  position: relative;
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.3125rem;
}
.avatar:not(img) {
  background-color: #fff;
}
.avatar-img {
  display: block;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  border-radius: 0.3125rem;
}
.avatar-initials {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.09375rem;
  font-weight: 600;
  pointer-events: none;
  text-transform: uppercase;
  border-radius: 0.3125rem;
}
.avatar-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}
.avatar-circle {
  border-radius: 50%;
}
.avatar-circle .avatar-img,
.avatar-circle .avatar-initials {
  border-radius: 50%;
}
.avatar-border-lg {
  border: 0.1875rem solid #fff;
}
.avatar-border-dashed {
  border: 0.0625rem dashed #e7eaf3;
}
.avatar-border-dashed .avatar-initials {
  color: #99a7ba;
  background-color: #fff;
  transition: 0.3s;
}
.avatar-border-dashed:hover .avatar-initials {
  color: #01684b;
}
.avatar-group {
  position: relative;
  z-index: 2;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.avatar-group .avatar:hover {
  z-index: 2;
}
.avatar-group .avatar,
.avatar-group .avatar-sm,
.avatar-group .avatar-xs {
  padding: 0.125rem;
}
.avatar-group .avatar-lg,
.avatar-group .avatar-xl,
.avatar-group .avatar-xxl {
  padding: 0.25rem;
}
.avatar-group.avatar-circle .avatar {
  border-radius: 50%;
}
.avatar-group.avatar-circle .avatar .avatar-img,
.avatar-group.avatar-circle .avatar .avatar-initials {
  border-radius: 50%;
}
.avatar-group .avatar {
  width: 2.625rem;
  height: 2.625rem;
}
.avatar-group .avatar .avatar-initials {
  font-size: 1.09375rem;
}
.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}
.avatar-group-xs .avatar {
  width: 1.53125rem;
  height: 1.53125rem;
}
.avatar-group-xs .avatar .avatar-initials {
  font-size: 0.875rem;
}
.avatar-group-xs .avatar + .avatar {
  margin-left: -0.6125rem;
}
.avatar-group-sm .avatar {
  width: 2.1875rem;
  height: 2.1875rem;
}
.avatar-group-sm .avatar .avatar-initials {
  font-size: 0.98438rem;
}
.avatar-group-sm .avatar + .avatar {
  margin-left: -0.875rem;
}
.avatar-group-lg .avatar {
  width: 3.36875rem;
  height: 3.36875rem;
}
.avatar-group-lg .avatar .avatar-initials {
  font-size: 1.53125rem;
}
.avatar-group-lg .avatar + .avatar {
  margin-left: -1.5rem;
}
.avatar-group-xl .avatar {
  width: 4.92188rem;
  height: 4.92188rem;
}
.avatar-group-xl .avatar .avatar-initials {
  font-size: 1.96875rem;
}
.avatar-group-xl .avatar + .avatar {
  margin-left: -1.5rem;
}
.avatar-group-xxl .avatar {
  width: 7.875rem;
  height: 7.875rem;
}
.avatar-group-xxl .avatar .avatar-initials {
  font-size: 2.625rem;
}
.avatar-group-xxl .avatar + .avatar {
  margin-left: -2rem;
}
.avatar.avatar-4by3 {
  width: 3.5rem;
}
.avatar-xss.avatar-4by3 {
  width: 1.33333rem;
}
.avatar-xs.avatar-4by3 {
  width: 2.04167rem;
}
.avatar-sm.avatar-4by3 {
  width: 2.91667rem;
}
.avatar-lg.avatar-4by3 {
  width: 4.49167rem;
}
.avatar-xl.avatar-4by3 {
  width: 6.5625rem;
}
.avatar-xxl.avatar-4by3 {
  width: 10.5rem;
}
.avatar.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}
.avatar.avatar-circle .avatar-sm-status {
  bottom: 0;
  right: 0;
}
.avatar.avatar-circle .avatar-lg-status {
  bottom: -4.8px;
  right: -4.8px;
}
.avatar-xss {
  width: 1rem;
  height: 1rem;
}
.avatar-xss .avatar-initials {
  font-size: 0.875rem;
}
.avatar-xs {
  width: 1.53125rem;
  height: 1.53125rem;
}
.avatar-xs .avatar-initials {
  font-size: 0.875rem;
}
.avatar-xs.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}
.avatar-xs.avatar-circle .avatar-sm-status {
  bottom: -1.5px;
  right: -1.5px;
}
.avatar-xs.avatar-circle .avatar-lg-status {
  bottom: -4.8px;
  right: -4.8px;
}
.avatar-sm {
  width: 2.1875rem;
  height: 2.1875rem;
}
.avatar-sm .avatar-initials {
  font-size: 0.98438rem;
}
.avatar-sm.avatar-circle .avatar-status {
  bottom: -3.5px;
  right: -3.5px;
}
.avatar-sm.avatar-circle .avatar-sm-status {
  bottom: -1.2px;
  right: -1.2px;
}
.avatar-sm.avatar-circle .avatar-lg-status {
  bottom: -6px;
  right: -6px;
}
.avatar-lg {
  width: 3.36875rem;
  height: 3.36875rem;
}
.avatar-lg .avatar-initials {
  font-size: 1.53125rem;
}
.avatar-lg.avatar-circle .avatar-status {
  bottom: -2.1px;
  right: -2.1px;
}
.avatar-lg.avatar-circle .avatar-sm-status {
  bottom: 1.2px;
  right: 1.2px;
}
.avatar-lg.avatar-circle .avatar-lg-status {
  bottom: -3px;
  right: -3px;
}
.avatar-xl {
  width: 4.92188rem;
  height: 4.92188rem;
}
.avatar-xl .avatar-initials {
  font-size: 1.96875rem;
}
.avatar-xl.avatar-circle .avatar-status {
  bottom: 2.625px;
  right: 2.625px;
}
.avatar-xl.avatar-circle .avatar-sm-status {
  bottom: 3px;
  right: 3px;
}
.avatar-xl.avatar-circle .avatar-lg-status {
  bottom: 2px;
  right: 2px;
}
.avatar-xxl {
  width: 7.875rem;
  height: 7.875rem;
}
.avatar-xxl .avatar-initials {
  font-size: 2.625rem;
}
.avatar-xxl.avatar-circle .avatar-status {
  bottom: 6px;
  right: 6px;
}
.avatar-xxl.avatar-circle .avatar-sm-status {
  bottom: 12px;
  right: 12px;
}
.avatar-xxl.avatar-circle .avatar-lg-status {
  bottom: 6px;
  right: 6px;
}
.avatar-status {
  position: absolute;
  bottom: -8.4px;
  right: -8.4px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  font-weight: 600;
  border: 0.125rem solid #fff;
  width: 21px;
  height: 21px;
  line-height: 1;
  font-size: 0.71094rem;
  border-radius: 50%;
}
.avatar-sm-status {
  bottom: -4.2px;
  right: -4.2px;
  width: 12px;
  height: 12px;
  font-size: 0.4375rem;
}
.avatar-lg-status {
  width: 24px;
  height: 24px;
  font-size: 0.65625rem;
}
.avatar-uploader {
  cursor: pointer;
  display: inline-block;
  transition: 0.2s;
  margin-bottom: 0;
}
.avatar-uploader:hover {
  border-color: #01684b;
}
.avatar-uploader-input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 33, 68, 0.25);
  border-radius: 50%;
  transition: 0.2s;
}
.avatar-uploader-trigger {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
}
.avatar-uploader-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #677788;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s;
}
.avatar-uploader:hover .avatar-uploader-icon {
  color: #fff;
  background-color: #01684b;
}
.avatar-lg .avatar-uploader-icon {
  font-size: 0.75rem;
  width: 1.3125rem;
  height: 1.3125rem;
}
.avatar-xl .avatar-uploader-icon {
  font-size: 0.8125rem;
  width: 1.53125rem;
  height: 1.53125rem;
}
.avatar-xxl .avatar-uploader-icon {
  width: 2.1875rem;
  height: 2.1875rem;
}
.badge-soft-primary {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
}
a.badge-soft-primary:focus,
a.badge-soft-primary:hover {
  color: #fff;
  background-color: #01684b;
}
.badge-soft-secondary {
  color: #363636;
  background-color: rgba(113, 134, 157, 0.1);
}
a.badge-soft-secondary:focus,
a.badge-soft-secondary:hover {
  color: #fff;
  background-color: #363636;
}
.badge-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
a.badge-soft-success:focus,
a.badge-soft-success:hover {
  color: #fff;
  background-color: #00c9a7;
}
.badge-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}
a.badge-soft-info:focus,
a.badge-soft-info:hover {
  color: #fff;
  background-color: #00c9db;
}
.badge-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}
a.badge-soft-warning:focus,
a.badge-soft-warning:hover {
  color: #1e2022;
  background-color: #f5ca99;
}
.badge-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}
a.badge-soft-danger:focus,
a.badge-soft-danger:hover {
  color: #fff;
  background-color: #ed4c78;
}
.badge-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}
a.badge-soft-light:focus,
a.badge-soft-light:hover {
  color: #1e2022;
  background-color: #f9fafc;
}
.badge-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}
a.badge-soft-dark:focus,
a.badge-soft-dark:hover {
  color: #fff;
  background-color: #132144;
}
.badge-soft-warning {
  color: #ec9a3c !important;
}
.breadcrumb .breadcrumb-item {
  color: #1e2022;
  font-size: 0.8125rem;
}
.breadcrumb .breadcrumb-link {
  color: #99a7ba;
}
.breadcrumb .breadcrumb-link:hover {
  color: #0052ea;
}
.breadcrumb-no-gutter {
  padding: 0;
}
.blockquote {
  font-size: 0.875rem;
  border-left: 0.1875rem solid #e7eaf3;
  padding-left: 0.75rem;
  margin-bottom: 0;
}
.blockquote-sm {
  font-size: 0.8125rem;
  padding-left: 0.5rem;
}
.blockquote-footer {
  margin-top: 1rem;
}
.btn-dashed-outline {
  border-style: dashed;
}
.btn.dropdown-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}
.btn-white {
  background-color: #fff;
  border-color: #e7eaf3;
}
.btn-white.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-white.active,
.btn-white.focus,
.btn-white:active,
.btn-white:focus,
.btn-white:hover {
  color: #0052ea;
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.btn-white.active.dropdown-toggle::after,
.btn-white.focus.dropdown-toggle::after,
.btn-white:active.dropdown-toggle::after,
.btn-white:focus.dropdown-toggle::after,
.btn-white:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-white.disabled,
.btn-white:disabled {
  color: #99a7ba;
  background-color: rgba(231, 234, 243, 0.5);
}
.btn-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-primary.active.dropdown-toggle::after,
.btn-outline-primary:active.dropdown-toggle::after,
.btn-outline-primary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-secondary.active.dropdown-toggle::after,
.btn-outline-secondary:active.dropdown-toggle::after,
.btn-outline-secondary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-success.active.dropdown-toggle::after,
.btn-outline-success:active.dropdown-toggle::after,
.btn-outline-success:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-info.active.dropdown-toggle::after,
.btn-outline-info:active.dropdown-toggle::after,
.btn-outline-info:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-warning.active.dropdown-toggle::after,
.btn-outline-warning:active.dropdown-toggle::after,
.btn-outline-warning:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-danger.active.dropdown-toggle::after,
.btn-outline-danger:active.dropdown-toggle::after,
.btn-outline-danger:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-light.active.dropdown-toggle::after,
.btn-outline-light:active.dropdown-toggle::after,
.btn-outline-light:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-outline-dark.active.dropdown-toggle::after,
.btn-outline-dark:active.dropdown-toggle::after,
.btn-outline-dark:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-wide {
  min-width: 7rem;
}
.btn-xs {
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.24063rem 0.65625rem;
  border-radius: 0.25rem;
}
.btn-ghost-primary {
  color: #01684b;
  background-color: transparent;
}
.btn-ghost-primary.active,
.btn-ghost-primary.focus,
.btn-ghost-primary:active,
.btn-ghost-primary:focus,
.btn-ghost-primary:hover {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
}
.btn-ghost-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-primary.btn-no-focus[href].focus,
.btn-ghost-primary.btn-no-focus[href]:focus,
.btn-ghost-primary.btn-no-focus[type].focus,
.btn-ghost-primary.btn-no-focus[type]:focus {
  color: #01684b;
  background-color: unset;
}
.btn-ghost-primary.btn-no-focus[href].focus:hover,
.btn-ghost-primary.btn-no-focus[href]:focus:hover,
.btn-ghost-primary.btn-no-focus[type].focus:hover,
.btn-ghost-primary.btn-no-focus[type]:focus:hover {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
}
.btn-ghost-secondary {
  color: #363636;
  background-color: transparent;
}
.btn-ghost-secondary.active,
.btn-ghost-secondary.focus,
.btn-ghost-secondary:active,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover {
  color: #363636;
  background-color: rgba(55, 125, 255, 0.1);
}
.btn-ghost-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-secondary.btn-no-focus[href].focus,
.btn-ghost-secondary.btn-no-focus[href]:focus,
.btn-ghost-secondary.btn-no-focus[type].focus,
.btn-ghost-secondary.btn-no-focus[type]:focus {
  color: #363636;
  background-color: unset;
}
.btn-ghost-secondary.btn-no-focus[href].focus:hover,
.btn-ghost-secondary.btn-no-focus[href]:focus:hover,
.btn-ghost-secondary.btn-no-focus[type].focus:hover,
.btn-ghost-secondary.btn-no-focus[type]:focus:hover {
  color: #363636;
  background-color: rgba(113, 134, 157, 0.1);
}
.btn-ghost-success {
  color: #00c9a7;
  background-color: transparent;
}
.btn-ghost-success.active,
.btn-ghost-success.focus,
.btn-ghost-success:active,
.btn-ghost-success:focus,
.btn-ghost-success:hover {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
.btn-ghost-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-success.btn-no-focus[href].focus,
.btn-ghost-success.btn-no-focus[href]:focus,
.btn-ghost-success.btn-no-focus[type].focus,
.btn-ghost-success.btn-no-focus[type]:focus {
  color: #00c9a7;
  background-color: unset;
}
.btn-ghost-success.btn-no-focus[href].focus:hover,
.btn-ghost-success.btn-no-focus[href]:focus:hover,
.btn-ghost-success.btn-no-focus[type].focus:hover,
.btn-ghost-success.btn-no-focus[type]:focus:hover {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
.btn-ghost-info {
  color: #00c9db;
  background-color: transparent;
}
.btn-ghost-info.active,
.btn-ghost-info.focus,
.btn-ghost-info:active,
.btn-ghost-info:focus,
.btn-ghost-info:hover {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}
.btn-ghost-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-info.btn-no-focus[href].focus,
.btn-ghost-info.btn-no-focus[href]:focus,
.btn-ghost-info.btn-no-focus[type].focus,
.btn-ghost-info.btn-no-focus[type]:focus {
  color: #00c9db;
  background-color: unset;
}
.btn-ghost-info.btn-no-focus[href].focus:hover,
.btn-ghost-info.btn-no-focus[href]:focus:hover,
.btn-ghost-info.btn-no-focus[type].focus:hover,
.btn-ghost-info.btn-no-focus[type]:focus:hover {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}
.btn-ghost-warning {
  color: #f5ca99;
  background-color: transparent;
}
.btn-ghost-warning.active,
.btn-ghost-warning.focus,
.btn-ghost-warning:active,
.btn-ghost-warning:focus,
.btn-ghost-warning:hover {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}
.btn-ghost-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-warning.btn-no-focus[href].focus,
.btn-ghost-warning.btn-no-focus[href]:focus,
.btn-ghost-warning.btn-no-focus[type].focus,
.btn-ghost-warning.btn-no-focus[type]:focus {
  color: #f5ca99;
  background-color: unset;
}
.btn-ghost-warning.btn-no-focus[href].focus:hover,
.btn-ghost-warning.btn-no-focus[href]:focus:hover,
.btn-ghost-warning.btn-no-focus[type].focus:hover,
.btn-ghost-warning.btn-no-focus[type]:focus:hover {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}
.btn-ghost-danger {
  color: #ed4c78;
  background-color: transparent;
}
.btn-ghost-danger.active,
.btn-ghost-danger.focus,
.btn-ghost-danger:active,
.btn-ghost-danger:focus,
.btn-ghost-danger:hover {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}
.btn-ghost-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-danger.btn-no-focus[href].focus,
.btn-ghost-danger.btn-no-focus[href]:focus,
.btn-ghost-danger.btn-no-focus[type].focus,
.btn-ghost-danger.btn-no-focus[type]:focus {
  color: #ed4c78;
  background-color: unset;
}
.btn-ghost-danger.btn-no-focus[href].focus:hover,
.btn-ghost-danger.btn-no-focus[href]:focus:hover,
.btn-ghost-danger.btn-no-focus[type].focus:hover,
.btn-ghost-danger.btn-no-focus[type]:focus:hover {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}
.btn-ghost-light {
  color: #f9fafc;
  background-color: transparent;
}
.btn-ghost-light.active,
.btn-ghost-light.focus,
.btn-ghost-light:active,
.btn-ghost-light:focus,
.btn-ghost-light:hover {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}
.btn-ghost-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-light.btn-no-focus[href].focus,
.btn-ghost-light.btn-no-focus[href]:focus,
.btn-ghost-light.btn-no-focus[type].focus,
.btn-ghost-light.btn-no-focus[type]:focus {
  color: #f9fafc;
  background-color: unset;
}
.btn-ghost-light.btn-no-focus[href].focus:hover,
.btn-ghost-light.btn-no-focus[href]:focus:hover,
.btn-ghost-light.btn-no-focus[type].focus:hover,
.btn-ghost-light.btn-no-focus[type]:focus:hover {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}
.btn-ghost-dark {
  color: #132144;
  background-color: transparent;
}
.btn-ghost-dark.active,
.btn-ghost-dark.focus,
.btn-ghost-dark:active,
.btn-ghost-dark:focus,
.btn-ghost-dark:hover {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}
.btn-ghost-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-dark.btn-no-focus[href].focus,
.btn-ghost-dark.btn-no-focus[href]:focus,
.btn-ghost-dark.btn-no-focus[type].focus,
.btn-ghost-dark.btn-no-focus[type]:focus {
  color: #132144;
  background-color: unset;
}
.btn-ghost-dark.btn-no-focus[href].focus:hover,
.btn-ghost-dark.btn-no-focus[href]:focus:hover,
.btn-ghost-dark.btn-no-focus[type].focus:hover,
.btn-ghost-dark.btn-no-focus[type]:focus:hover {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}
.btn-ghost-secondary.focus,
.btn-ghost-secondary:active,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover {
  color: #01684b;
}
.btn-ghost-secondary.focus.dropdown-toggle::after,
.btn-ghost-secondary:active.dropdown-toggle::after,
.btn-ghost-secondary:focus.dropdown-toggle::after,
.btn-ghost-secondary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-ghost-secondary.btn-no-focus[href].focus:hover,
.btn-ghost-secondary.btn-no-focus[href]:focus:hover,
.btn-ghost-secondary.btn-no-focus[type].focus:hover,
.btn-ghost-secondary.btn-no-focus[type]:focus:hover {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
}
.btn-soft-primary {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
  border-color: transparent;
}
.btn-soft-primary.active,
.btn-soft-primary:active,
.btn-soft-primary:focus,
.btn-soft-primary:hover {
  color: #fff;
  background-color: #01684b;
  box-shadow: 0 4px 11px rgba(55, 125, 255, 0.1);
}
.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
  box-shadow: none;
}
.btn-soft-primary:not(:disabled):not(.disabled).active,
.btn-soft-primary:not(:disabled):not(.disabled):active,
.show > .btn-soft-primary.dropdown-toggle {
  color: #fff;
  background-color: #01684b;
}
.btn-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
  border-color: transparent;
}
.btn-soft-secondary.active,
.btn-soft-secondary:active,
.btn-soft-secondary:focus,
.btn-soft-secondary:hover {
  color: #fff;
  background-color: #71869d;
  box-shadow: 0 4px 11px rgba(113, 134, 157, 0.1);
}
.btn-soft-secondary.disabled,
.btn-soft-secondary:disabled {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
  box-shadow: none;
}
.btn-soft-secondary:not(:disabled):not(.disabled).active,
.btn-soft-secondary:not(:disabled):not(.disabled):active,
.show > .btn-soft-secondary.dropdown-toggle {
  color: #fff;
  background-color: #71869d;
}
.btn-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  border-color: transparent;
}
.btn-soft-success.active,
.btn-soft-success:active,
.btn-soft-success:focus,
.btn-soft-success:hover {
  color: #fff;
  background-color: #00c9a7;
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.1);
}
.btn-soft-success.disabled,
.btn-soft-success:disabled {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  box-shadow: none;
}
.btn-soft-success:not(:disabled):not(.disabled).active,
.btn-soft-success:not(:disabled):not(.disabled):active,
.show > .btn-soft-success.dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
}
.btn-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
  border-color: transparent;
}
.btn-soft-info.active,
.btn-soft-info:active,
.btn-soft-info:focus,
.btn-soft-info:hover {
  color: #fff;
  background-color: #00c9db;
  box-shadow: 0 4px 11px rgba(0, 201, 219, 0.1);
}
.btn-soft-info.disabled,
.btn-soft-info:disabled {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
  box-shadow: none;
}
.btn-soft-info:not(:disabled):not(.disabled).active,
.btn-soft-info:not(:disabled):not(.disabled):active,
.show > .btn-soft-info.dropdown-toggle {
  color: #fff;
  background-color: #00c9db;
}
.btn-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  border-color: transparent;
}
.btn-soft-warning.active,
.btn-soft-warning:active,
.btn-soft-warning:focus,
.btn-soft-warning:hover {
  color: #1e2022;
  background-color: #f5ca99;
  box-shadow: 0 4px 11px rgba(245, 202, 153, 0.1);
}
.btn-soft-warning.disabled,
.btn-soft-warning:disabled {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
  box-shadow: none;
}
.btn-soft-warning:not(:disabled):not(.disabled).active,
.btn-soft-warning:not(:disabled):not(.disabled):active,
.show > .btn-soft-warning.dropdown-toggle {
  color: #1e2022;
  background-color: #f5ca99;
}
.btn-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  border-color: transparent;
}
.btn-soft-danger.active,
.btn-soft-danger:active,
.btn-soft-danger:focus,
.btn-soft-danger:hover {
  color: #fff;
  background-color: #ed4c78;
  box-shadow: 0 4px 11px rgba(237, 76, 120, 0.1);
}
.btn-soft-danger.disabled,
.btn-soft-danger:disabled {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
  box-shadow: none;
}
.btn-soft-danger:not(:disabled):not(.disabled).active,
.btn-soft-danger:not(:disabled):not(.disabled):active,
.show > .btn-soft-danger.dropdown-toggle {
  color: #fff;
  background-color: #ed4c78;
}
.btn-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
  border-color: transparent;
}
.btn-soft-light.active,
.btn-soft-light:active,
.btn-soft-light:focus,
.btn-soft-light:hover {
  color: #1e2022;
  background-color: #f9fafc;
  box-shadow: 0 4px 11px rgba(249, 250, 252, 0.1);
}
.btn-soft-light.disabled,
.btn-soft-light:disabled {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
  box-shadow: none;
}
.btn-soft-light:not(:disabled):not(.disabled).active,
.btn-soft-light:not(:disabled):not(.disabled):active,
.show > .btn-soft-light.dropdown-toggle {
  color: #1e2022;
  background-color: #f9fafc;
}
.btn-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
  border-color: transparent;
}
.btn-soft-dark.active,
.btn-soft-dark:active,
.btn-soft-dark:focus,
.btn-soft-dark:hover {
  color: #fff;
  background-color: #132144;
  box-shadow: 0 4px 11px rgba(19, 33, 68, 0.1);
}
.btn-soft-dark.disabled,
.btn-soft-dark:disabled {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
  box-shadow: none;
}
.btn-soft-dark:not(:disabled):not(.disabled).active,
.btn-soft-dark:not(:disabled):not(.disabled):active,
.show > .btn-soft-dark.dropdown-toggle {
  color: #fff;
  background-color: #132144;
}
.btn-soft-primary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-primary.active.dropdown-toggle::after,
.btn-soft-primary.focus.dropdown-toggle::after,
.btn-soft-primary:active.dropdown-toggle::after,
.btn-soft-primary:focus.dropdown-toggle::after,
.btn-soft-primary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-soft-secondary.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-secondary.active.dropdown-toggle::after,
.btn-soft-secondary.focus.dropdown-toggle::after,
.btn-soft-secondary:active.dropdown-toggle::after,
.btn-soft-secondary:focus.dropdown-toggle::after,
.btn-soft-secondary:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-soft-success.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9a7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-success.active.dropdown-toggle::after,
.btn-soft-success.focus.dropdown-toggle::after,
.btn-soft-success:active.dropdown-toggle::after,
.btn-soft-success:focus.dropdown-toggle::after,
.btn-soft-success:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-soft-info.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c9db' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-info.active.dropdown-toggle::after,
.btn-soft-info.focus.dropdown-toggle::after,
.btn-soft-info:active.dropdown-toggle::after,
.btn-soft-info:focus.dropdown-toggle::after,
.btn-soft-info:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-soft-warning.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f5ca99' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-warning.active.dropdown-toggle::after,
.btn-soft-warning.focus.dropdown-toggle::after,
.btn-soft-warning:active.dropdown-toggle::after,
.btn-soft-warning:focus.dropdown-toggle::after,
.btn-soft-warning:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-soft-danger.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23ed4c78' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-danger.active.dropdown-toggle::after,
.btn-soft-danger.focus.dropdown-toggle::after,
.btn-soft-danger:active.dropdown-toggle::after,
.btn-soft-danger:focus.dropdown-toggle::after,
.btn-soft-danger:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-soft-light.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23f9fafc' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-light.active.dropdown-toggle::after,
.btn-soft-light.focus.dropdown-toggle::after,
.btn-soft-light:active.dropdown-toggle::after,
.btn-soft-light:focus.dropdown-toggle::after,
.btn-soft-light:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%231e2022' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-soft-dark.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23132144' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  transition: all 0.2s ease-in-out;
}
.btn-soft-dark.active.dropdown-toggle::after,
.btn-soft-dark.focus.dropdown-toggle::after,
.btn-soft-dark:active.dropdown-toggle::after,
.btn-soft-dark:focus.dropdown-toggle::after,
.btn-soft-dark:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.btn-group-segment {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f8fafd;
  border-radius: 0.3125rem;
  padding: 0.25rem;
}
.btn-group-segment .btn {
  color: #677788;
}
.btn-group-segment .btn:not(:last-child) {
  margin-right: 0.25rem;
}
.btn-group-segment .btn:hover {
  color: #01684b;
}
.btn-group-segment .btn.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}
.btn-group-segment .btn:not(:disabled):not(.disabled).active,
.btn-group-segment .btn:not(:disabled):not(.disabled):active {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}
.btn-group-segment > .btn-group:not(:first-child) > .btn,
.btn-group-segment > .btn-group:not(:last-child) > .btn,
.btn-group-segment > .btn:not(:first-child),
.btn-group-segment > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0.3125rem;
}
.btn-group-segment.btn-group-pills {
  border-radius: 6.1875rem;
}
.btn-group-segment.btn-group-pills > .btn-group:not(:first-child) > .btn,
.btn-group-segment.btn-group-pills > .btn-group:not(:last-child) > .btn,
.btn-group-segment.btn-group-pills > .btn:not(:first-child),
.btn-group-segment.btn-group-pills
  > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 6.1875rem;
}
.btn-outline-secondary {
  border-color: #e7eaf3;
}
.btn-icon {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.92969rem;
  font-weight: 400;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0;
}
.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.53125rem;
  height: 1.53125rem;
}
.btn-group-sm > .btn-icon.btn,
.btn-icon.btn-sm {
  font-size: 0.875rem;
  width: 2.1875rem;
  height: 2.1875rem;
}
.btn-group-lg > .btn-icon.btn,
.btn-icon.btn-lg {
  font-size: 1rem;
  width: 3.36875rem;
  height: 3.36875rem;
}
.btn-status {
  position: absolute;
  top: -0.4375rem;
  right: -0.4375rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.71094rem;
  font-weight: 600;
  width: 21px;
  height: 21px;
  background-color: #bdc5d1;
  border: 0.125rem solid #fff;
  padding: 0;
  border-radius: 50%;
}
.btn.rounded-circle .btn-status {
  top: -0.29167rem;
  right: -0.29167rem;
}
.btn-xs-status {
  top: -0.3125rem;
  right: -0.3125rem;
  font-size: 0.53594rem;
  width: 10px;
  height: 10px;
  border-width: 0.1rem;
}
.btn.rounded-circle .btn-xs-status {
  top: -0.07812rem;
  right: -0.07812rem;
}
.btn-sm-status {
  top: -0.3125rem;
  right: -0.3125rem;
  font-size: 0.65625rem;
  width: 12px;
  height: 12px;
}
.btn.rounded-circle .btn-sm-status {
  top: -0.15625rem;
  right: -0.15625rem;
}
.btn-lg-status {
  top: -0.6125rem;
  right: -0.6125rem;
  font-size: 0.875rem;
  width: 24px;
  height: 24px;
}
.btn.rounded-circle .btn-lg-status {
  top: -0.49rem;
  right: -0.49rem;
}
.btn-status-primary {
  color: #fff;
  background-color: #01684b;
}
.btn-status-secondary {
  color: #fff;
  background-color: #71869d;
}
.btn-status-success {
  color: #fff;
  background-color: #00c9a7;
}
.btn-status-info {
  color: #fff;
  background-color: #00c9db;
}
.btn-status-warning {
  color: #1e2022;
  background-color: #f5ca99;
}
.btn-status-danger {
  color: #fff;
  background-color: #ed4c78;
}
.btn-status-light {
  color: #1e2022;
  background-color: #f9fafc;
}
.btn-status-dark {
  color: #fff;
  background-color: #132144;
}
.btn {
  position: relative;
}
label.btn {
  cursor: pointer !important;
}
.btn.dropdown-toggle::after {
  transition: all 0.2s ease-in-out;
}
.btn-pill {
  border-radius: 6.1875rem;
}
.btn-link {
  font-weight: 600;
}
.btn-toggle-default {
  display: inline-block;
}
.btn-toggle-toggled {
  display: none;
}
.btn.toggled .btn-toggle-default,
.btn[aria-expanded="true"] .btn-toggle-default {
  display: none;
}
.btn.toggled .btn-toggle-toggled,
.btn[aria-expanded="true"] .btn-toggle-toggled {
  display: inline-block;
}
.content-space {
  padding: 2rem;
}
@media (max-width: 575.98px) {
  .content-space {
    padding: 1rem;
  }
}
.navbar-expand .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
  padding-left: 16.25rem;
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-vertical-aside.navbar-vertical-fixed ~ .main {
    padding-left: 16.25rem;
  }
}
.footer-offset {
  margin-bottom: 3.5rem;
}
.main .content {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.container-fluid,
.container-xxl {
  padding-right: 2rem;
  padding-left: 2rem;
}
@media (max-width: 575.98px) {
  .container-fluid,
  .container-xxl {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.kanban-board {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.kanban-board::-webkit-scrollbar {
  height: 0.6125rem;
}
.kanban-board::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.kanban-board .kanban-board-row {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.kanban-board .kanban-board-row > [class*="col"] {
  max-width: 350px;
}
.sidebar-detached-content {
  margin-left: 18.25rem;
}
.splitted-content-main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.navbar-vertical.splitted-content-navbar {
  width: 21.5rem;
}
.navbar-vertical.splitted-content-navbar .navbar-vertical-content {
  height: 100%;
}
.navbar-vertical.splitted-content-navbar .navbar-nav {
  margin-left: 5.25rem;
}
.navbar-vertical.splitted-content-navbar .navbar-vertical-footer {
  margin-left: 5.25rem;
}
.splitted-content-bordered:not(:last-child) {
  border-right: 0.0625rem solid #e7eaf3;
}
.splitted-content-bordered:not(:first-child) {
  border-left: 0.0625rem solid #e7eaf3;
}
.navbar-vertical-aside
  ~ .splitted-content-small.splitted-content-bordered:not(:last-child) {
  margin-left: -0.0625rem;
}
.splitted-content-mini,
.splitted-content-small {
  background-color: #fff;
}
.splitted-content-mini {
  width: 5.25rem;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.splitted-content-mini .navbar-brand {
  margin-right: 0;
}
.splitted-content-fluid,
.splitted-content-small {
  min-height: 100vh;
}
.splitted-content-small {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20rem;
  max-width: 100%;
}
.splitted-content-fluid {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}
.header ~ .main .splitted-content-fluid,
.header ~ .main .splitted-content-mini,
.header ~ .main .splitted-content-small {
  min-height: calc(100vh - 3.75rem);
}
.splitted-content-mini.hs-unfold-content-initialized,
.splitted-content-small.hs-unfold-content-initialized {
  display: -ms-flexbox !important;
  display: flex !important;
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1200px) {
  .splitted-content-toggle {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .splitted-content-mini.hs-unfold-hidden,
  .splitted-content-small.hs-unfold-hidden {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
  .splitted-content-small {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
  }
  .splitted-content-mini-right {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 101;
  }
}
.page-header {
  border-bottom: 0.0625rem solid #e7eaf3;
  padding-bottom: 1.75rem;
  margin-bottom: 2.25rem;
}
.page-header .breadcrumb {
  margin-bottom: 0.25rem;
}
.page-header-title {
  margin-bottom: 0.25rem;
}
.page-header-text {
  margin-bottom: 0.25rem;
}
.page-header-tabs {
  margin-bottom: -1.75rem;
  border-bottom-width: 0;
}
.hs-nav-scroller-horizontal .page-header-tabs {
  margin-bottom: -1.85rem;
}
.page-header-reset {
  border-bottom-width: 0;
  padding-bottom: 0;
}
.page-header-light {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
.page-header-light .page-header-title {
  color: #fff;
}
.page-header-light .page-header-text {
  color: rgba(255, 255, 255, 0.7);
}
.card {
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);
}
.card[href]:hover .card-header-title,
.card[href]:hover .card-title {
  color: #01684b;
}
.card-title {
  display: block;
  color: #1e2022;
  margin-bottom: 0;
}
.card-subtitle {
  display: block;
  color: #99a7ba;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.card-text {
  display: block;
  color: #677788;
}
.card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.card-header-title {
  margin-bottom: 0;
}
.card-footer {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.card-sm > .card-footer,
.card-sm > .card-header {
  padding-right: 1rem;
  padding-left: 1rem;
}
.card-sm > .card-body,
.card-sm > .collapse .card-body {
  padding: 1rem;
}
.card-sm .card-table td,
.card-sm .card-table th {
  padding-right: 1rem;
  padding-left: 1rem;
}
.card-lg > .card-footer,
.card-lg > .card-header {
  padding-right: 2rem;
  padding-left: 2rem;
}
.card-lg > .card-body,
.card-lg > .collapse .card-body {
  padding: 2rem;
}
.card-lg .card-table td,
.card-lg .card-table th {
  padding-right: 2rem;
  padding-left: 2rem;
}
.card-header-borderless .card-header {
  border-width: 0;
  padding-bottom: 0;
}
.card-body-height {
  height: 21.25rem;
  overflow: hidden;
  overflow-y: auto;
}
.card-body-height::-webkit-scrollbar {
  width: 0.6125rem;
}
.card-body-height::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.card-body-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-body-stretched {
  padding: 0 1.3125rem 1.3125rem;
}
.card-dashed {
  border-width: 0;
}
.card-dashed:hover {
  border-color: rgba(55, 125, 255, 0.7);
}
.card-dashed:hover .card-dashed-body {
  color: #0052ea;
}
.card-dashed-body {
  color: #99a7ba;
  border: 0.0625rem dashed rgba(214, 219, 235, 0.7);
  border-radius: 0.75rem;
  transition: 0.3s;
}
.card-border-left {
  border-left: 0.125rem solid #e7eaf3;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}
.card-hover-shadow {
  transition: 0.3s;
}
.card-hover-shadow:hover {
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.25) !important;
}
.card-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  white-space: inherit;
}
.card-btn-toggle {
  display: inline-block;
  color: #01684b;
}
.card-btn-toggle-default {
  display: none;
}
.card-btn-toggle-active {
  display: block;
}
.card-btn.collapsed .card-btn-toggle-default {
  display: block;
}
.card-btn.collapsed .card-btn-toggle-active {
  display: none;
}
.card-pinned {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}
.card-table {
  margin-bottom: 0;
}
.card-table td,
.card-table th {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}
.card-table .table-thead-bordered {
  margin-top: -0.0625rem;
}
.card-alert {
  border-radius: 0;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .card-dropdown-filter-centered {
    left: -8rem;
    min-width: 18rem !important;
  }
}
.card-unfold {
  z-index: 2;
}
.card-progress-wrap {
  overflow: hidden;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.card-progress {
  height: 0.25rem;
}
.card-progress:first-child .progress-bar {
  border-top-left-radius: 0.75rem;
}
.card-progress:first-child .progress-bar[aria-valuenow="100"] {
  border-top-right-radius: 0.75rem;
}
.card-nav-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-nav-vertical .nav-link {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.card-nav-vertical.card-nav {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.card-navbar-nav {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.card-navbar-nav svg {
  margin-right: 0.5rem;
}
.card > .profile-cover,
.card > .profile-cover .profile-cover-img,
.card > .profile-cover .profile-cover-img-wrapper {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header ~ .profile-cover,
.card > .card-header ~ .profile-cover .profile-cover-img,
.card > .card-header ~ .profile-cover .profile-cover-img-wrapper {
  border-radius: 0;
}
.card-jvectormap .jvectormap-container {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.close {
  opacity: 1;
}
.close:hover {
  color: #0052ea;
}
.close-light {
  color: #fff;
  opacity: 0.5;
}
.close-light:hover {
  color: #fff;
  opacity: 1;
}
.column-divider {
  position: relative;
}
.column-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-left: 0.0625rem solid #e7eaf3;
  content: "";
}
@media (min-width: 576px) {
  .column-divider-sm {
    position: relative;
  }
  .column-divider-sm::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: "";
  }
}
@media (min-width: 768px) {
  .column-divider-md {
    position: relative;
  }
  .column-divider-md::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: "";
  }
}
@media (min-width: 992px) {
  .column-divider-lg {
    position: relative;
  }
  .column-divider-lg::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: "";
  }
}
@media (min-width: 1200px) {
  .column-divider-xl {
    position: relative;
  }
  .column-divider-xl::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: "";
  }
}
@media (min-width: 1400px) {
  .column-divider-xxl {
    position: relative;
  }
  .column-divider-xxl::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 0.0625rem solid #e7eaf3;
    content: "";
  }
}
.divider {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.divider::after,
.divider::before {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: 0.0625rem solid #e7eaf3;
  content: "";
  margin-top: 0.0625rem;
}
.divider::before {
  margin-right: 1.5rem;
}
.divider::after {
  margin-left: 1.5rem;
}
.link {
  font-size: 0.8125rem;
}
.list-inline-m-1 {
  margin-left: -0.25rem !important;
}
.list-inline-m-1 .list-inline-item {
  margin: 0.25rem !important;
}
.list-unstyled-py-2 > li:not(:last-child) {
  padding-bottom: 0.5rem;
}
.list-unstyled-py-2 .list-unstyled-py-2 > li:first-child {
  padding-top: 0.5rem;
}
.list-unstyled-py-2 .list-unstyled-py-2 > li:last-child {
  padding-bottom: 0;
}
.list-unstyled-py-3 > li:not(:last-child) {
  padding-bottom: 1rem;
}
.list-unstyled-py-3 .list-unstyled-py-3 > li:first-child {
  padding-top: 1rem;
}
.list-unstyled-py-3 .list-unstyled-py-3 > li:last-child {
  padding-bottom: 0;
}
.list-unstyled-py-4 > li:not(:last-child) {
  padding-bottom: 1.5rem;
}
.list-unstyled-py-4 .list-unstyled-py-4 > li:first-child {
  padding-top: 1.5rem;
}
.list-unstyled-py-4 .list-unstyled-py-4 > li:last-child {
  padding-bottom: 0;
}
.list-comment {
  padding-left: 0;
  list-style: none;
}
.list-comment-item:not(:first-child) {
  border-top: 0.0625rem solid #e7eaf3;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}
.list-separator {
  margin-bottom: 0;
}
.list-separator .list-inline-item {
  position: relative;
  margin-left: 0;
  margin-right: -0.25rem;
}
.list-separator .list-inline-item:not(:last-child) {
  padding-right: 2rem;
}
.list-separator .list-inline-item:not(:last-child)::after {
  position: absolute;
  top: calc(50% - 0.15625rem);
  right: 0.84375rem;
  width: 0.3125rem;
  height: 0.3125rem;
  background-color: #bdc5d1;
  border-radius: 0.3125rem;
  content: "";
}
.list-separator .list-separator-link {
  color: #677788;
}
.list-separator .list-separator-link:hover {
  color: #0052ea;
}
.list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.list-group-icon {
  width: 1.25rem;
  text-align: center;
  margin-right: 0.25rem;
}
.list-group-item-light {
  background-color: #f9fafc;
}
.list-group-sm .list-group-item {
  font-size: 0.8125rem;
  padding: 0.375rem 0.625rem;
}
.list-group-lg .list-group-icon {
  font-size: 1.3125rem;
  width: 2.1875rem;
  margin-right: 0.5rem;
}
.list-group-no-gutters .list-group-item {
  padding-right: 0;
  padding-left: 0;
}
.list-checked {
  padding-left: 0;
  list-style: none;
}
.list-checked-item {
  position: relative;
  display: block;
  color: #677788;
  padding-left: 1.75rem;
  margin: 0.5rem;
}
.list-checked-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%2371869d' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A")
    no-repeat right center/1rem 1rem;
  content: "";
  margin-top: 0.125rem;
}
.list-checked-item[hover]:hover {
  color: #01684b;
}
[class*="list-checked-bg-"] .list-checked-item::before,
[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: 0.25rem;
}
.list-checked-sm .list-checked-item {
  padding-left: 1.25rem;
}
.list-checked-sm .list-checked-item::before {
  width: 0.75rem;
  height: 0.75rem;
  background-size: 0.75rem 0.75rem;
  margin-top: 0.3125rem;
}
.list-checked-sm[class*="list-checked-bg-"] .list-checked-item::before,
.list-checked-sm[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: 0.375rem;
}
.list-checked-lg .list-checked-item {
  padding-left: 2.25rem;
}
.list-checked-lg .list-checked-item::before {
  width: 1.25rem;
  height: 1.25rem;
  background-size: 1.25rem 1.25rem;
  margin-top: 0;
}
.list-checked-lg[class*="list-checked-bg-"] .list-checked-item::before,
.list-checked-lg[class*="list-checked-soft-bg-"] .list-checked-item::before {
  margin-top: 0.125rem;
}
.list-checked-primary .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nOScgdmlld0JveD0nMCAwIDEwMCA5MCcgZmlsbD0nIzM3N2RmZicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOTguMSwxNi4yYy0yLjUtMi4zLTYuNC0yLjItOC43LDAuMkwzNi43LDcwLjFsLTEzLTE1LjVjLTIuOS0zLjUtNy45LTQuMS0xMS4xLTEuNGMtMi45LDIuNC0zLjUsNi42LTEuNCwxMC4xbDE2LjUsMjhjMy4yLDUuNCwxMC44LDUuOCwxNC41LDAuOGw1Ni41LTY3LjNDMTAwLjcsMjIuMSwxMDAuNCwxOC41LDk4LjEsMTYuMnonLz48L3N2Zz4K);
}
.list-checked-bg-primary .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyBmaWxsPScjMzc3ZGZmJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J001NSwxMTBMNTUsMTEwQzI0LjYsMTEwLDAsODUuNCwwLDU1djBDMCwyNC42LDI0LjYsMCw1NSwwaDBjMzAuNCwwLDU1LDI0LjYsNTUsNTV2MEMxMTAsODUuNCw4NS40LDExMCw1NSwxMTB6TTgxLjYsMzFjLTEuNS0xLjQtMy45LTEuNC01LjQsMC4xTDQzLjcsNjQuM2wtOC05LjZjLTEuOC0yLjItNC45LTIuNi02LjktMC45Yy0xLjgsMS41LTIuMSw0LjEtMC45LDYuMmwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonIC8+PC9zdmc+Cg==);
}
.list-checked-soft-bg-primary .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9JyMzNzdkZmYnIG9wYWNpdHk9Jy4xJyBkPSdNNTUsMTEwTDU1LDExMEMyNC42LDExMCwwLDg1LjQsMCw1NWwwLDBDMCwyNC42LDI0LjYsMCw1NSwwbDAsMGMzMC40LDAsNTUsMjQuNiw1NSw1NWwwLDAgQzExMCw4NS40LDg1LjQsMTEwLDU1LDExMHonLz48cGF0aCBmaWxsPScjMzc3ZGZmJyBkPSdNODEuNiwzMWMtMS41LTEuNC0zLjktMS40LTUuNCwwLjFMNDMuNyw2NC4zbC04LTkuNmMtMS44LTIuMi00LjktMi42LTYuOS0wLjljLTEuOCwxLjUtMi4xLDQuMS0wLjksNi4yIGwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonLz48L3N2Zz4=);
}
.list-checked-secondary .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nOScgdmlld0JveD0nMCAwIDEwMCA5MCcgZmlsbD0nIzcxODY5ZCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOTguMSwxNi4yYy0yLjUtMi4zLTYuNC0yLjItOC43LDAuMkwzNi43LDcwLjFsLTEzLTE1LjVjLTIuOS0zLjUtNy45LTQuMS0xMS4xLTEuNGMtMi45LDIuNC0zLjUsNi42LTEuNCwxMC4xbDE2LjUsMjhjMy4yLDUuNCwxMC44LDUuOCwxNC41LDAuOGw1Ni41LTY3LjNDMTAwLjcsMjIuMSwxMDAuNCwxOC41LDk4LjEsMTYuMnonLz48L3N2Zz4K);
}
.list-checked-bg-secondary .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyBmaWxsPScjNzE4NjlkJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J001NSwxMTBMNTUsMTEwQzI0LjYsMTEwLDAsODUuNCwwLDU1djBDMCwyNC42LDI0LjYsMCw1NSwwaDBjMzAuNCwwLDU1LDI0LjYsNTUsNTV2MEMxMTAsODUuNCw4NS40LDExMCw1NSwxMTB6TTgxLjYsMzFjLTEuNS0xLjQtMy45LTEuNC01LjQsMC4xTDQzLjcsNjQuM2wtOC05LjZjLTEuOC0yLjItNC45LTIuNi02LjktMC45Yy0xLjgsMS41LTIuMSw0LjEtMC45LDYuMmwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonIC8+PC9zdmc+Cg==);
}
.list-checked-soft-bg-secondary .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9JyM3MTg2OWQnIG9wYWNpdHk9Jy4xJyBkPSdNNTUsMTEwTDU1LDExMEMyNC42LDExMCwwLDg1LjQsMCw1NWwwLDBDMCwyNC42LDI0LjYsMCw1NSwwbDAsMGMzMC40LDAsNTUsMjQuNiw1NSw1NWwwLDAgQzExMCw4NS40LDg1LjQsMTEwLDU1LDExMHonLz48cGF0aCBmaWxsPScjNzE4NjlkJyBkPSdNODEuNiwzMWMtMS41LTEuNC0zLjktMS40LTUuNCwwLjFMNDMuNyw2NC4zbC04LTkuNmMtMS44LTIuMi00LjktMi42LTYuOS0wLjljLTEuOCwxLjUtMi4xLDQuMS0wLjksNi4yIGwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonLz48L3N2Zz4=);
}
.list-checked-success .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nOScgdmlld0JveD0nMCAwIDEwMCA5MCcgZmlsbD0nIzAwYzlhNycgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOTguMSwxNi4yYy0yLjUtMi4zLTYuNC0yLjItOC43LDAuMkwzNi43LDcwLjFsLTEzLTE1LjVjLTIuOS0zLjUtNy45LTQuMS0xMS4xLTEuNGMtMi45LDIuNC0zLjUsNi42LTEuNCwxMC4xbDE2LjUsMjhjMy4yLDUuNCwxMC44LDUuOCwxNC41LDAuOGw1Ni41LTY3LjNDMTAwLjcsMjIuMSwxMDAuNCwxOC41LDk4LjEsMTYuMnonLz48L3N2Zz4K);
}
.list-checked-bg-success .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyBmaWxsPScjMDBjOWE3JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J001NSwxMTBMNTUsMTEwQzI0LjYsMTEwLDAsODUuNCwwLDU1djBDMCwyNC42LDI0LjYsMCw1NSwwaDBjMzAuNCwwLDU1LDI0LjYsNTUsNTV2MEMxMTAsODUuNCw4NS40LDExMCw1NSwxMTB6TTgxLjYsMzFjLTEuNS0xLjQtMy45LTEuNC01LjQsMC4xTDQzLjcsNjQuM2wtOC05LjZjLTEuOC0yLjItNC45LTIuNi02LjktMC45Yy0xLjgsMS41LTIuMSw0LjEtMC45LDYuMmwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonIC8+PC9zdmc+Cg==);
}
.list-checked-soft-bg-success .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9JyMwMGM5YTcnIG9wYWNpdHk9Jy4xJyBkPSdNNTUsMTEwTDU1LDExMEMyNC42LDExMCwwLDg1LjQsMCw1NWwwLDBDMCwyNC42LDI0LjYsMCw1NSwwbDAsMGMzMC40LDAsNTUsMjQuNiw1NSw1NWwwLDAgQzExMCw4NS40LDg1LjQsMTEwLDU1LDExMHonLz48cGF0aCBmaWxsPScjMDBjOWE3JyBkPSdNODEuNiwzMWMtMS41LTEuNC0zLjktMS40LTUuNCwwLjFMNDMuNyw2NC4zbC04LTkuNmMtMS44LTIuMi00LjktMi42LTYuOS0wLjljLTEuOCwxLjUtMi4xLDQuMS0wLjksNi4yIGwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonLz48L3N2Zz4=);
}
.list-checked-info .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nOScgdmlld0JveD0nMCAwIDEwMCA5MCcgZmlsbD0nIzAwYzlkYicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOTguMSwxNi4yYy0yLjUtMi4zLTYuNC0yLjItOC43LDAuMkwzNi43LDcwLjFsLTEzLTE1LjVjLTIuOS0zLjUtNy45LTQuMS0xMS4xLTEuNGMtMi45LDIuNC0zLjUsNi42LTEuNCwxMC4xbDE2LjUsMjhjMy4yLDUuNCwxMC44LDUuOCwxNC41LDAuOGw1Ni41LTY3LjNDMTAwLjcsMjIuMSwxMDAuNCwxOC41LDk4LjEsMTYuMnonLz48L3N2Zz4K);
}
.list-checked-bg-info .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyBmaWxsPScjMDBjOWRiJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J001NSwxMTBMNTUsMTEwQzI0LjYsMTEwLDAsODUuNCwwLDU1djBDMCwyNC42LDI0LjYsMCw1NSwwaDBjMzAuNCwwLDU1LDI0LjYsNTUsNTV2MEMxMTAsODUuNCw4NS40LDExMCw1NSwxMTB6TTgxLjYsMzFjLTEuNS0xLjQtMy45LTEuNC01LjQsMC4xTDQzLjcsNjQuM2wtOC05LjZjLTEuOC0yLjItNC45LTIuNi02LjktMC45Yy0xLjgsMS41LTIuMSw0LjEtMC45LDYuMmwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonIC8+PC9zdmc+Cg==);
}
.list-checked-soft-bg-info .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9JyMwMGM5ZGInIG9wYWNpdHk9Jy4xJyBkPSdNNTUsMTEwTDU1LDExMEMyNC42LDExMCwwLDg1LjQsMCw1NWwwLDBDMCwyNC42LDI0LjYsMCw1NSwwbDAsMGMzMC40LDAsNTUsMjQuNiw1NSw1NWwwLDAgQzExMCw4NS40LDg1LjQsMTEwLDU1LDExMHonLz48cGF0aCBmaWxsPScjMDBjOWRiJyBkPSdNODEuNiwzMWMtMS41LTEuNC0zLjktMS40LTUuNCwwLjFMNDMuNyw2NC4zbC04LTkuNmMtMS44LTIuMi00LjktMi42LTYuOS0wLjljLTEuOCwxLjUtMi4xLDQuMS0wLjksNi4yIGwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonLz48L3N2Zz4=);
}
.list-checked-warning .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nOScgdmlld0JveD0nMCAwIDEwMCA5MCcgZmlsbD0nI2Y1Y2E5OScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOTguMSwxNi4yYy0yLjUtMi4zLTYuNC0yLjItOC43LDAuMkwzNi43LDcwLjFsLTEzLTE1LjVjLTIuOS0zLjUtNy45LTQuMS0xMS4xLTEuNGMtMi45LDIuNC0zLjUsNi42LTEuNCwxMC4xbDE2LjUsMjhjMy4yLDUuNCwxMC44LDUuOCwxNC41LDAuOGw1Ni41LTY3LjNDMTAwLjcsMjIuMSwxMDAuNCwxOC41LDk4LjEsMTYuMnonLz48L3N2Zz4K);
}
.list-checked-bg-warning .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyBmaWxsPScjZjVjYTk5JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J001NSwxMTBMNTUsMTEwQzI0LjYsMTEwLDAsODUuNCwwLDU1djBDMCwyNC42LDI0LjYsMCw1NSwwaDBjMzAuNCwwLDU1LDI0LjYsNTUsNTV2MEMxMTAsODUuNCw4NS40LDExMCw1NSwxMTB6TTgxLjYsMzFjLTEuNS0xLjQtMy45LTEuNC01LjQsMC4xTDQzLjcsNjQuM2wtOC05LjZjLTEuOC0yLjItNC45LTIuNi02LjktMC45Yy0xLjgsMS41LTIuMSw0LjEtMC45LDYuMmwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonIC8+PC9zdmc+Cg==);
}
.list-checked-soft-bg-warning .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9JyNmNWNhOTknIG9wYWNpdHk9Jy4xJyBkPSdNNTUsMTEwTDU1LDExMEMyNC42LDExMCwwLDg1LjQsMCw1NWwwLDBDMCwyNC42LDI0LjYsMCw1NSwwbDAsMGMzMC40LDAsNTUsMjQuNiw1NSw1NWwwLDAgQzExMCw4NS40LDg1LjQsMTEwLDU1LDExMHonLz48cGF0aCBmaWxsPScjZjVjYTk5JyBkPSdNODEuNiwzMWMtMS41LTEuNC0zLjktMS40LTUuNCwwLjFMNDMuNyw2NC4zbC04LTkuNmMtMS44LTIuMi00LjktMi42LTYuOS0wLjljLTEuOCwxLjUtMi4xLDQuMS0wLjksNi4yIGwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonLz48L3N2Zz4=);
}
.list-checked-danger .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nOScgdmlld0JveD0nMCAwIDEwMCA5MCcgZmlsbD0nI2VkNGM3OCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOTguMSwxNi4yYy0yLjUtMi4zLTYuNC0yLjItOC43LDAuMkwzNi43LDcwLjFsLTEzLTE1LjVjLTIuOS0zLjUtNy45LTQuMS0xMS4xLTEuNGMtMi45LDIuNC0zLjUsNi42LTEuNCwxMC4xbDE2LjUsMjhjMy4yLDUuNCwxMC44LDUuOCwxNC41LDAuOGw1Ni41LTY3LjNDMTAwLjcsMjIuMSwxMDAuNCwxOC41LDk4LjEsMTYuMnonLz48L3N2Zz4K);
}
.list-checked-bg-danger .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyBmaWxsPScjZWQ0Yzc4JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J001NSwxMTBMNTUsMTEwQzI0LjYsMTEwLDAsODUuNCwwLDU1djBDMCwyNC42LDI0LjYsMCw1NSwwaDBjMzAuNCwwLDU1LDI0LjYsNTUsNTV2MEMxMTAsODUuNCw4NS40LDExMCw1NSwxMTB6TTgxLjYsMzFjLTEuNS0xLjQtMy45LTEuNC01LjQsMC4xTDQzLjcsNjQuM2wtOC05LjZjLTEuOC0yLjItNC45LTIuNi02LjktMC45Yy0xLjgsMS41LTIuMSw0LjEtMC45LDYuMmwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonIC8+PC9zdmc+Cg==);
}
.list-checked-soft-bg-danger .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9JyNlZDRjNzgnIG9wYWNpdHk9Jy4xJyBkPSdNNTUsMTEwTDU1LDExMEMyNC42LDExMCwwLDg1LjQsMCw1NWwwLDBDMCwyNC42LDI0LjYsMCw1NSwwbDAsMGMzMC40LDAsNTUsMjQuNiw1NSw1NWwwLDAgQzExMCw4NS40LDg1LjQsMTEwLDU1LDExMHonLz48cGF0aCBmaWxsPScjZWQ0Yzc4JyBkPSdNODEuNiwzMWMtMS41LTEuNC0zLjktMS40LTUuNCwwLjFMNDMuNyw2NC4zbC04LTkuNmMtMS44LTIuMi00LjktMi42LTYuOS0wLjljLTEuOCwxLjUtMi4xLDQuMS0wLjksNi4yIGwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonLz48L3N2Zz4=);
}
.list-checked-light .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nOScgdmlld0JveD0nMCAwIDEwMCA5MCcgZmlsbD0nI2Y5ZmFmYycgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOTguMSwxNi4yYy0yLjUtMi4zLTYuNC0yLjItOC43LDAuMkwzNi43LDcwLjFsLTEzLTE1LjVjLTIuOS0zLjUtNy45LTQuMS0xMS4xLTEuNGMtMi45LDIuNC0zLjUsNi42LTEuNCwxMC4xbDE2LjUsMjhjMy4yLDUuNCwxMC44LDUuOCwxNC41LDAuOGw1Ni41LTY3LjNDMTAwLjcsMjIuMSwxMDAuNCwxOC41LDk4LjEsMTYuMnonLz48L3N2Zz4K);
}
.list-checked-bg-light .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyBmaWxsPScjZjlmYWZjJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J001NSwxMTBMNTUsMTEwQzI0LjYsMTEwLDAsODUuNCwwLDU1djBDMCwyNC42LDI0LjYsMCw1NSwwaDBjMzAuNCwwLDU1LDI0LjYsNTUsNTV2MEMxMTAsODUuNCw4NS40LDExMCw1NSwxMTB6TTgxLjYsMzFjLTEuNS0xLjQtMy45LTEuNC01LjQsMC4xTDQzLjcsNjQuM2wtOC05LjZjLTEuOC0yLjItNC45LTIuNi02LjktMC45Yy0xLjgsMS41LTIuMSw0LjEtMC45LDYuMmwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonIC8+PC9zdmc+Cg==);
}
.list-checked-soft-bg-light .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9JyNmOWZhZmMnIG9wYWNpdHk9Jy4xJyBkPSdNNTUsMTEwTDU1LDExMEMyNC42LDExMCwwLDg1LjQsMCw1NWwwLDBDMCwyNC42LDI0LjYsMCw1NSwwbDAsMGMzMC40LDAsNTUsMjQuNiw1NSw1NWwwLDAgQzExMCw4NS40LDg1LjQsMTEwLDU1LDExMHonLz48cGF0aCBmaWxsPScjZjlmYWZjJyBkPSdNODEuNiwzMWMtMS41LTEuNC0zLjktMS40LTUuNCwwLjFMNDMuNyw2NC4zbC04LTkuNmMtMS44LTIuMi00LjktMi42LTYuOS0wLjljLTEuOCwxLjUtMi4xLDQuMS0wLjksNi4yIGwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonLz48L3N2Zz4=);
}
.list-checked-dark .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTAnIGhlaWdodD0nOScgdmlld0JveD0nMCAwIDEwMCA5MCcgZmlsbD0nIzEzMjE0NCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOTguMSwxNi4yYy0yLjUtMi4zLTYuNC0yLjItOC43LDAuMkwzNi43LDcwLjFsLTEzLTE1LjVjLTIuOS0zLjUtNy45LTQuMS0xMS4xLTEuNGMtMi45LDIuNC0zLjUsNi42LTEuNCwxMC4xbDE2LjUsMjhjMy4yLDUuNCwxMC44LDUuOCwxNC41LDAuOGw1Ni41LTY3LjNDMTAwLjcsMjIuMSwxMDAuNCwxOC41LDk4LjEsMTYuMnonLz48L3N2Zz4K);
}
.list-checked-bg-dark .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyBmaWxsPScjMTMyMTQ0JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGQ9J001NSwxMTBMNTUsMTEwQzI0LjYsMTEwLDAsODUuNCwwLDU1djBDMCwyNC42LDI0LjYsMCw1NSwwaDBjMzAuNCwwLDU1LDI0LjYsNTUsNTV2MEMxMTAsODUuNCw4NS40LDExMCw1NSwxMTB6TTgxLjYsMzFjLTEuNS0xLjQtMy45LTEuNC01LjQsMC4xTDQzLjcsNjQuM2wtOC05LjZjLTEuOC0yLjItNC45LTIuNi02LjktMC45Yy0xLjgsMS41LTIuMSw0LjEtMC45LDYuMmwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonIC8+PC9zdmc+Cg==);
}
.list-checked-soft-bg-dark .list-checked-item::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTYnIGhlaWdodD0nMTYnIHZpZXdCb3g9JzAgMCAxMTAgMTEwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGw9JyMxMzIxNDQnIG9wYWNpdHk9Jy4xJyBkPSdNNTUsMTEwTDU1LDExMEMyNC42LDExMCwwLDg1LjQsMCw1NWwwLDBDMCwyNC42LDI0LjYsMCw1NSwwbDAsMGMzMC40LDAsNTUsMjQuNiw1NSw1NWwwLDAgQzExMCw4NS40LDg1LjQsMTEwLDU1LDExMHonLz48cGF0aCBmaWxsPScjMTMyMTQ0JyBkPSdNODEuNiwzMWMtMS41LTEuNC0zLjktMS40LTUuNCwwLjFMNDMuNyw2NC4zbC04LTkuNmMtMS44LTIuMi00LjktMi42LTYuOS0wLjljLTEuOCwxLjUtMi4xLDQuMS0wLjksNi4yIGwxMC4yLDE3LjNjMiwzLjMsNi43LDMuNiw5LDAuNUw4MiwzNi4yQzgzLjMsMzQuNyw4My4xLDMyLjQsODEuNiwzMXonLz48L3N2Zz4=);
}
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  width: 23rem;
  height: 100%;
  background-color: #fff;
}
.sidebar-bordered {
  border-left: 0.0625rem solid #e7eaf3;
}
.sidebar-box-shadow {
  box-shadow: -5px 0 12px rgba(103, 119, 136, 0.1);
}
.sidebar-left {
  left: 0;
  right: auto;
}
.sidebar-left.sidebar-bordered {
  border-left: none;
  border-right: 0.0625rem solid #e7eaf3;
}
.sidebar-left.sidebar-box-shadow {
  box-shadow: 5px 0 12px rgba(103, 119, 136, 0.1);
}
.sidebar-close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  z-index: 2;
}
.sidebar-card {
  position: relative;
  height: 100%;
  border-radius: 0;
}
.sidebar-body {
  min-height: auto;
}
.sidebar-scrollbar {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.sidebar-scrollbar::-webkit-scrollbar {
  width: 0.6125rem;
}
.sidebar-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
  visibility: hidden;
}
.sidebar-scrollbar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.sidebar-footer-fixed {
  padding-bottom: 4.75rem;
}
.sidebar-footer-fixed .sidebar-body {
  height: calc(100% - 4.75rem);
}
.sidebar-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4.75rem;
}
.table th {
  font-weight: 400;
}
.table thead th {
  font-size: 0.75rem;
  text-transform: capitalize;
  font-weight: 600;
}
.table tfoot td,
.table tfoot th {
  color: #1e2022;
  font-weight: 600;
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.table .btn {
  white-space: nowrap;
}
.table-sm {
  font-size: 0.8125rem;
}
.table-lg td,
.table-lg th {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}
.table-align-middle tbody td {
  vertical-align: middle;
}
.table-text-center td,
.table-text-center th {
  text-align: center;
}
.table-column-right-aligned {
  text-align: right;
}
.table-column-pr-0 {
  padding-right: 0 !important;
}
.table-column-pl-0 {
  padding-left: 0 !important;
}
.table-thead-bordered thead th {
  border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.table-input-group {
  min-width: 14rem;
}
.table-progress {
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 5rem;
  height: 0.25rem;
}
.dropdown-menu {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
}
.dropdown-item {
  font-size: 0.8125rem;
}
.dropdown-item:active {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}
.dropdown-item.active:not(:focus):not(:active) {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}
.dropdown-item[href]:hover.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.dropdown-header {
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
  font-size: 80%;
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.dropdown-menu-sm {
  padding: 0.75rem 0;
}
.dropdown-menu-sm .dropdown-header,
.dropdown-menu-sm .dropdown-item {
  padding: 0.25rem 1rem;
}
.dropdown-menu-sm .dropdown-divider {
  margin: 0.25rem;
}
.dropdown-menu-lg .dropdown-header,
.dropdown-menu-lg .dropdown-item {
  padding: 0.5rem 1.5rem;
}
.dropdown-menu-lg .dropdown-item {
  font-size: 0.875rem;
}
.dropdown-menu-lg .dropdown-item-icon {
  font-size: 1.125rem;
  width: 1.25rem;
  margin-right: 0.5rem;
}
.dropdown-item-icon {
  display: inline-block;
  opacity: 0.7;
  font-size: 1rem;
  width: 1.5rem;
  color: #677788;
}
.dropdown-toggle {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: "";
}
.dropdown-toggle:not(.dropdown-toggle-empty)::after {
  margin-left: 0.3125rem;
}
.dropup .dropdown-toggle::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.dropright .dropdown-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.dropleft .dropdown-toggle::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.dropdown-toggle-collapse::after {
  transition: 0.3s;
}
.dropdown-toggle-collapse[aria-expanded="true"]::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.dropdown-card {
  padding-top: 0;
  padding-bottom: 0;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.5rem;
}
.footer:not([class*="container"]) {
  padding-right: 2rem;
  padding-left: 2rem;
}
@media (max-width: 575.98px) {
  .footer:not([class*="container"]) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.footer-height-offset {
  height: calc(100vh - 3.5rem);
}
.custom-select:focus,
.form-control:focus {
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
}
.form-text {
  color: #99a7ba;
}
.form-link {
  display: inline-block;
  margin-top: 0.75rem;
}
.form-control-single-number {
  font-size: 1.75rem;
  text-align: center;
}
.form-control-title {
  font-size: 1.75rem;
  line-height: normal;
  font-weight: 600;
  border: none;
  padding: 0;
}
.form-control-title:focus {
  box-shadow: none;
}
.custom-control-label,
.custom-select {
  cursor: pointer;
}
.form-control .custom-control-label {
  width: 100%;
}
.custom-control-input:checked:disabled ~ .custom-control-label::before {
  border-color: transparent;
}
.custom-select-lg {
  border-radius: 0.3125rem;
}
.custom-select-sm {
  border-radius: 0.25rem;
}
.custom-radio-reverse {
  width: 100%;
  padding-left: 0;
  padding-right: 1.5rem;
}
.custom-radio-reverse .custom-control-label {
  width: 100%;
}
.custom-radio-reverse .custom-control-label::before {
  left: auto;
  right: -1.5rem;
}
.custom-radio-reverse .custom-control-label::after {
  left: auto;
  right: -1.5rem;
}
.input-group-text {
  border-color: #e7eaf3;
}
.input-label {
  display: block;
  color: #1e2022;
  font-size: 0.875rem;
}
.input-label-secondary {
  color: #99a7ba;
  font-size: 0.8125rem;
  margin-left: 0.25rem;
}
.input-group .input-group-append,
.input-group .input-group-prepend {
  z-index: 4;
}
.input-group > .input-group-prepend .custom-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append .custom-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group
  > .input-group-prepend:first-child
  .select2-custom:not(:first-child)
  .custom-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group
  > .input-group-append:last-child
  .select2-custom:not(:last-child)
  .custom-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media (max-width: 575.98px) {
  .input-group-append-last-sm-down-none.input-group-append:last-child
    .select2-custom:not(:last-child)
    .custom-select {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
  }
}
.input-group-add-field {
  position: relative;
  margin-top: 1rem;
}
.input-group-add-field:hover .input-group-add-field-delete {
  opacity: 1;
}
.input-group-add-field-delete {
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 1rem;
  color: #ed4c78;
  opacity: 0;
  margin-right: -1rem;
  padding-left: 0.25rem;
}
.input-group-add-field-delete:hover {
  color: #ea2c60;
}
@media (max-width: 1199.98px) {
  .input-group-add-field-delete {
    opacity: 1;
  }
}
.form-control-borderless,
.input-group-borderless .form-control,
.input-group-borderless .input-group-text {
  border: none;
}
.form-control-borderless:focus,
.input-group-borderless .form-control:focus,
.input-group-borderless .input-group-text:focus {
  box-shadow: none;
}
.custom-select-borderless {
  border-color: transparent;
}
.custom-select-borderless:focus {
  box-shadow: none;
  border-color: transparent;
}
@media (max-width: 575.98px) {
  .input-group-sm-down-break {
    display: block;
  }
  .input-group-sm-down-break > .custom-select,
  .input-group-sm-down-break > .form-control {
    width: 100%;
  }
  .input-group-sm-down-break .select2-custom,
  .input-group-sm-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-sm-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-sm-down-break .select2-custom:first-child .select2-selection,
  .input-group-sm-down-break > .custom-select:first-child,
  .input-group-sm-down-break > .form-control:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-sm-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection,
  .input-group-sm-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-sm-down-break
    > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-sm-down-break .select2-custom:last-child .select2-selection,
  .input-group-sm-down-break > .custom-select:last-child,
  .input-group-sm-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-sm-down-break .custom-file + .custom-file,
  .input-group-sm-down-break .custom-file + .custom-select,
  .input-group-sm-down-break .custom-file + .form-control,
  .input-group-sm-down-break .custom-select + .custom-file,
  .input-group-sm-down-break .custom-select + .custom-select,
  .input-group-sm-down-break .custom-select + .form-control,
  .input-group-sm-down-break .form-control + .custom-file,
  .input-group-sm-down-break .form-control + .custom-select,
  .input-group-sm-down-break .form-control + .form-control,
  .input-group-sm-down-break .form-control-plaintext + .custom-file,
  .input-group-sm-down-break .form-control-plaintext + .custom-select,
  .input-group-sm-down-break .form-control-plaintext + .form-control,
  .input-group-sm-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}
@media (max-width: 767.98px) {
  .input-group-md-down-break {
    display: block;
  }
  .input-group-md-down-break > .custom-select,
  .input-group-md-down-break > .form-control {
    width: 100%;
  }
  .input-group-md-down-break .select2-custom,
  .input-group-md-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-md-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-md-down-break .select2-custom:first-child .select2-selection,
  .input-group-md-down-break > .custom-select:first-child,
  .input-group-md-down-break > .form-control:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-md-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection,
  .input-group-md-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-md-down-break
    > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-md-down-break .select2-custom:last-child .select2-selection,
  .input-group-md-down-break > .custom-select:last-child,
  .input-group-md-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-md-down-break .custom-file + .custom-file,
  .input-group-md-down-break .custom-file + .custom-select,
  .input-group-md-down-break .custom-file + .form-control,
  .input-group-md-down-break .custom-select + .custom-file,
  .input-group-md-down-break .custom-select + .custom-select,
  .input-group-md-down-break .custom-select + .form-control,
  .input-group-md-down-break .form-control + .custom-file,
  .input-group-md-down-break .form-control + .custom-select,
  .input-group-md-down-break .form-control + .form-control,
  .input-group-md-down-break .form-control-plaintext + .custom-file,
  .input-group-md-down-break .form-control-plaintext + .custom-select,
  .input-group-md-down-break .form-control-plaintext + .form-control,
  .input-group-md-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}
@media (max-width: 991.98px) {
  .input-group-lg-down-break {
    display: block;
  }
  .input-group-lg-down-break > .custom-select,
  .input-group-lg-down-break > .form-control {
    width: 100%;
  }
  .input-group-lg-down-break .select2-custom,
  .input-group-lg-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-lg-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-lg-down-break .select2-custom:first-child .select2-selection,
  .input-group-lg-down-break > .custom-select:first-child,
  .input-group-lg-down-break > .form-control:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-lg-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection,
  .input-group-lg-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-lg-down-break
    > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-lg-down-break .select2-custom:last-child .select2-selection,
  .input-group-lg-down-break > .custom-select:last-child,
  .input-group-lg-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-lg-down-break .custom-file + .custom-file,
  .input-group-lg-down-break .custom-file + .custom-select,
  .input-group-lg-down-break .custom-file + .form-control,
  .input-group-lg-down-break .custom-select + .custom-file,
  .input-group-lg-down-break .custom-select + .custom-select,
  .input-group-lg-down-break .custom-select + .form-control,
  .input-group-lg-down-break .form-control + .custom-file,
  .input-group-lg-down-break .form-control + .custom-select,
  .input-group-lg-down-break .form-control + .form-control,
  .input-group-lg-down-break .form-control-plaintext + .custom-file,
  .input-group-lg-down-break .form-control-plaintext + .custom-select,
  .input-group-lg-down-break .form-control-plaintext + .form-control,
  .input-group-lg-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}
@media (max-width: 1199.98px) {
  .input-group-xl-down-break {
    display: block;
  }
  .input-group-xl-down-break > .custom-select,
  .input-group-xl-down-break > .form-control {
    width: 100%;
  }
  .input-group-xl-down-break .select2-custom,
  .input-group-xl-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-xl-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-xl-down-break .select2-custom:first-child .select2-selection,
  .input-group-xl-down-break > .custom-select:first-child,
  .input-group-xl-down-break > .form-control:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xl-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection,
  .input-group-xl-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-xl-down-break
    > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-xl-down-break .select2-custom:last-child .select2-selection,
  .input-group-xl-down-break > .custom-select:last-child,
  .input-group-xl-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-xl-down-break .custom-file + .custom-file,
  .input-group-xl-down-break .custom-file + .custom-select,
  .input-group-xl-down-break .custom-file + .form-control,
  .input-group-xl-down-break .custom-select + .custom-file,
  .input-group-xl-down-break .custom-select + .custom-select,
  .input-group-xl-down-break .custom-select + .form-control,
  .input-group-xl-down-break .form-control + .custom-file,
  .input-group-xl-down-break .form-control + .custom-select,
  .input-group-xl-down-break .form-control + .form-control,
  .input-group-xl-down-break .form-control-plaintext + .custom-file,
  .input-group-xl-down-break .form-control-plaintext + .custom-select,
  .input-group-xl-down-break .form-control-plaintext + .form-control,
  .input-group-xl-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}
@media (max-width: 1399.98px) {
  .input-group-xxl-down-break {
    display: block;
  }
  .input-group-xxl-down-break > .custom-select,
  .input-group-xxl-down-break > .form-control {
    width: 100%;
  }
  .input-group-xxl-down-break .select2-custom,
  .input-group-xxl-down-break .select2-custom .select2-container {
    width: 100% !important;
  }
  .input-group-xxl-down-break .select2-custom {
    margin-left: 1px;
  }
  .input-group-xxl-down-break .select2-custom:first-child .select2-selection,
  .input-group-xxl-down-break > .custom-select:first-child,
  .input-group-xxl-down-break > .form-control:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group-xxl-down-break
    .select2-custom:not(:first-child):not(:last-child)
    .select2-selection,
  .input-group-xxl-down-break
    > .custom-select:not(:first-child):not(:last-child),
  .input-group-xxl-down-break
    > .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .input-group-xxl-down-break .select2-custom:last-child .select2-selection,
  .input-group-xxl-down-break > .custom-select:last-child,
  .input-group-xxl-down-break > .form-control:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
  .input-group-xxl-down-break .custom-file + .custom-file,
  .input-group-xxl-down-break .custom-file + .custom-select,
  .input-group-xxl-down-break .custom-file + .form-control,
  .input-group-xxl-down-break .custom-select + .custom-file,
  .input-group-xxl-down-break .custom-select + .custom-select,
  .input-group-xxl-down-break .custom-select + .form-control,
  .input-group-xxl-down-break .form-control + .custom-file,
  .input-group-xxl-down-break .form-control + .custom-select,
  .input-group-xxl-down-break .form-control + .form-control,
  .input-group-xxl-down-break .form-control-plaintext + .custom-file,
  .input-group-xxl-down-break .form-control-plaintext + .custom-select,
  .input-group-xxl-down-break .form-control-plaintext + .form-control,
  .input-group-xxl-down-break .select2-custom .select2-selection {
    margin-left: 0;
    margin-top: -0.0625rem;
  }
}
.input-group-down-break {
  display: block;
}
.input-group-down-break > .custom-select,
.input-group-down-break > .form-control {
  width: 100%;
}
.input-group-down-break .select2-custom,
.input-group-down-break .select2-custom .select2-container {
  width: 100% !important;
}
.input-group-down-break .select2-custom {
  margin-left: 1px;
}
.input-group-down-break .select2-custom:first-child .select2-selection,
.input-group-down-break > .custom-select:first-child,
.input-group-down-break > .form-control:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-down-break
  .select2-custom:not(:first-child):not(:last-child)
  .select2-selection,
.input-group-down-break > .custom-select:not(:first-child):not(:last-child),
.input-group-down-break > .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-down-break .select2-custom:last-child .select2-selection,
.input-group-down-break > .custom-select:last-child,
.input-group-down-break > .form-control:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group-down-break .custom-file + .custom-file,
.input-group-down-break .custom-file + .custom-select,
.input-group-down-break .custom-file + .form-control,
.input-group-down-break .custom-select + .custom-file,
.input-group-down-break .custom-select + .custom-select,
.input-group-down-break .custom-select + .form-control,
.input-group-down-break .form-control + .custom-file,
.input-group-down-break .form-control + .custom-select,
.input-group-down-break .form-control + .form-control,
.input-group-down-break .form-control-plaintext + .custom-file,
.input-group-down-break .form-control-plaintext + .custom-select,
.input-group-down-break .form-control-plaintext + .form-control,
.input-group-down-break .select2-custom .select2-selection {
  margin-left: 0;
  margin-top: -0.0625rem;
}
.custom-select-light,
.form-control-light,
.input-group-light .custom-select,
.input-group-light .form-control {
  background-color: #f6f7fa;
  box-shadow: none;
}
.custom-select-light:disabled,
.custom-select-light[readonly],
.form-control-light:disabled,
.form-control-light[readonly],
.input-group-light .custom-select:disabled,
.input-group-light .custom-select[readonly],
.input-group-light .form-control:disabled,
.input-group-light .form-control[readonly] {
  background-color: transparent;
}
.custom-select-hover-light.focus,
.custom-select-hover-light:focus,
.custom-select-hover-light:hover,
.form-control-hover-light.focus,
.form-control-hover-light:focus,
.form-control-hover-light:hover,
.input-group-hover-light .custom-select.focus,
.input-group-hover-light .custom-select:focus,
.input-group-hover-light .custom-select:hover,
.input-group-hover-light .form-control.focus,
.input-group-hover-light .form-control:focus,
.input-group-hover-light .form-control:hover {
  background-color: #f6f7fa;
  box-shadow: none;
}
.custom-select-hover-light:disabled,
.custom-select-hover-light[readonly],
.form-control-hover-light:disabled,
.form-control-hover-light[readonly],
.input-group-hover-light .custom-select:disabled,
.input-group-hover-light .custom-select[readonly],
.input-group-hover-light .form-control:disabled,
.input-group-hover-light .form-control[readonly] {
  background-color: transparent;
}
.input-group-merge {
  position: relative;
}
.input-group-merge .input-group-append,
.input-group-merge .input-group-prepend {
  position: absolute;
  top: 0;
  bottom: 0;
}
.input-group-merge .input-group-prepend {
  left: 0;
}
.input-group-merge .input-group-prepend .input-group-text {
  padding: 0 0 0 0.875rem;
}
.input-group-merge .input-group-prepend .input-group-text:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-merge .input-group-append {
  right: 0;
}
.input-group-merge .input-group-append .input-group-text {
  padding: 0 0.875rem 0 0;
}
.input-group-merge .input-group-append .input-group-text:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-merge .input-group-text {
  z-index: 4;
  border: none;
}
.input-group-merge .custom-select:not(:first-child),
.input-group-merge .form-control:not(:first-child) {
  padding-left: 2.625rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group-merge .custom-select:not(:last-child),
.input-group-merge .form-control:not(:last-child) {
  padding-right: 2.625rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.custom-select-flush,
.form-control-flush {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.custom-select-flush.is-invalid,
.custom-select-flush.is-invalid:focus,
.custom-select-flush.is-valid,
.custom-select-flush.is-valid:focus,
.form-control-flush.is-invalid,
.form-control-flush.is-invalid:focus,
.form-control-flush.is-valid,
.form-control-flush.is-valid:focus {
  box-shadow: none;
}
.form-control-flush:focus {
  box-shadow: none;
}
.input-group-flush .custom-select,
.input-group-flush .form-control {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.input-group-flush .custom-select.is-invalid,
.input-group-flush .custom-select.is-invalid:focus,
.input-group-flush .custom-select.is-valid,
.input-group-flush .custom-select.is-valid:focus,
.input-group-flush .form-control.is-invalid,
.input-group-flush .form-control.is-invalid:focus,
.input-group-flush .form-control.is-valid,
.input-group-flush .form-control.is-valid:focus {
  box-shadow: none;
}
.input-group-flush .input-group-append .input-group-text,
.input-group-flush .input-group-prepend .input-group-text {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}
.input-group-flush .input-group-prepend .input-group-text {
  padding-left: 0;
}
.input-group-flush .input-group-append .input-group-text {
  padding-left: 0;
}
.input-group-flush.input-group-merge .input-group-append .input-group-text,
.input-group-flush.input-group-merge .input-group-prepend .input-group-text {
  padding: 0;
}
.input-group-flush.input-group-merge .custom-select:not(:first-child),
.input-group-flush.input-group-merge .form-control:not(:first-child) {
  padding-left: 1.75rem;
}
.input-group-flush.input-group-merge .custom-select:not(:last-child),
.input-group-flush.input-group-merge .form-control:not(:last-child) {
  padding-right: 1.75rem;
}
.input-group-flush.input-group .custom-select:not(:first-child),
.input-group-flush.input-group .custom-select:not(:last-child),
.input-group-flush.input-group .form-control:not(:first-child),
.input-group-flush.input-group .form-control:not(:last-child),
.input-group-flush.input-group
  .input-group-append
  .input-group-text:first-child,
.input-group-flush.input-group .input-group-append .input-group-text:last-child,
.input-group-flush.input-group-merge .input-group-append .input-group-text,
.input-group-flush.input-group-merge .input-group-prepend .input-group-text {
  border-radius: 0;
}
.custom-select-pill,
.form-control-pill {
  border-radius: 6.1875rem;
}
.input-group-pill {
  border-radius: 6.1875rem;
}
.input-group-pill > .custom-select:first-child,
.input-group-pill > .form-control:first-child {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.input-group-pill > .custom-select:last-child,
.input-group-pill > .form-control:last-child {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}
.input-group-pill > .input-group-prepend > .btn,
.input-group-pill > .input-group-prepend > .input-group-text {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.input-group-pill > .input-group-append > .btn,
.input-group-pill > .input-group-append > .input-group-text {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}
.input-group-pill.input-group-merge .form-control:not(:last-child) {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}
.input-group-pill.input-group-merge .input-group-prepend > .btn,
.input-group-pill.input-group-merge .input-group-prepend > .input-group-text {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.input-group-pill.input-group-merge .form-control:not(:first-child) {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.input-group-pill.input-group-merge .input-group-append > .btn,
.input-group-pill.input-group-merge .input-group-append > .input-group-text {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}
.input-group-pill.input-group-merge .custom-select {
  border-radius: 6.1875rem;
}
.input-group-quantity-counter {
  position: relative;
  width: 8rem;
}
.input-group-quantity-counter .input-group-quantity-counter-control {
  padding-right: 4.0625rem;
}
.input-group-quantity-counter .input-group-quantity-counter-toggle {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.input-group-quantity-counter .input-group-quantity-counter-btn {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #677788;
  font-size: 0.75rem;
  width: 1.53125rem;
  height: 1.53125rem;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 50%;
}
.input-group-quantity-counter .input-group-quantity-counter-btn:hover {
  color: #0052ea;
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.input-group-sm.input-group-merge .custom-select:not(:first-child),
.input-group-sm.input-group-merge .form-control:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.input-group-sm.input-group-merge .custom-select:not(:last-child),
.input-group-sm.input-group-merge .form-control:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.input-group-sm.input-group-merge
  .input-group-prepend
  .input-group-text:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.input-group-sm.input-group-merge
  .input-group-append
  .input-group-text:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.input-group-lg.input-group-merge .custom-select:not(:first-child),
.input-group-lg.input-group-merge .form-control:not(:first-child) {
  padding-left: 2.625rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group-lg.input-group-merge .custom-select:not(:last-child),
.input-group-lg.input-group-merge .form-control:not(:last-child) {
  padding-right: 2.625rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.input-group-lg.input-group-merge
  .input-group-prepend
  .input-group-text:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.input-group-lg.input-group-merge
  .input-group-append
  .input-group-text:last-child {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.form-control.is-valid {
  background-size: 1rem 1rem;
}
.form-control.is-valid,
.form-control.is-valid:focus {
  box-shadow: 0 0 10px rgba(0, 201, 167, 0.1);
}
.form-control.is-invalid {
  background-size: 1rem 1rem;
}
.form-control.is-invalid,
.form-control.is-invalid:focus {
  box-shadow: 0 0 10px rgba(237, 76, 120, 0.1);
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #00c9a7;
  background-color: #00c9a7;
}
.is-valid .custom-select {
  background-size: 1rem 1rem;
  border-color: #00c9a7;
}
.is-valid .custom-select,
.is-valid .custom-select:focus {
  box-shadow: 0 0 10px rgba(0, 201, 167, 0.1);
}
.is-invalid .custom-select {
  background-size: 1rem 1rem;
  border-color: #ed4c78;
}
.is-invalid .custom-select,
.is-invalid .custom-select:focus {
  box-shadow: 0 0 10px rgba(237, 76, 120, 0.1);
}
.custom-checkbox-bookmark {
  padding-left: 0;
}
.custom-checkbox-bookmark-label {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: #bdc5d1;
  font-size: 1.09375rem;
  margin-bottom: 0;
}
.custom-checkbox-bookmark-active,
.custom-checkbox-bookmark-default {
  margin-top: -1px;
}
.custom-checkbox-bookmark-active {
  color: #ffc107;
}
.custom-checkbox-bookmark-input
  ~ .custom-checkbox-bookmark-label
  .custom-checkbox-bookmark-default {
  display: block;
}
.custom-checkbox-bookmark-input
  ~ .custom-checkbox-bookmark-label
  .custom-checkbox-bookmark-active {
  display: none;
}
.custom-checkbox-bookmark-input:checked
  ~ .custom-checkbox-bookmark-label
  .custom-checkbox-bookmark-default {
  display: none;
}
.custom-checkbox-bookmark-input:checked
  ~ .custom-checkbox-bookmark-label
  .custom-checkbox-bookmark-active {
  display: block;
}
.custom-checkbox-bookmark-input:disabled ~ .custom-checkbox-bookmark-label {
  color: #e7eaf3;
}
.custom-checkbox-card {
  position: relative;
  height: 100%;
  padding-left: 0;
  margin-right: 0;
}
.custom-checkbox-card.card {
  border-width: 0;
}
.custom-checkbox-card-input {
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox-card-label {
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  cursor: pointer;
  margin-bottom: 0;
}
.custom-checkbox-card-label::after {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  z-index: 1;
  content: "";
}
.custom-checkbox-card-label-stretched {
  position: absolute;
  top: 0;
  left: 0;
}
.custom-checkbox-card-label-stretched::after {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23e7eaf3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.custom-checkbox-card-img {
  max-width: 100%;
  height: auto;
  border-radius: 0.3125rem;
}
.custom-checkbox-card-text {
  display: block;
  padding: 0.3125rem 0;
}
.custom-checkbox-card-lg,
.custom-checkbox-card-lg .custom-checkbox-card-label {
  border-radius: 0.75rem;
}
.custom-checkbox-card-lg .custom-checkbox-card-label::after {
  top: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23e7eaf3' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
    no-repeat right center/1.25rem 1.25rem;
}
.custom-checkbox-card-input:checked ~ .custom-checkbox-card-label::after {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.custom-checkbox-card-lg
  .custom-checkbox-card-input:checked
  ~ .custom-checkbox-card-label::after {
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55,110L55,110C24.6,110,0,85.4,0,55v0C0,24.6,24.6,0,55,0h0c30.4,0,55,24.6,55,55v0C110,85.4,85.4,110,55,110zM81.6,31c-1.5-1.4-3.9-1.4-5.4,0.1L43.7,64.3l-8-9.6c-1.8-2.2-4.9-2.6-6.9-0.9c-1.8,1.5-2.1,4.1-0.9,6.2l10.2,17.3c2,3.3,6.7,3.6,9,0.5L82,36.2C83.3,34.7,83.1,32.4,81.6,31z'/%3E%3C/svg%3E")
    no-repeat right center/1.25rem 1.25rem;
}
.custom-checkbox-card-input:checked ~ .custom-checkbox-card-label,
.custom-checkbox-card-input:checked ~ .custom-checkbox-card-label-stretched {
  border-color: #01684b;
}
.custom-checkbox-card.checked .custom-checkbox-card-btn {
  color: #fff;
  background-color: #01684b;
  border-color: #01684b;
}
.custom-checkbox-card-input:disabled ~ .custom-checkbox-card-label {
  opacity: 0.5;
}
.custom-checkbox-card.disabled .custom-checkbox-card-btn {
  cursor: default;
  opacity: 0.5;
}
.custom-checkbox-list {
  position: static;
  z-index: auto;
}
.custom-checkbox-list
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: none;
}
.custom-checkbox-list .custom-control-label::before {
  width: 0.6875rem;
  height: 0.6875rem;
  border: none;
  background-color: #e7eaf3;
  border-radius: 50%;
}
.custom-checkbox-list .custom-control-label::after {
  z-index: 2;
}
.custom-checkbox-list:hover .custom-control-label::before {
  background-color: rgba(55, 125, 255, 0.3125);
}
.custom-checkbox-list:hover
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #01684b;
}
.custom-checkbox-list-stretched-bg::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}
.custom-checkbox-list-wrapper:hover .custom-checkbox-list-stretched-bg::after {
  background-color: rgba(55, 125, 255, 0.035);
}
.custom-checkbox-list
  .custom-control-input:checked
  ~ .custom-checkbox-list-stretched-bg::after {
  background-color: rgba(55, 125, 255, 0.035);
}
.custom-checkbox-list
  .custom-control-input:disabled
  ~ .custom-control-label::before {
  background-color: #99a7ba;
}
.custom-checkbox-switch {
  display: inline-block;
  padding-left: 0;
}
.custom-checkbox-switch-label {
  cursor: pointer;
  color: #01684b;
  background-color: #fff;
  border: 0.0625rem solid #01684b;
  margin-bottom: 0;
}
.custom-checkbox-switch-label:hover {
  color: #01684b;
}
.custom-checkbox-switch-label-btn-dashed {
  border: 0.0625rem dashed #e7eaf3;
}
.custom-checkbox-switch-input
  ~ .custom-checkbox-switch-label
  .custom-checkbox-switch-default {
  display: block;
}
.custom-checkbox-switch-input
  ~ .custom-checkbox-switch-label
  .custom-checkbox-switch-active {
  display: none;
}
.custom-checkbox-switch-input:checked ~ .custom-checkbox-switch-label {
  color: #fff;
  border-style: solid;
  background-color: #01684b;
}
.custom-checkbox-switch-input:checked
  ~ .custom-checkbox-switch-label
  .custom-checkbox-switch-default {
  display: none;
}
.custom-checkbox-switch-input:checked
  ~ .custom-checkbox-switch-label
  .custom-checkbox-switch-active {
  display: block;
}
.custom-checkbox-switch-input:disabled ~ .custom-checkbox-switch-label {
  color: #99a7ba;
  background-color: rgba(231, 234, 243, 0.5);
}
.checkbox-outline-primary .custom-control-label::before {
  border-color: #01684b;
}
.checkbox-outline-primary
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: rgba(55, 125, 255, 0.4);
}
.checkbox-outline-primary
  .custom-control-input:checked
  ~ .custom-control-label::before,
.checkbox-outline-primary
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #01684b;
  background-color: #01684b;
}
.checkbox-outline-secondary .custom-control-label::before {
  border-color: #71869d;
}
.checkbox-outline-secondary
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: rgba(113, 134, 157, 0.4);
}
.checkbox-outline-secondary
  .custom-control-input:checked
  ~ .custom-control-label::before,
.checkbox-outline-secondary
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #71869d;
  background-color: #71869d;
}
.checkbox-outline-success .custom-control-label::before {
  border-color: #00c9a7;
}
.checkbox-outline-success
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: rgba(0, 201, 167, 0.4);
}
.checkbox-outline-success
  .custom-control-input:checked
  ~ .custom-control-label::before,
.checkbox-outline-success
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #00c9a7;
  background-color: #00c9a7;
}
.checkbox-outline-info .custom-control-label::before {
  border-color: #00c9db;
}
.checkbox-outline-info
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: rgba(0, 201, 219, 0.4);
}
.checkbox-outline-info
  .custom-control-input:checked
  ~ .custom-control-label::before,
.checkbox-outline-info
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #00c9db;
  background-color: #00c9db;
}
.checkbox-outline-warning .custom-control-label::before {
  border-color: #f5ca99;
}
.checkbox-outline-warning
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: rgba(245, 202, 153, 0.4);
}
.checkbox-outline-warning
  .custom-control-input:checked
  ~ .custom-control-label::before,
.checkbox-outline-warning
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #1e2022;
  border-color: #f5ca99;
  background-color: #f5ca99;
}
.checkbox-outline-danger .custom-control-label::before {
  border-color: #ed4c78;
}
.checkbox-outline-danger
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: rgba(237, 76, 120, 0.4);
}
.checkbox-outline-danger
  .custom-control-input:checked
  ~ .custom-control-label::before,
.checkbox-outline-danger
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #ed4c78;
  background-color: #ed4c78;
}
.checkbox-outline-light .custom-control-label::before {
  border-color: #f9fafc;
}
.checkbox-outline-light
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: rgba(249, 250, 252, 0.4);
}
.checkbox-outline-light
  .custom-control-input:checked
  ~ .custom-control-label::before,
.checkbox-outline-light
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #1e2022;
  border-color: #f9fafc;
  background-color: #f9fafc;
}
.checkbox-outline-dark .custom-control-label::before {
  border-color: #132144;
}
.checkbox-outline-dark
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: rgba(19, 33, 68, 0.4);
}
.checkbox-outline-dark
  .custom-control-input:checked
  ~ .custom-control-label::before,
.checkbox-outline-dark
  .custom-control-input:not(:disabled):active
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #132144;
  background-color: #132144;
}
.toggle-switch {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.toggle-switch:not(.form-group) {
  margin-bottom: 0;
}
.toggle-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.toggle-switch-content {
  -ms-flex: 1;
  flex: 1;
  margin-left: 0.5rem;
}
.toggle-switch-label {
  position: relative;
  display: block;
  width: 3rem;
  height: 2rem;
  background-color: #e7eaf3;
  background-clip: content-box;
  border: 0.125rem solid transparent;
  border-radius: 6.1875rem;
  transition: 0.3s;
}
.toggle-switch-indicator {
  position: absolute;
  left: 0.125rem;
  bottom: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  -webkit-transform: initial;
  transform: initial;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  border-radius: 50%;
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  transition: 0.3s;
}
.toggle-switch-input:checked + .toggle-switch-label {
  background-color: #01684b;
}
.toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
  -webkit-transform: translate3d(1.025rem, 50%, 0);
  transform: translate3d(1.025rem, 50%, 0);
}
.toggle-switch-input.is-valid + .toggle-switch-label {
  background-color: #00c9a7;
}
.toggle-switch-input.is-invalid + .toggle-switch-label {
  background-color: #ed4c78;
}
.toggle-switch-input:disabled + .toggle-switch-label {
  background-color: rgba(231, 234, 243, 0.5);
}
.toggle-switch-input:checked:disabled + .toggle-switch-label {
  background-color: rgba(55, 125, 255, 0.5);
}
.toggle-switch-sm .toggle-switch-label {
  width: 2.5rem;
  height: 1.6125rem;
}
.toggle-switch-sm .toggle-switch-indicator {
  width: 1.20938rem;
  height: 1.20938rem;
}
.toggle-switch-sm
  .toggle-switch-input:checked
  + .toggle-switch-label
  .toggle-switch-indicator {
  -webkit-transform: translate3d(0.81094rem, 50%, 0);
  transform: translate3d(0.81094rem, 50%, 0);
}
.custom-file-boxed {
  position: relative;
  width: 100%;
  text-align: center;
  border: 0.1rem dashed #e7eaf3;
  cursor: pointer;
  padding: 3rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}
.custom-file-boxed:hover {
  background-color: #f8fafd;
}
.custom-file-boxed-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.custom-file-boxed-label {
  text-align: center;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
}
.custom-file-boxed-sm {
  padding: 2rem;
}
.custom-file-btn {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.custom-file-btn-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.custom-file-btn-label {
  margin-bottom: 0;
}
.go-to {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  width: 2.625rem;
  height: 2.625rem;
  background-color: rgba(113, 134, 157, 0.1);
  color: #677788;
  font-size: 0.92969rem;
  opacity: 0.5;
  border-radius: 50%;
  transition: 0.3s ease-out;
}
.go-to:focus:hover,
.go-to:hover {
  color: #fff;
  background-color: #01684b;
  opacity: 1;
}
.icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.09375rem;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.3125rem;
}
.icon-circle {
  border-radius: 50%;
}
.icon-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}
.icon.icon-xs {
  font-size: 0.875rem;
  width: 1.53125rem;
  height: 1.53125rem;
}
.icon.icon-sm {
  font-size: 0.98438rem;
  width: 2.1875rem;
  height: 2.1875rem;
}
.icon.icon-lg {
  font-size: 1.53125rem;
  width: 3.36875rem;
  height: 3.36875rem;
}
.icon-primary {
  color: #fff;
  border-color: #01684b;
  background-color: #01684b;
}
.icon-secondary {
  color: #fff;
  border-color: #71869d;
  background-color: #71869d;
}
.icon-success {
  color: #fff;
  border-color: #00c9a7;
  background-color: #00c9a7;
}
.icon-info {
  color: #fff;
  border-color: #00c9db;
  background-color: #00c9db;
}
.icon-warning {
  color: #1e2022;
  border-color: #f5ca99;
  background-color: #f5ca99;
}
.icon-danger {
  color: #fff;
  border-color: #ed4c78;
  background-color: #ed4c78;
}
.icon-light {
  color: #1e2022;
  border-color: #f9fafc;
  background-color: #f9fafc;
}
.icon-dark {
  color: #fff;
  border-color: #132144;
  background-color: #132144;
}
.icon-soft-primary {
  color: #01684b;
  background: rgba(55, 125, 255, 0.1);
}
.icon-soft-secondary {
  color: #71869d;
  background: rgba(113, 134, 157, 0.1);
}
.icon-soft-success {
  color: #00c9a7;
  background: rgba(0, 201, 167, 0.1);
}
.icon-soft-info {
  color: #00c9db;
  background: rgba(0, 201, 219, 0.1);
}
.icon-soft-warning {
  color: #f5ca99;
  background: rgba(245, 202, 153, 0.1);
}
.icon-soft-danger {
  color: #ed4c78;
  background: rgba(237, 76, 120, 0.1);
}
.icon-soft-light {
  color: #f9fafc;
  background: rgba(249, 250, 252, 0.1);
}
.icon-soft-dark {
  color: #132144;
  background: rgba(19, 33, 68, 0.1);
}
.nav-link {
  color: #677788;
}
.nav-link:hover {
  color: #01684b;
}
.nav-link.active {
  color: #01684b;
}
.nav-title {
  display: block;
  color: #1e2022;
  font-size: 1.14844rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}
.nav-subtitle {
  display: block;
  color: #99a7ba;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.03125rem;
}
.nav-tabs .nav-link {
  border-bottom-width: 0.0625rem;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  padding: 1rem;
  margin-bottom: 0;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom-width: 0.2rem;
  margin-bottom: -0.1rem;
}
.nav-tabs-light .nav-item.show .nav-link,
.nav-tabs-light .nav-link.active {
  color: #fff;
}
.nav-tabs-light .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.nav-tabs-light .nav-link:hover {
  color: #fff;
}
.nav-pills .nav-item:not(:first-child) {
  margin-left: 0.25rem;
}
.nav-pills .nav-item:not(:last-child) {
  margin-right: 0.25rem;
}
.nav-pills:not(.nav-segment) .nav-link:hover {
  color: #677788;
  background-color: rgba(189, 197, 209, 0.3);
}
.nav-pills:not(.nav-segment) .nav-link.active {
  color: #fff;
  background-color: #01684b;
}
.nav-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 0.0625rem solid #e7eaf3;
}
.nav-link-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.nav-link-toggle::after,

.nav-pills .show > .nav-link-toggle:not(:hover)::after,

.nav-item.active .nav-link {
  color: #01684b;
}
svg {
  font-size: 1.125rem;
  line-height: 1.4;
  opacity: 0.7;
  -ms-flex: 0 0 1.75rem;
  flex: 0 0 1.75rem;
}
.nav-compact {
  text-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.nav-compact > .nav-item {
  margin: 0.25rem 0;
}
.nav-compact > .nav-item:not(:last-child) {
  margin-right: 0;
}
.nav-compact > .nav-item > .nav-link {
  width: 7rem;
  border-radius: 0.3125rem;
}
.nav-compact > .nav-item > .nav-link.active,
.nav-compact > .nav-item > .nav-link:hover {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}
.nav-compact > .nav-item > .nav-link svg {
  font-size: 1.25rem;
}
.nav-compact > .show > .nav-link {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}
.nav-compact-title {
  display: block;
}
.nav-compact-icon {
  text-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.nav-compact-icon > .nav-item {
  margin: 0.25rem 0;
}
.nav-compact-icon > .nav-item:not(:last-child) {
  margin-right: 0;
}
.nav-compact-icon svg {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 2.625rem;
  height: 2.625rem;
  font-size: 0.92969rem;
  color: #677788;
  background-color: transparent;
  opacity: 1;
  margin-right: 0;
  border-radius: 0.3125rem;
  text-align: center;
}
.nav-compact-icon svg.active,
.nav-compact-icon svg:hover {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}
.nav-compact-icon .nav-link.active svg {
  color: #0052ea;
  background-color: rgba(55, 125, 255, 0.1);
}
.nav-compact-icon-circle svg {
  border-radius: 50%;
}
.nav-indicator-icon {
  color: #bdc5d1;
  font-size: 6px;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
}
.nav-segment {
  position: relative;
  background-color: #f8fafd;
  padding: 0.25rem;
  border-radius: 0.3125rem;
}
.nav-segment:not(.nav-fill) {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.nav-segment .nav-link {
  color: #677788;
  font-size: 0.8125rem;
  padding: 0.4375rem 0.65625rem;
  border-radius: 0.3125rem;
}
.nav-segment .nav-link:hover {
  color: #01684b;
}
.nav-segment .nav-link.active {
  color: #1e2022;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}
.nav-segment.nav-pills {
  border-radius: 6.1875rem;
}
.nav-segment.nav-pills .nav-link {
  border-radius: 6.1875rem;
}
@media (max-width: 575.98px) {
  .nav-sm-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .nav-md-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 991.98px) {
  .nav-lg-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 1199.98px) {
  .nav-xl-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 1399.98px) {
  .nav-xxl-down-break {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.nav-down-break {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
}
.legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  margin-right: 0.4375rem;
}
.popover {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
}
.popover-header {
  font-size: 1rem;
  font-weight: 600;
}
.popover-dark {
  background-color: #132144;
}
.popover-dark.bs-popover-auto[x-placement^="top"] > .arrow::after,
.popover-dark.bs-popover-top > .arrow::after {
  border-top-color: #132144;
}
.popover-dark.bs-popover-auto[x-placement^="right"] > .arrow::after,
.popover-dark.bs-popover-right > .arrow::after {
  border-right-color: #132144;
}
.popover-dark.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.popover-dark.bs-popover-bottom > .arrow::after {
  border-bottom-color: #132144;
}
.popover-dark.bs-popover-auto[x-placement^="left"] > .arrow::after,
.popover-dark.bs-popover-left > .arrow::after {
  border-left-color: #132144;
}
.popover-dark .popover-header {
  color: #fff;
  background-color: #132144;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
.popover-dark .popover-body {
  color: #bdc5d1;
}
.page-link {
  cursor: pointer;
  text-align: center;
  min-width: 2.25rem;
}
.page-item:not(.active) .page-link:hover {
  color: #01684b;
}
.page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.page-item .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.page-item .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.pagination-sm .page-link {
  border-radius: 0.25rem;
}
.pagination-lg .page-link {
  border-radius: 0.75rem;
}
.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #f8fafd;
  width: 0.5rem;
  height: 12.5rem;
}
.profile-cover {
  position: relative;
  height: 7.5rem;
  padding: 1.75rem 2rem;
  border-radius: 0.75rem;
}
.profile-cover-content {
  position: relative;
  z-index: 1;
}
.profile-cover-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 7.5rem;
  background-color: #e7eaf3;
  border-radius: 0.75rem;
}
.profile-cover-img {
  width: 100%;
  height: 7.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: top;
  border-radius: 0.75rem;
}
.profile-cover-avatar {
  display: -ms-flexbox;
  display: flex;
  margin: -6.3rem auto 0.5rem;
}
.profile-cover-content {
  padding: 1rem 2rem;
}
.profile-cover-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (min-width: 992px) {
  .profile-cover {
    height: 10rem;
  }
  .profile-cover-img-wrapper {
    height: 10rem;
  }
  .profile-cover-img {
    height: 10rem;
  }
}
.modal {
  padding-right: 0 !important;
}
.modal-header {
  -ms-flex-align: center;
  align-items: center;
  border-width: 0;
  padding-bottom: 0;
}
.modal-header .close {
  padding: 0.25rem;
  margin: 0 0 0 auto;
}
.modal-footer > * {
  margin-top: 0;
  margin-bottom: 0;
}
.modal-footer-text:last-child {
  font-size: 0.8125rem;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .modal-lg .modal-header,
  .modal-xl .modal-header {
    padding-top: 2.25rem;
  }
  .modal-lg .modal-footer,
  .modal-lg .modal-header,
  .modal-xl .modal-footer,
  .modal-xl .modal-header {
    padding-right: 2.25rem;
    padding-left: 2.25rem;
  }
  .modal-lg .modal-body,
  .modal-xl .modal-body {
    padding: 2.25rem;
  }
  .modal-lg .modal-footer,
  .modal-xl .modal-footer {
    padding-bottom: 2.25rem;
  }
}
.modal-top-cover {
  position: relative;
  overflow: hidden;
  min-height: 8rem;
  border-top-right-radius: 0.6875rem;
  border-top-left-radius: 0.6875rem;
}
.modal-top-cover-avatar,
.modal-top-cover-icon {
  position: relative;
  z-index: 2;
  margin-top: -3rem;
}
.modal-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
}
.step {
  position: relative;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}
.step.step-dashed .step-icon::after {
  border-left-style: dashed;
}
.step-title {
  display: block;
  color: #1e2022;
  font-weight: 600;
}
.step-text:last-child {
  color: #677788;
  margin-bottom: 0;
}
.step-border-last-0 .step-item:last-child .step-icon::after {
  display: none;
}
.step .step-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1.5rem;
}
.step-item-between .step-item:last-child {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}
.step .step-content-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.step .step-content {
  -ms-flex: 1;
  flex: 1;
}
.step-item.collapse:not(.show) {
  display: none;
}
.step-item .step-title-description {
  display: none;
}
.step-item.focus .step-title-description {
  display: block;
}
.step .step-avatar {
  font-size: 1.09375rem;
  font-weight: 600;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.step .step-avatar-img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}
.step .step-avatar::after {
  position: absolute;
  top: 3.09375rem;
  left: 1.3125rem;
  height: calc(100% - 2.15625rem);
  border-left: 0.125rem solid #e7eaf3;
  content: "";
}
.step-avatar-xs .step-avatar,
.step-avatar-xs.step-avatar {
  font-size: 0.875rem;
  width: 1.53125rem;
  height: 1.53125rem;
}
.step-avatar-xs .step-avatar::after,
.step-avatar-xs.step-avatar::after {
  top: 2rem;
  left: 0.70312rem;
  width: 1.0625rem;
  height: calc(100% - 1.0625rem);
}
.step-avatar-xs .step-divider::after {
  left: 0.76562rem;
}
.step-avatar-sm .step-avatar,
.step-avatar-sm.step-avatar {
  font-size: 0.98438rem;
  width: 2.1875rem;
  height: 2.1875rem;
}
.step-avatar-sm .step-avatar::after,
.step-avatar-sm.step-avatar::after {
  top: 2.65625rem;
  left: 1.03125rem;
  width: 1.0625rem;
  height: calc(100% - 1.71875rem);
}
.step-avatar-sm .step-divider::after {
  left: 1.09375rem;
}
.step-avatar-lg .step-avatar,
.step-avatar-lg.step-avatar {
  font-size: 1.53125rem;
  width: 3.36875rem;
  height: 3.36875rem;
}
.step-avatar-lg .step-avatar::after,
.step-avatar-lg.step-avatar::after {
  top: 3.8375rem;
  left: 1.62187rem;
  width: 1.0625rem;
  height: calc(100% - 2.9rem);
}
.step-avatar-lg .step-divider::after {
  left: 1.68437rem;
}
.step-divider {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  height: 1rem;
  font-weight: 600;
}
.step-divider::after {
  position: absolute;
  top: 1.46875rem;
  left: 1.3125rem;
  height: calc(100% - 0.53125rem);
  border-left: 0.125rem solid #e7eaf3;
  content: "";
}
.step .step-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.09375rem;
  font-weight: 600;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  margin-right: 1rem;
}
.step .step-icon::after {
  position: absolute;
  top: 3.09375rem;
  left: 1.3125rem;
  height: calc(100% - 2.15625rem);
  border-left: 0.125rem solid #e7eaf3;
  content: "";
}
.step .step-icon-pseudo::before {
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #97a4af;
  border-radius: 50%;
  content: "";
}
.step-icon-xs .step-icon,
.step-icon-xs.step-icon {
  font-size: 0.875rem;
  width: 1.53125rem;
  height: 1.53125rem;
}
.step-icon-xs .step-icon::after,
.step-icon-xs.step-icon::after {
  top: 2rem;
  left: 0.70312rem;
  width: 1.0625rem;
  height: calc(100% - 1.0625rem);
}
.step-icon-xs .step-divider::after {
  left: 0.76562rem;
}
.step-icon-sm .step-icon,
.step-icon-sm.step-icon {
  font-size: 0.98438rem;
  width: 2.1875rem;
  height: 2.1875rem;
}
.step-icon-sm .step-icon::after,
.step-icon-sm.step-icon::after {
  top: 2.65625rem;
  left: 1.03125rem;
  width: 1.0625rem;
  height: calc(100% - 1.71875rem);
}
.step-icon-sm .step-divider::after {
  left: 1.09375rem;
}
.step-icon-lg .step-icon,
.step-icon-lg.step-icon {
  font-size: 1.53125rem;
  width: 3.36875rem;
  height: 3.36875rem;
}
.step-icon-lg .step-icon::after,
.step-icon-lg.step-icon::after {
  top: 3.8375rem;
  left: 1.62187rem;
  width: 1.0625rem;
  height: calc(100% - 2.9rem);
}
.step-icon-lg .step-divider::after {
  left: 1.68437rem;
}
@media (min-width: 576px) {
  .step-sm.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-sm .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-sm:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-sm .step-icon {
    margin-bottom: 1rem;
  }
  .step-sm .step-icon::after {
    top: 1.3125rem;
    left: 3.5625rem;
    width: calc(100% - 3.5625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-sm .step-icon.step-icon-xs::after,
  .step-sm.step-icon-xs .step-icon::after {
    top: 0.76562rem;
    left: 2.46875rem;
    width: calc(100% - 2.46875rem);
  }
  .step-sm .step-icon.step-icon-sm::after,
  .step-sm.step-icon-sm .step-icon::after {
    top: 1.09375rem;
    left: 3.125rem;
    width: calc(100% - 3.125rem);
  }
  .step-sm .step-icon.step-icon-lg::after,
  .step-sm.step-icon-lg .step-icon::after {
    top: 1.68437rem;
    left: 4.30625rem;
    width: calc(100% - 4.30625rem);
  }
}
@media (min-width: 768px) {
  .step-md.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-md .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-md:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-md .step-icon {
    margin-bottom: 1rem;
  }
  .step-md .step-icon::after {
    top: 1.3125rem;
    left: 3.5625rem;
    width: calc(100% - 3.5625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-md .step-icon.step-icon-xs::after,
  .step-md.step-icon-xs .step-icon::after {
    top: 0.76562rem;
    left: 2.46875rem;
    width: calc(100% - 2.46875rem);
  }
  .step-md .step-icon.step-icon-sm::after,
  .step-md.step-icon-sm .step-icon::after {
    top: 1.09375rem;
    left: 3.125rem;
    width: calc(100% - 3.125rem);
  }
  .step-md .step-icon.step-icon-lg::after,
  .step-md.step-icon-lg .step-icon::after {
    top: 1.68437rem;
    left: 4.30625rem;
    width: calc(100% - 4.30625rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-lg .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-lg:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-lg .step-icon {
    margin-bottom: 1rem;
  }
  .step-lg .step-icon::after {
    top: 1.3125rem;
    left: 3.5625rem;
    width: calc(100% - 3.5625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-lg .step-icon.step-icon-xs::after,
  .step-lg.step-icon-xs .step-icon::after {
    top: 0.76562rem;
    left: 2.46875rem;
    width: calc(100% - 2.46875rem);
  }
  .step-lg .step-icon.step-icon-sm::after,
  .step-lg.step-icon-sm .step-icon::after {
    top: 1.09375rem;
    left: 3.125rem;
    width: calc(100% - 3.125rem);
  }
  .step-lg .step-icon.step-icon-lg::after,
  .step-lg.step-icon-lg .step-icon::after {
    top: 1.68437rem;
    left: 4.30625rem;
    width: calc(100% - 4.30625rem);
  }
}
@media (min-width: 1200px) {
  .step-xl.step-dashed .step-icon::after {
    border-left: none;
    border-top-style: dashed;
  }
  .step-xl .step-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 1;
    flex: 1;
    margin-bottom: 0;
  }
  .step-xl:not(.step-inline) .step-content-wrapper {
    display: block;
  }
  .step-xl .step-icon {
    margin-bottom: 1rem;
  }
  .step-xl .step-icon::after {
    top: 1.3125rem;
    left: 3.5625rem;
    width: calc(100% - 3.5625rem);
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    border-left: none;
  }
  .step-xl .step-icon.step-icon-xs::after,
  .step-xl.step-icon-xs .step-icon::after {
    top: 0.76562rem;
    left: 2.46875rem;
    width: calc(100% - 2.46875rem);
  }
  .step-xl .step-icon.step-icon-sm::after,
  .step-xl.step-icon-sm .step-icon::after {
    top: 1.09375rem;
    left: 3.125rem;
    width: calc(100% - 3.125rem);
  }
  .step-xl .step-icon.step-icon-lg::after,
  .step-xl.step-icon-lg .step-icon::after {
    top: 1.68437rem;
    left: 4.30625rem;
    width: calc(100% - 4.30625rem);
  }
}
@media (min-width: 576px) {
  .step-sm.step-centered {
    text-align: center;
  }
  .step-sm.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-sm.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-sm.step-centered .step-icon::after {
    width: calc(100% - 2.625rem);
    left: calc(50% + 2.25rem);
  }
  .step-sm.step-centered .step-icon.step-icon-xs::after,
  .step-sm.step-centered.step-icon-xs .step-icon::after {
    width: calc(100% - 1.53125rem);
    left: calc(50% + 1.70312rem);
  }
  .step-sm.step-centered .step-icon.step-icon-sm::after,
  .step-sm.step-centered.step-icon-sm .step-icon::after {
    width: calc(100% - 2.1875rem);
    left: calc(50% + 2.03125rem);
  }
  .step-sm.step-centered .step-icon.step-icon-lg::after,
  .step-sm.step-centered.step-icon-lg .step-icon::after {
    width: calc(100% - 3.36875rem);
    left: calc(50% + 2.62188rem);
  }
}
@media (min-width: 768px) {
  .step-md.step-centered {
    text-align: center;
  }
  .step-md.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-md.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-md.step-centered .step-icon::after {
    width: calc(100% - 2.625rem);
    left: calc(50% + 2.25rem);
  }
  .step-md.step-centered .step-icon.step-icon-xs::after,
  .step-md.step-centered.step-icon-xs .step-icon::after {
    width: calc(100% - 1.53125rem);
    left: calc(50% + 1.70312rem);
  }
  .step-md.step-centered .step-icon.step-icon-sm::after,
  .step-md.step-centered.step-icon-sm .step-icon::after {
    width: calc(100% - 2.1875rem);
    left: calc(50% + 2.03125rem);
  }
  .step-md.step-centered .step-icon.step-icon-lg::after,
  .step-md.step-centered.step-icon-lg .step-icon::after {
    width: calc(100% - 3.36875rem);
    left: calc(50% + 2.62188rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 2.625rem);
    left: calc(50% + 2.25rem);
  }
  .step-lg.step-centered .step-icon.step-icon-xs::after,
  .step-lg.step-centered.step-icon-xs .step-icon::after {
    width: calc(100% - 1.53125rem);
    left: calc(50% + 1.70312rem);
  }
  .step-lg.step-centered .step-icon.step-icon-sm::after,
  .step-lg.step-centered.step-icon-sm .step-icon::after {
    width: calc(100% - 2.1875rem);
    left: calc(50% + 2.03125rem);
  }
  .step-lg.step-centered .step-icon.step-icon-lg::after,
  .step-lg.step-centered.step-icon-lg .step-icon::after {
    width: calc(100% - 3.36875rem);
    left: calc(50% + 2.62188rem);
  }
}
@media (min-width: 992px) {
  .step-lg.step-centered {
    text-align: center;
  }
  .step-lg.step-centered .step-item:last-child .step-icon::after {
    display: none;
  }
  .step-lg.step-centered .step-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .step-lg.step-centered .step-icon::after {
    width: calc(100% - 2.625rem);
    left: calc(50% + 2.25rem);
  }
  .step-lg.step-centered .step-icon.step-icon-xs::after,
  .step-lg.step-centered.step-icon-xs .step-icon::after {
    width: calc(100% - 1.53125rem);
    left: calc(50% + 1.70312rem);
  }
  .step-lg.step-centered .step-icon.step-icon-sm::after,
  .step-lg.step-centered.step-icon-sm .step-icon::after {
    width: calc(100% - 2.1875rem);
    left: calc(50% + 2.03125rem);
  }
  .step-lg.step-centered .step-icon.step-icon-lg::after,
  .step-lg.step-centered.step-icon-lg .step-icon::after {
    width: calc(100% - 3.36875rem);
    left: calc(50% + 2.62188rem);
  }
}
.step .step-is-invalid-icon,
.step .step-is-valid-icon {
  display: none;
}
.step .active .step-icon,
.step .active.is-valid .step-icon {
  color: #fff;
  background-color: #01684b;
}
.step .active .step-title,
.step .active.is-valid .step-title {
  color: #01684b;
}
.step .is-valid .step-icon {
  color: #fff;
  background-color: #01684b;
}
.step .is-valid .step-title {
  color: #01684b;
}
.step .is-valid .step-is-valid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.step .is-valid .step-is-default-icon,
.step .is-valid .step-is-invalid-icon {
  display: none;
}
.step .is-invalid .step-icon {
  color: #fff;
  background-color: #ed4c78;
}
.step .is-invalid .step-title {
  color: #ed4c78;
}
.step .is-invalid .step-is-invalid-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.step .is-invalid .step-is-default-icon,
.step .is-invalid .step-is-valid-icon {
  display: none;
}
.step-icon-primary {
  color: #fff;
  background-color: #01684b;
}
.step-icon-primary.step-icon-pseudo::before {
  background-color: #fff;
}
.step-icon-secondary {
  color: #fff;
  background-color: #71869d;
}
.step-icon-secondary.step-icon-pseudo::before {
  background-color: #fff;
}
.step-icon-success {
  color: #fff;
  background-color: #00c9a7;
}
.step-icon-success.step-icon-pseudo::before {
  background-color: #fff;
}
.step-icon-info {
  color: #fff;
  background-color: #00c9db;
}
.step-icon-info.step-icon-pseudo::before {
  background-color: #fff;
}
.step-icon-warning {
  color: #1e2022;
  background-color: #f5ca99;
}
.step-icon-warning.step-icon-pseudo::before {
  background-color: #1e2022;
}
.step-icon-danger {
  color: #fff;
  background-color: #ed4c78;
}
.step-icon-danger.step-icon-pseudo::before {
  background-color: #fff;
}
.step-icon-light {
  color: #1e2022;
  background-color: #f9fafc;
}
.step-icon-light.step-icon-pseudo::before {
  background-color: #1e2022;
}
.step-icon-dark {
  color: #fff;
  background-color: #132144;
}
.step-icon-dark.step-icon-pseudo::before {
  background-color: #fff;
}
.step-icon-soft-primary {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
}
.step-icon-soft-primary.step-icon-pseudo::before {
  background-color: #01684b;
}
.step-icon-soft-secondary {
  color: #71869d;
  background-color: rgba(113, 134, 157, 0.1);
}
.step-icon-soft-secondary.step-icon-pseudo::before {
  background-color: #71869d;
}
.step-icon-soft-success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}
.step-icon-soft-success.step-icon-pseudo::before {
  background-color: #00c9a7;
}
.step-icon-soft-info {
  color: #00c9db;
  background-color: rgba(0, 201, 219, 0.1);
}
.step-icon-soft-info.step-icon-pseudo::before {
  background-color: #00c9db;
}
.step-icon-soft-warning {
  color: #f5ca99;
  background-color: rgba(245, 202, 153, 0.1);
}
.step-icon-soft-warning.step-icon-pseudo::before {
  background-color: #f5ca99;
}
.step-icon-soft-danger {
  color: #ed4c78;
  background-color: rgba(237, 76, 120, 0.1);
}
.step-icon-soft-danger.step-icon-pseudo::before {
  background-color: #ed4c78;
}
.step-icon-soft-light {
  color: #f9fafc;
  background-color: rgba(249, 250, 252, 0.1);
}
.step-icon-soft-light.step-icon-pseudo::before {
  background-color: #f9fafc;
}
.step-icon-soft-dark {
  color: #132144;
  background-color: rgba(19, 33, 68, 0.1);
}
.step-icon-soft-dark.step-icon-pseudo::before {
  background-color: #132144;
}
.step-inline .step-content-wrapper {
  -ms-flex-align: center;
  align-items: center;
}
.step-inline .step-item:last-child .step-title::after {
  display: none;
}
.step-inline .step-title {
  display: inline-block;
}
@media (min-width: 576px) {
  .step-sm.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-sm.step-inline .step-item {
    overflow: hidden;
  }
  .step-sm.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-sm.step-inline .step-icon::after {
    display: none;
  }
  .step-sm.step-inline .step-title::after {
    position: absolute;
    top: 1.3125rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: "";
  }
  .step-sm.step-inline .step-icon-xs + .step-content .step-title::after,
  .step-sm.step-inline.step-icon-xs .step-content .step-title::after {
    top: 0.76562rem;
  }
  .step-sm.step-inline .step-icon-sm + .step-content .step-title::after,
  .step-sm.step-inline.step-icon-sm .step-content .step-title::after {
    top: 1.09375rem;
  }
  .step-sm.step-inline .step-icon-lg + .step-content .step-title::after,
  .step-sm.step-inline.step-icon-lg .step-content .step-title::after {
    top: 1.68437rem;
  }
}
@media (min-width: 768px) {
  .step-md.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-md.step-inline .step-item {
    overflow: hidden;
  }
  .step-md.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-md.step-inline .step-icon::after {
    display: none;
  }
  .step-md.step-inline .step-title::after {
    position: absolute;
    top: 1.3125rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: "";
  }
  .step-md.step-inline .step-icon-xs + .step-content .step-title::after,
  .step-md.step-inline.step-icon-xs .step-content .step-title::after {
    top: 0.76562rem;
  }
  .step-md.step-inline .step-icon-sm + .step-content .step-title::after,
  .step-md.step-inline.step-icon-sm .step-content .step-title::after {
    top: 1.09375rem;
  }
  .step-md.step-inline .step-icon-lg + .step-content .step-title::after,
  .step-md.step-inline.step-icon-lg .step-content .step-title::after {
    top: 1.68437rem;
  }
}
@media (min-width: 992px) {
  .step-lg.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-lg.step-inline .step-item {
    overflow: hidden;
  }
  .step-lg.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-lg.step-inline .step-icon::after {
    display: none;
  }
  .step-lg.step-inline .step-title::after {
    position: absolute;
    top: 1.3125rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: "";
  }
  .step-lg.step-inline .step-icon-xs + .step-content .step-title::after,
  .step-lg.step-inline.step-icon-xs .step-content .step-title::after {
    top: 0.76562rem;
  }
  .step-lg.step-inline .step-icon-sm + .step-content .step-title::after,
  .step-lg.step-inline.step-icon-sm .step-content .step-title::after {
    top: 1.09375rem;
  }
  .step-lg.step-inline .step-icon-lg + .step-content .step-title::after,
  .step-lg.step-inline.step-icon-lg .step-content .step-title::after {
    top: 1.68437rem;
  }
}
@media (min-width: 1200px) {
  .step-xl.step-inline.step-dashed .step-title::after {
    border-top-style: dashed;
  }
  .step-xl.step-inline .step-item {
    overflow: hidden;
  }
  .step-xl.step-inline .step-icon {
    margin-bottom: 0;
  }
  .step-xl.step-inline .step-icon::after {
    display: none;
  }
  .step-xl.step-inline .step-title::after {
    position: absolute;
    top: 1.3125rem;
    width: 100%;
    height: 1.0625rem;
    border-top: 0.125rem solid #e7eaf3;
    margin-left: 0.9375rem;
    content: "";
  }
  .step-xl.step-inline .step-icon-xs + .step-content .step-title::after,
  .step-xl.step-inline.step-icon-xs .step-content .step-title::after {
    top: 0.76562rem;
  }
  .step-xl.step-inline .step-icon-sm + .step-content .step-title::after,
  .step-xl.step-inline.step-icon-sm .step-content .step-title::after {
    top: 1.09375rem;
  }
  .step-xl.step-inline .step-icon-lg + .step-content .step-title::after,
  .step-xl.step-inline.step-icon-lg .step-content .step-title::after {
    top: 1.68437rem;
  }
}
@media (min-width: 576px) {
  .step-timeline-sm {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-sm .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.3125rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.76562rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.09375rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-icon-lg {
    margin-right: -1.68437rem;
  }
  .step-timeline-sm .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-sm .step-icon {
    margin-left: -1.3125rem;
  }
  .step-timeline-sm .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-sm .step-icon-xs {
    margin-left: -0.76562rem;
  }
  .step-timeline-sm .step-icon-sm {
    margin-left: -1.09375rem;
  }
  .step-timeline-sm .step-icon-lg {
    margin-left: -1.68437rem;
  }
}
@media (min-width: 768px) {
  .step-timeline-md {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-md .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.3125rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.76562rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.09375rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-icon-lg {
    margin-right: -1.68437rem;
  }
  .step-timeline-md .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-md .step-icon {
    margin-left: -1.3125rem;
  }
  .step-timeline-md .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-md .step-icon-xs {
    margin-left: -0.76562rem;
  }
  .step-timeline-md .step-icon-sm {
    margin-left: -1.09375rem;
  }
  .step-timeline-md .step-icon-lg {
    margin-left: -1.68437rem;
  }
}
@media (min-width: 992px) {
  .step-timeline-lg {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-lg .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.3125rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.76562rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.09375rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-icon-lg {
    margin-right: -1.68437rem;
  }
  .step-timeline-lg .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-lg .step-icon {
    margin-left: -1.3125rem;
  }
  .step-timeline-lg .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-lg .step-icon-xs {
    margin-left: -0.76562rem;
  }
  .step-timeline-lg .step-icon-sm {
    margin-left: -1.09375rem;
  }
  .step-timeline-lg .step-icon-lg {
    margin-left: -1.68437rem;
  }
}
@media (min-width: 1200px) {
  .step-timeline-xl {
    margin-left: 0;
    margin-right: 0;
  }
  .step-timeline-xl .step-item {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-right: 0;
    margin-left: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content-wrapper {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon {
    margin-left: 0;
    margin-right: -1.3125rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-xs {
    margin-right: -0.76562rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-sm {
    margin-right: -1.09375rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-icon-lg {
    margin-right: -1.68437rem;
  }
  .step-timeline-xl .step-item:nth-child(even) .step-content {
    margin-right: 1.5rem;
  }
  .step-timeline-xl .step-icon {
    margin-left: -1.3125rem;
  }
  .step-timeline-xl .step-icon::after {
    left: auto;
    width: auto;
  }
  .step-timeline-xl .step-icon-xs {
    margin-left: -0.76562rem;
  }
  .step-timeline-xl .step-icon-sm {
    margin-left: -1.09375rem;
  }
  .step-timeline-xl .step-icon-lg {
    margin-left: -1.68437rem;
  }
}
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .table-nowrap td,
  .table-nowrap th {
    white-space: normal;
  }
  .step-item-between .step-item:last-child {
    -ms-flex: 0 0 17%;
    flex: 0 0 17%;
    width: auto;
  }
  .ie-modal-curved-shape {
    height: 1.75rem;
  }
  .ie-welcome-brands {
    width: 5rem;
  }
  .ie-sidebar-activity-img {
    width: 3.5rem;
  }
  .ie-card-img {
    width: 5.5rem;
  }
}
.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.bg-soft-primary {
  background-color: rgba(55, 125, 255, 0.1);
}
.bg-soft-secondary {
  background-color: rgba(113, 134, 157, 0.1);
}
.bg-soft-success {
  background-color: rgba(0, 201, 167, 0.1);
}
.bg-soft-info {
  background-color: rgba(0, 201, 219, 0.1);
}
.bg-soft-warning {
  background-color: rgba(245, 202, 153, 0.1);
}
.bg-soft-danger {
  background-color: rgba(237, 76, 120, 0.1);
}
.bg-soft-light {
  background-color: rgba(249, 250, 252, 0.1);
}
.bg-soft-dark {
  background-color: rgba(19, 33, 68, 0.1);
}
.border-left-primary {
  border-left-color: #01684b !important;
}
.border-left-secondary {
  border-left-color: #71869d !important;
}
.border-left-success {
  border-left-color: #00c9a7 !important;
}
.border-left-info {
  border-left-color: #00c9db !important;
}
.border-left-warning {
  border-left-color: #f5ca99 !important;
}
.border-left-danger {
  border-left-color: #ed4c78 !important;
}
.border-left-light {
  border-left-color: #f9fafc !important;
}
.border-left-dark {
  border-left-color: #132144 !important;
}
.content-centered-x {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.g-0,
.gx-0 {
  margin-left: -- 0.0625rem;
  margin-right: -- 0.0625rem;
}
.g-0 > *,
.gx-0 > * {
  padding-left: 0;
  padding-right: 0;
}
.g-0,
.gy-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.g-0 > *,
.gy-0 > * {
  padding-top: 0;
  padding-bottom: 0;
}
.g-1,
.gx-1 {
  margin-left: -0.1875rem;
  margin-right: -0.1875rem;
}
.g-1 > *,
.gx-1 > * {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.g-1,
.gy-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.g-1 > *,
.gy-1 > * {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.g-2,
.gx-2 {
  margin-left: -0.4375rem;
  margin-right: -0.4375rem;
}
.g-2 > *,
.gx-2 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.g-2,
.gy-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.g-2 > *,
.gy-2 > * {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.g-3,
.gx-3 {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}
.g-3 > *,
.gx-3 > * {
  padding-left: 1rem;
  padding-right: 1rem;
}
.g-3,
.gy-3 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.g-3 > *,
.gy-3 > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.g-4,
.gx-4 {
  margin-left: -1.4375rem;
  margin-right: -1.4375rem;
}
.g-4 > *,
.gx-4 > * {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.g-4,
.gy-4 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}
.g-4 > *,
.gy-4 > * {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.g-5,
.gx-5 {
  margin-left: -2.9375rem;
  margin-right: -2.9375rem;
}
.g-5 > *,
.gx-5 > * {
  padding-left: 3rem;
  padding-right: 3rem;
}
.g-5,
.gy-5 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}
.g-5 > *,
.gy-5 > * {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (min-width: 576px) {
  .g-sm-0,
  .gx-sm-0 {
    margin-left: -- 0.0625rem;
    margin-right: -- 0.0625rem;
  }
  .g-sm-0 > *,
  .gx-sm-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .g-sm-0 > *,
  .gy-sm-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-sm-1 > *,
  .gx-sm-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-sm-1 > *,
  .gy-sm-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-sm-2 > *,
  .gx-sm-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-sm-2 > *,
  .gy-sm-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-sm-3 > *,
  .gx-sm-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-sm-3 > *,
  .gy-sm-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-sm-4 > *,
  .gx-sm-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-sm-4 > *,
  .gy-sm-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-sm-5 > *,
  .gx-sm-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-sm-5 > *,
  .gy-sm-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (min-width: 768px) {
  .g-md-0,
  .gx-md-0 {
    margin-left: -- 0.0625rem;
    margin-right: -- 0.0625rem;
  }
  .g-md-0 > *,
  .gx-md-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-md-0,
  .gy-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .g-md-0 > *,
  .gy-md-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-md-1,
  .gx-md-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-md-1 > *,
  .gx-md-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-md-1 > *,
  .gy-md-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-md-2 > *,
  .gx-md-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-md-2 > *,
  .gy-md-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-md-3 > *,
  .gx-md-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-md-3 > *,
  .gy-md-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-md-4 > *,
  .gx-md-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-md-4 > *,
  .gy-md-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-md-5 > *,
  .gx-md-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-md-5 > *,
  .gy-md-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .g-lg-0,
  .gx-lg-0 {
    margin-left: -- 0.0625rem;
    margin-right: -- 0.0625rem;
  }
  .g-lg-0 > *,
  .gx-lg-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .g-lg-0 > *,
  .gy-lg-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-lg-1 > *,
  .gx-lg-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-lg-1 > *,
  .gy-lg-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-lg-2 > *,
  .gx-lg-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-lg-2 > *,
  .gy-lg-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-lg-3 > *,
  .gx-lg-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-lg-3 > *,
  .gy-lg-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-lg-4 > *,
  .gx-lg-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-lg-4 > *,
  .gy-lg-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-lg-5 > *,
  .gx-lg-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-lg-5 > *,
  .gy-lg-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (min-width: 1200px) {
  .g-xl-0,
  .gx-xl-0 {
    margin-left: -- 0.0625rem;
    margin-right: -- 0.0625rem;
  }
  .g-xl-0 > *,
  .gx-xl-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .g-xl-0 > *,
  .gy-xl-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-xl-1 > *,
  .gx-xl-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-xl-1 > *,
  .gy-xl-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-xl-2 > *,
  .gx-xl-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-xl-2 > *,
  .gy-xl-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-xl-3 > *,
  .gx-xl-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-xl-3 > *,
  .gy-xl-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-xl-4 > *,
  .gx-xl-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-xl-4 > *,
  .gy-xl-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-xl-5 > *,
  .gx-xl-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-xl-5 > *,
  .gy-xl-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (min-width: 1400px) {
  .g-xxl-0,
  .gx-xxl-0 {
    margin-left: -- 0.0625rem;
    margin-right: -- 0.0625rem;
  }
  .g-xxl-0 > *,
  .gx-xxl-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .g-xxl-0 > *,
  .gy-xxl-0 > * {
    padding-top: 0;
    padding-bottom: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    margin-left: -0.1875rem;
    margin-right: -0.1875rem;
  }
  .g-xxl-1 > *,
  .gx-xxl-1 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .g-xxl-1 > *,
  .gy-xxl-1 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    margin-left: -0.4375rem;
    margin-right: -0.4375rem;
  }
  .g-xxl-2 > *,
  .gx-xxl-2 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .g-xxl-2 > *,
  .gy-xxl-2 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .g-xxl-3 > *,
  .gx-xxl-3 > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .g-xxl-3 > *,
  .gy-xxl-3 > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    margin-left: -1.4375rem;
    margin-right: -1.4375rem;
  }
  .g-xxl-4 > *,
  .gx-xxl-4 > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .g-xxl-4 > *,
  .gy-xxl-4 > * {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    margin-left: -2.9375rem;
    margin-right: -2.9375rem;
  }
  .g-xxl-5 > *,
  .gx-xxl-5 > * {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .g-xxl-5 > *,
  .gy-xxl-5 > * {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.opacity-xs {
  opacity: 0.2;
}
.opacity-sm {
  opacity: 0.4;
}
.opacity {
  opacity: 0.6;
}
.opacity-lg {
  opacity: 0.8;
}
.top-0 {
  top: 0 !important;
}
.right-0 {
  right: 0 !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.left-0 {
  left: 0 !important;
}
.min-h-100 {
  min-height: 100%;
}
@media (min-width: 992px) {
  .vh-lg-100 {
    height: 100vh;
  }
  .min-vh-lg-100 {
    min-height: 100vh;
  }
}
.shadow-soft {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25) !important;
}
.text-cap {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.03125rem;
}
.font-size-sm {
  font-size: 0.8125rem;
}
.text-dark {
  color: #1e2022 !important;
}
.text-dark[href]:hover {
  color: #01684b !important;
}
.text-secondary[href]:hover {
  color: #01684b !important;
}
.text-body[href]:hover,
.text-muted[href]:hover {
  color: #0052ea !important;
}
.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}
.text-white-70[href]:hover {
  color: #fff;
}
a:hover .text-hover-primary {
  color: #01684b !important;
}
.text-warning {
  color: #ec9a3c !important;
}
.z-index-2 {
  z-index: 2;
}
.z-index-99 {
  z-index: 99;
}
.chartjs-custom {
  position: relative;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
}
.hs-chartjs-tooltip-wrap {
  position: absolute;
  z-index: 3;
  transition: opacity 0.2s ease-in-out, left 0.2s ease, top 0.2s ease;
}
.hs-chartjs-tooltip {
  position: relative;
  font-size: 0.75rem;
  background-color: #132144;
  border-radius: 0.3125rem;
  padding: 0.54688rem 0.875rem;
  transition: opacity 0.2s ease-in-out, left 0.2s ease, top 0.2s ease, top 0s;
}
.hs-chartjs-tooltip::before {
  position: absolute;
  left: calc(50% - 0.5rem);
  bottom: -0.4375rem;
  width: 1rem;
  height: 0.5rem;
  content: "";
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMXJlbScgaGVpZ2h0PScwLjVyZW0nIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycgeD0nMHB4JyB5PScwcHgnIHZpZXdCb3g9JzAgMCA1MCAyMi40OSc+PHBhdGggZmlsbD0nIzEzMjE0NCcgZD0nTTAsMGg1MEwzMS44NywxOS42NWMtMy40NSwzLjczLTkuMzMsMy43OS0xMi44NSwwLjEzTDAsMHonLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem 0.5rem;
}
.hs-chartjs-tooltip-left {
  left: -130%;
}
.hs-chartjs-tooltip-left::before {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -0.6875rem;
  left: auto;
  -webkit-transform: translateY(-50%) rotate(270deg);
  transform: translateY(-50%) rotate(270deg);
}
.hs-chartjs-tooltip-right {
  left: 30%;
}
.hs-chartjs-tooltip-right::before {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -0.6875rem;
  right: auto;
  -webkit-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}
.hs-chartjs-tooltip-header {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  white-space: nowrap;
}
.hs-chartjs-tooltip-body {
  color: #fff;
}
.chartjs-doughnut-custom {
  position: relative;
}
.chartjs-doughnut-custom-stat {
  position: absolute;
  top: 8rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.chartjs-matrix-custom {
  position: relative;
}
.hs-chartjs-matrix-legend {
  display: inline-block;
  position: relative;
  height: 2.5rem;
  list-style: none;
  padding-left: 0;
}
.hs-chartjs-matrix-legend-item {
  width: 0.625rem;
  height: 0.625rem;
  display: inline-block;
}
.hs-chartjs-matrix-legend-min {
  position: absolute;
  left: 0;
  bottom: 0;
}
.hs-chartjs-matrix-legend-max {
  position: absolute;
  right: 0;
  bottom: 0;
}
.circle-custom-text {
  z-index: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.datatable-custom .dataTables_filter,
.datatable-custom .dataTables_info,
.datatable-custom .dataTables_length,
.datatable-custom .dataTables_paginate {
  display: none;
}
.datatable-custom .sorting,
.datatable-custom .sorting_asc,
.datatable-custom .sorting_desc {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.datatable-custom .sorting:after,
.datatable-custom .sorting_asc:after,
.datatable-custom .sorting_desc:after {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  width: 0.7rem;
  height: 0.7rem;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMC43cmVtJyBoZWlnaHQ9JzAuN3JlbScgdmlld0JveD0nMCAwIDI5MiAzNzUnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTEzMS45NjUgMTUuMzgwOEMxMzkuNSA3LjEyMzQ1IDE1Mi41IDcuMTIzNDYgMTYwLjAzNSAxNS4zODA4TDI2Mi45NzYgMTI4LjE5M0MyNzQuMTA2IDE0MC4zOSAyNjUuNDUzIDE2MCAyNDguOTQxIDE2MEg0My4wNTg5QzI2LjU0NzQgMTYwIDE3Ljg5NDMgMTQwLjM5IDI5LjAyMzggMTI4LjE5M0wxMzEuOTY1IDE1LjM4MDhaJyBmaWxsPScjZGRlMWVlJy8+PHBhdGggZD0nTTE2MC4wMzUgMzU5LjYxOUMxNTIuNSAzNjcuODc3IDEzOS41IDM2Ny44NzcgMTMxLjk2NSAzNTkuNjE5TDI5LjAyMzggMjQ2LjgwN0MxNy44OTQyIDIzNC42MSAyNi41NDczIDIxNSA0My4wNTg5IDIxNUwyNDguOTQxIDIxNUMyNjUuNDUzIDIxNSAyNzQuMTA2IDIzNC42MSAyNjIuOTc2IDI0Ni44MDdMMTYwLjAzNSAzNTkuNjE5WicgZmlsbD0nI2RkZTFlZScvPjwvc3ZnPgo=);
  content: "";
  margin-left: 0.5rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.datatable-custom .sorting_asc:after {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMC43cmVtJyBoZWlnaHQ9JzAuN3JlbScgdmlld0JveD0nMCAwIDI5MiAzNzUnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTEzMS45NjUgMTUuMzgwOEMxMzkuNSA3LjEyMzQ1IDE1Mi41IDcuMTIzNDYgMTYwLjAzNSAxNS4zODA4TDI2Mi45NzYgMTI4LjE5M0MyNzQuMTA2IDE0MC4zOSAyNjUuNDUzIDE2MCAyNDguOTQxIDE2MEg0My4wNTg5QzI2LjU0NzQgMTYwIDE3Ljg5NDMgMTQwLjM5IDI5LjAyMzggMTI4LjE5M0wxMzEuOTY1IDE1LjM4MDhaJyBmaWxsPScjZGRlMWVlJy8+PHBhdGggZD0nTTE2MC4wMzUgMzU5LjYxOUMxNTIuNSAzNjcuODc3IDEzOS41IDM2Ny44NzcgMTMxLjk2NSAzNTkuNjE5TDI5LjAyMzggMjQ2LjgwN0MxNy44OTQyIDIzNC42MSAyNi41NDczIDIxNSA0My4wNTg5IDIxNUwyNDguOTQxIDIxNUMyNjUuNDUzIDIxNSAyNzQuMTA2IDIzNC42MSAyNjIuOTc2IDI0Ni44MDdMMTYwLjAzNSAzNTkuNjE5WicgZmlsbD0nIzM3N2RmZicvPjwvc3ZnPgo=);
  content: "";
}
.datatable-custom .sorting_desc:after {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMC43cmVtJyBoZWlnaHQ9JzAuN3JlbScgdmlld0JveD0nMCAwIDI5MiAzNzUnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTEzMS45NjUgMTUuMzgwOEMxMzkuNSA3LjEyMzQ1IDE1Mi41IDcuMTIzNDYgMTYwLjAzNSAxNS4zODA4TDI2Mi45NzYgMTI4LjE5M0MyNzQuMTA2IDE0MC4zOSAyNjUuNDUzIDE2MCAyNDguOTQxIDE2MEg0My4wNTg5QzI2LjU0NzQgMTYwIDE3Ljg5NDMgMTQwLjM5IDI5LjAyMzggMTI4LjE5M0wxMzEuOTY1IDE1LjM4MDhaJyBmaWxsPScjMzc3ZGZmJy8+PHBhdGggZD0nTTE2MC4wMzUgMzU5LjYxOUMxNTIuNSAzNjcuODc3IDEzOS41IDM2Ny44NzcgMTMxLjk2NSAzNTkuNjE5TDI5LjAyMzggMjQ2LjgwN0MxNy44OTQyIDIzNC42MSAyNi41NDczIDIxNSA0My4wNTg5IDIxNUwyNDguOTQxIDIxNUMyNjUuNDUzIDIxNSAyNzQuMTA2IDIzNC42MSAyNjIuOTc2IDI0Ni44MDdMMTYwLjAzNSAzNTkuNjE5WicgZmlsbD0nI2RkZTFlZScvPjwvc3ZnPgo=);
  content: "";
}
.datatable-custom-pagination {
  margin-bottom: 0;
}
.datatable-custom-content-box.dataTable,
.datatable-custom-content-box.dataTable td,
.datatable-custom-content-box.dataTable th {
  box-sizing: content-box;
}
.datatable-custom-centered .dataTable {
  margin: 0 auto;
}
.datatable-custom-collapsible td.details-control {
  position: relative;
}
.datatable-custom-collapsible td.details-control::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19,11.5v1a.5.5,0,0,1-.5.5H13v5.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V13H5.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H11V5.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V11h5.5A.5.5,0,0,1,19,11.5Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
  content: "";
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.datatable-custom-collapsible tr.shown td.details-control::before {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%23677788' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5,13a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.daterangepicker-custom-input {
  cursor: pointer;
}
.daterangepicker-wrapper {
  border: none;
  padding: 0;
  cursor: pointer;
}
.daterangepicker {
  border: none;
  font-family: inter, sans-serif;
  z-index: 98;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  margin-top: 0.5rem;
  border-radius: 0.75rem;
}
.daterangepicker.opensright::after,
.daterangepicker.opensright::before {
  left: 1rem;
}
.daterangepicker::after,
.daterangepicker::before {
  top: -0.5rem;
  border-bottom-color: #fff;
  border-bottom-width: 0.5rem;
}
.daterangepicker.drop-up::before {
  border-top-color: #fff;
}
.daterangepicker .drp-calendar {
  max-width: 18.75rem;
}
.daterangepicker .drp-calendar.left {
  padding: 1rem;
}
.daterangepicker .drp-calendar.right {
  padding: 1rem;
}
.daterangepicker th.next,
.daterangepicker th.prev {
  min-width: auto;
  width: 2.1875rem;
  height: 2.1875rem;
  color: #01684b;
  font-size: 0.875rem;
  border-radius: 50%;
}
.daterangepicker th.next:hover,
.daterangepicker th.prev:hover {
  background-color: rgba(55, 125, 255, 0.1);
}
.daterangepicker th.next:hover .daterangepicker-custom-arrow,
.daterangepicker th.prev:hover .daterangepicker-custom-arrow {
  color: #01684b;
}
.daterangepicker .calendar-table table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;
}
.daterangepicker .calendar-table th:not(.month) {
  color: #97a4af;
  font-weight: 600;
  text-transform: uppercase;
}
.daterangepicker .calendar-table th.month {
  font-size: 0.875rem;
  font-weight: 600;
}
.daterangepicker .calendar-table td {
  width: 2.1875rem;
  height: 2.1875rem;
  font-size: 0.875rem;
  line-height: 1.9375rem;
}
.daterangepicker td.available:not(.in-range) {
  border-radius: 50%;
}
.daterangepicker td.available:hover:not(.active) {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
}
.daterangepicker td.in-range,
.daterangepicker td.in-range.available:hover {
  color: #fff;
  background-color: #01684b;
}
.daterangepicker td.today.start-date.end-date {
  border-radius: 50%;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  color: #fff;
  background-color: #01684b;
}
.daterangepicker td.active.start-date,
.daterangepicker td.active:hover.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}
.daterangepicker td.active.end-date,
.daterangepicker td.active:hover.end-date {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  color: #e7eaf3;
}
.daterangepicker .drp-buttons,
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-color: #e7eaf3;
}
.daterangepicker .drp-buttons {
  padding: 1rem;
}
.daterangepicker .drp-buttons .btn {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.54688rem 0.875rem;
}
.daterangepicker .cancelBtn {
  background-color: #fff;
  border-color: #e7eaf3;
}
.daterangepicker .cancelBtn.active,
.daterangepicker .cancelBtn:active,
.daterangepicker .cancelBtn:focus,
.daterangepicker .cancelBtn:hover {
  color: #0052ea;
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.daterangepicker .drp-selected {
  color: #71869d;
}
@media (max-width: 575.98px) {
  .daterangepicker .drp-selected {
    display: block;
    margin-bottom: 0.5rem;
  }
}
.daterangepicker .ranges ul {
  min-width: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.daterangepicker .ranges li {
  color: #71869d;
  font-size: 0.875rem;
  line-height: 1.6;
  padding: 0.375rem 1.5rem;
}
.daterangepicker .ranges li:hover {
  color: #0052ea;
  background-color: transparent;
}
.daterangepicker .ranges li.active {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  cursor: pointer;
  width: 3.5rem;
  font-size: 0.8125rem;
  color: #1e2022;
  background-color: transparent;
  border-color: #e7eaf3;
  padding: 0.25rem;
  border-radius: 0.3125rem;
}
.daterangepicker select.ampmselect:hover,
.daterangepicker select.hourselect:hover,
.daterangepicker select.minuteselect:hover,
.daterangepicker select.secondselect:hover {
  color: #0052ea;
}
.dropzone-custom {
  cursor: pointer;
}
.dropzone-custom .dz-message {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}
.dropzone-custom .dz-details {
  margin-bottom: 1rem;
}
.dropzone-custom .dz-file-preview {
  background-color: #fff;
  border-radius: 0.3125rem;
  padding: 1rem;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}
.dropzone-custom .dz-file-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.dropzone-custom .dz-filename {
  margin-bottom: 0.25rem;
}
.dropzone-custom .dz-img {
  max-width: 2.625rem;
  border-radius: 0.3125rem;
  margin-right: 0.75rem;
}
.dropzone-custom .dz-img-inner {
  border-radius: 0.3125rem;
}
.dropzone-custom .dz-close-icon,
.dropzone-custom .dz-size {
  color: #677788;
}
.dropzone-custom .dz-title {
  font-size: 0.75rem;
  font-weight: 600;
}
.dropzone-custom .dz-size {
  font-size: 80%;
}
.dropzone-custom .dz-file-initials {
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;
  line-height: 2.625rem;
  font-weight: 600;
  font-size: 0.92969rem;
  color: #01684b;
  text-align: center;
  background-color: rgba(55, 125, 255, 0.1);
  border-radius: 0.3125rem;
  margin-right: 0.75rem;
}
.dropzone-custom [data-dz-thumbnail]:not([src]) {
  display: none;
  margin-bottom: 0;
}
.dropzone-custom .dz-progress {
  margin-bottom: 1rem;
}
.dropzone-custom .dz-processing .dz-error-mark,
.dropzone-custom .dz-processing .dz-success-mark {
  display: none;
}
.dropzone-custom .dz-processing.dz-error .dz-error-mark,
.dropzone-custom .dz-processing.dz-success .dz-success-mark {
  display: block;
}
.dropzone-custom .dz-processing .dz-error-mark {
  color: #ed4c78;
}
.dropzone-custom .dz-processing .dz-success-mark {
  color: #00c9a7;
}
.fancybox-custom .fancybox-slide.animated {
  display: block;
  opacity: 0;
  z-index: 0;
}
.fancybox-custom .fancybox-slide.animated.fancybox-slide--current {
  opacity: 1;
  z-index: 1;
}
.fancybox-custom .fancybox-content {
  background-color: transparent;
}
.fancybox-custom .fancybox-bg {
  background-color: #1e2022;
}
.fancybox-custom .fancybox-button svg {
  margin-bottom: 0;
}
.fancybox-custom .fancybox-progress {
  background-color: #01684b;
}
.fancybox-blur aside,
.fancybox-blur footer,
.fancybox-blur header,
.fancybox-blur main {
  -webkit-filter: blur(30px);
  filter: blur(30px);
}
.fullcalendar-custom .fc-list-empty {
  background-color: #f8fafd;
}
.fullcalendar-custom .fc-daygrid-dot-event.fc-event-mirror,
.fullcalendar-custom .fc-daygrid-dot-event:hover {
  color: #01684b;
}
.fullcalendar-custom .fc-daygrid-dot-event {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1e2022;
  background-color: rgba(55, 125, 255, 0.1);
  border-radius: 0.3125rem;
}
.fullcalendar-custom .fc-v-event .fc-event-main {
  color: #1e2022;
  font-weight: 600;
}
.fullcalendar-custom .fc-h-event,
.fullcalendar-custom .fc-v-event {
  cursor: pointer;
  border: none;
}
.fullcalendar-custom .fc-h-event {
  background-color: rgba(55, 125, 255, 0.1);
}
.fullcalendar-custom .fc-listWeek-view .fc-list-event {
  background-color: #fff;
}
.fullcalendar-custom .fullcalendar-custom-event-hs-team {
  background-color: #eaf1ff;
}
.fullcalendar-custom .fullcalendar-custom-event-hs-team .fc-list-event-dot {
  border-color: #b7d0ff;
}
.fullcalendar-custom .fullcalendar-custom-event-reminders {
  background-color: #fdeef2;
}
.fullcalendar-custom .fullcalendar-custom-event-reminders .fc-list-event-dot {
  border-color: #f9c0cf;
}
.fullcalendar-custom .fullcalendar-custom-event-tasks {
  background-color: #fdf3e8;
}
.fullcalendar-custom .fullcalendar-custom-event-tasks .fc-list-event-dot {
  border-color: #f8dbba;
}
.fullcalendar-custom .fullcalendar-custom-event-holidays {
  background-color: #c2faff;
}
.fullcalendar-custom .fullcalendar-custom-event-holidays .fc-list-event-dot {
  border-color: #8ff6ff;
}
.fullcalendar-custom .fc-daygrid-inline-block-event {
  display: inline-block;
}
.fullcalendar-custom .fc-daygrid-dot-event .fc-event-title,
.fullcalendar-custom .fc-daygrid-event {
  font-weight: 600;
}
.fullcalendar-custom .fc-event-resizable,
.fullcalendar-custom .fc-timegrid-event-harness .fc-timegrid-event {
  max-width: 15rem;
}
.fullcalendar-custom .fc-daygrid-event,
.fullcalendar-custom .fc-timegrid-event .fc-event-main {
  padding: 0.3125rem;
}
.fullcalendar-custom .fc-daygrid-block-event .fc-event-time,
.fullcalendar-custom .fc-daygrid-block-event .fc-event-title {
  color: #1e2022;
  padding: 0;
}
.fullcalendar-custom .fc-daygrid-block-event .fc-event-time:hover,
.fullcalendar-custom .fc-daygrid-block-event .fc-event-title:hover {
  color: #01684b;
}
.fullcalendar-custom .fc-daygrid-block-event .fc-event-time.fc-sticky,
.fullcalendar-custom .fc-daygrid-block-event .fc-event-title.fc-sticky {
  padding-right: 0;
  padding-left: 0;
}
.fullcalendar-custom .fc-daygrid-event .fc-event-title {
  padding: 0.125rem 0;
}
.fullcalendar-custom .fc-event-time {
  font-size: 0.75rem;
}
.fullcalendar-custom .fc-event-title.fc-sticky {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8125rem;
}
.fullcalendar-custom .fc-daygrid-event,
.fullcalendar-custom.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fullcalendar-custom.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin: 0.125rem 0;
}
.fullcalendar-custom.fc-direction-ltr
  .fc-daygrid-block-event:not(.fc-event-end),
.fullcalendar-custom.fc-direction-rtl
  .fc-daygrid-block-event:not(.fc-event-start) {
  border-radius: 0.3125rem;
}
.fullcalendar-custom.fc table,
.fullcalendar-custom.fc td,
.fullcalendar-custom.fc th {
  border-color: #e7eaf3;
}
.fullcalendar-custom.fc .fc-view:not(.fc-timeGridDay-view) .fc-daygrid-day {
  height: 10rem;
}
.fullcalendar-custom.fc .fc-daygrid-day-frame {
  padding: 0.25rem;
}
.fullcalendar-custom.fc .fc-col-header-cell-cushion,
.fullcalendar-custom.fc .fc-timegrid-slot-label-cushion {
  font-size: 0.76562rem;
  color: #97a4af;
  text-transform: uppercase;
}
.fullcalendar-custom.fc .fc-col-header-cell-cushion {
  display: block;
  background-color: transparent;
  padding: 0.5rem;
}
.fullcalendar-custom.fc .fc-day-today .fc-col-header-cell-cushion {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.fullcalendar-custom.fc .fc-daygrid-day-top {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0.125rem;
}
.fullcalendar-custom.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}
.fullcalendar-custom.fc
  .fc-day-other
  .fc-daygrid-day-top
  .fc-daygrid-day-number {
  color: #e7eaf3;
}
.fullcalendar-custom.fc .fc-daygrid-day-number {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 2.1875rem;
  height: 2.1875rem;
  color: #132144;
  border-radius: 50%;
}
.fullcalendar-custom.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}
.fullcalendar-custom.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: #fff;
  background-color: #01684b;
}
.fullcalendar-custom.fc .fc-highlight,
.fullcalendar-custom.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(55, 125, 255, 0.1);
}
.fullcalendar-custom.fc .fc-cell-shaded,
.fullcalendar-custom.fc .fc-day-disabled {
  background-color: rgba(231, 234, 243, 0.5);
}
.fullcalendar-custom.fc .fc-button {
  font-size: 0.875rem;
  color: #677788;
  background-color: transparent;
  border-color: #e7eaf3;
  text-transform: capitalize;
  padding: 0.4375rem 0.65625rem;
}
.fullcalendar-custom.fc .fc-button .fc-icon {
  font-size: 0.875rem;
  vertical-align: baseline;
}
.fullcalendar-custom.fc .fc-button.active,
.fullcalendar-custom.fc .fc-button.focus,
.fullcalendar-custom.fc .fc-button:active,
.fullcalendar-custom.fc .fc-button:focus,
.fullcalendar-custom.fc .fc-button:hover {
  color: #0052ea;
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.fullcalendar-custom.fc .fc-button.fc-button-active {
  color: #fff;
  background-color: #01684b;
  border-color: #01684b;
}
.fullcalendar-custom.fc .fc-button.fc-button-primary:focus,
.fullcalendar-custom.fc
  .fc-button.fc-button-primary:not(:disabled).fc-button-active:focus,
.fullcalendar-custom.fc
  .fc-button.fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}
.fullcalendar-custom.fc
  .fc-button.fc-button-primary:not(:disabled).fc-button-active,
.fullcalendar-custom.fc .fc-button.fc-button-primary:not(:disabled):active {
  color: #fff;
  background-color: #01684b;
  border-color: #01684b;
}
.fullcalendar-custom.fc .fc-toolbar-title {
  font-size: 1.14844rem;
}
.fullcalendar-custom-timegrid.fc-theme-standard .fc-scrollgrid td {
  border-bottom: none;
}
.fullcalendar-custom-timegrid.fc-theme-standard
  .fc-scrollgrid
  td
  .fc-timegrid-slot-minor {
  border-top: none;
}
.fullcalendar-custom-timegrid .fc-col-header-cell-cushion {
  cursor: pointer;
}
.fullcalendar-custom-timegrid .fc-col-header-cell-cushion .day-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.fullcalendar-custom-timegrid .fc-col-header-cell-cushion:hover {
  color: #0052ea;
}
.fullcalendar-custom-timegrid .fc-timegrid-event {
  min-height: 5rem;
  padding: 0.3125rem;
}
.fullcalendar-custom-timegrid .fc-timegrid-now-indicator-line {
  border-color: #ed4c78;
}
.fullcalendar-custom-timegrid .fc-timegrid-now-indicator-arrow {
  left: auto;
  right: 0;
  border: none;
}
.fullcalendar-custom-timegrid .fc-timegrid-now-indicator-arrow::before {
  position: absolute;
  top: 1px;
  right: 0;
  width: 0.5rem;
  height: 0.5rem;
  content: "";
  background-color: #ed4c78;
  border-radius: 50%;
}
.fc-theme-standard .fc-list {
  border-color: #e7eaf3;
}
.fc-theme-standard .fc-list .fc-list-event:hover td {
  background-color: transparent;
}
.fc-theme-standard .fc-list .fc-list-day-text {
  color: #1e2022;
}
.fc-theme-standard .fc-list .fc-list-day-side-text {
  color: #677788;
  font-weight: 400;
}
.fullcalendar-custom.fc .fc-popover {
  border-color: rgba(231, 234, 243, 0.7);
  box-shadow: 0 6px 12px rgba(140, 152, 164, 0.075);
  border-radius: 0.75rem;
}
.fullcalendar-custom.fc .fc-popover-header {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  background-color: #f8fafd;
  padding: 0.5rem 0.75rem;
}
.fullcalendar-custom.fc .fc-more-popover .fc-popover-body {
  padding: 0.5rem 0.75rem;
}
.fullcalendar-custom.fc .fc-more-popover .fc-popover-body .fc-sticky {
  position: static;
}
.fullcalendar-custom.fc .fc-popover-title {
  margin: 0;
}
.fullcalendar-custom.fc .fc-popover-close:hover {
  color: #0052ea;
}
.fullcalendar-event-popover {
  width: 25rem;
  max-width: 25rem;
}
.flatpickr-custom {
  position: relative;
  width: 100% !important;
}
.flatpickr-custom .flatpickr-calendar {
  top: calc(1.6em + 1.21875rem) !important;
  left: 0 !important;
  width: auto;
}
.flatpickr-custom-form-control[readonly],
.form-control[readonly] {
  background-color: transparent;
}
.flatpickr-calendar {
  width: 21.125rem;
  padding: 1rem;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  border-radius: 0.75rem;
  margin-top: 0.5rem;
}
.flatpickr-calendar::before {
  left: 1rem;
  border-width: 0.5rem;
  margin: 0 0.5rem;
}
.flatpickr-calendar.arrowTop::before {
  border-bottom-color: #fff;
}
.flatpickr-calendar::after {
  display: none;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  animation: fadeInUp 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar .flatpickr-months {
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.75rem;
}
.flatpickr-calendar .flatpickr-current-month,
.flatpickr-calendar .flatpickr-next-month,
.flatpickr-calendar .flatpickr-prev-month {
  padding: 0;
}
.flatpickr-calendar .flatpickr-current-month {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
}
.flatpickr-calendar .flatpickr-next-month,
.flatpickr-calendar .flatpickr-prev-month {
  position: static;
  color: #677788;
  width: 2.1875rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
  background-color: transparent;
  border-radius: 50%;
}
.flatpickr-calendar .flatpickr-next-month:hover,
.flatpickr-calendar .flatpickr-prev-month:hover {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
}
.flatpickr-calendar .flatpickr-weekday {
  color: #97a4af;
  text-transform: uppercase;
  font-weight: 600;
}
.flatpickr-calendar .rangeMode .flatpickr-day {
  margin-top: 0.25rem;
}
.flatpickr-calendar .flatpickr-monthDropdown-months {
  font-size: 0.875rem;
  font-weight: 600;
}
.flatpickr-calendar .flatpickr-monthDropdown-months:hover {
  background-color: transparent;
}
.flatpickr-calendar .flatpickr-current-month input.cur-year {
  font-size: 0.875rem;
  font-weight: 600;
}
.flatpickr-calendar .flatpickr-day {
  color: #1e2022;
  border: none;
}
.flatpickr-calendar .flatpickr-day:focus,
.flatpickr-calendar .flatpickr-day:hover {
  color: #01684b;
  background-color: rgba(55, 125, 255, 0.1);
}
.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.endRange.inRange,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.endRange:focus,
.flatpickr-calendar .flatpickr-day.endRange:hover,
.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.selected.inRange,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected:focus,
.flatpickr-calendar .flatpickr-day.selected:hover,
.flatpickr-calendar .flatpickr-day.startRange,
.flatpickr-calendar .flatpickr-day.startRange.inRange,
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.startRange:focus,
.flatpickr-calendar .flatpickr-day.startRange:hover {
  color: #fff;
  background-color: #01684b;
  border-color: #01684b;
}
.flatpickr-calendar .flatpickr-day.inRange {
  color: #fff;
  background-color: #01684b;
  box-shadow: -0.35rem 0 0 #01684b, 0.35rem 0 0 #01684b;
}
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:hover,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay:hover {
  color: #fff;
  border-color: #01684b;
  background-color: #01684b;
}
.flatpickr-calendar .flatpickr-day.today {
  color: #fff;
  border-color: transparent;
  background-color: lightslategrey;
}
.flatpickr-calendar .flatpickr-day.nextMonthDay,
.flatpickr-calendar .flatpickr-day.prevMonthDay {
  color: #e7eaf3;
}
.flatpickr-calendar .flatpickr-day.nextMonthDay:focus,
.flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.prevMonthDay:focus,
.flatpickr-calendar .flatpickr-day.prevMonthDay:hover {
  color: #bdc5d1;
  background-color: #e7eaf3;
  border-color: #e7eaf3;
}
.flatpickr-calendar .flatpickr-day.disabled {
  color: #99a7ba;
}
.flatpickr-calendar .flatpickr-day.disabled:hover {
  color: #99a7ba;
  background-color: #e7eaf3;
  border-color: #e7eaf3;
}
.flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -0.35rem 0 0 #01684b, 0.35rem 0 0 #01684b;
}
.flatpickr-calendar .numInputWrapper span.arrowDown,
.flatpickr-calendar .numInputWrapper span.arrowUp {
  display: none;
}
.flatpickr-calendar .numInputWrapper:hover {
  background-color: transparent;
}
.flatpickr-custom-borderless {
  width: 0 !important;
  min-width: 6.5rem !important;
}
.flatpickr-custom-borderless .input-group-text {
  border: 0;
  padding-right: 0 !important;
}
.flatpickr-custom-borderless .flatpickr-custom-form-control {
  border: none;
  cursor: pointer;
  box-shadow: none;
  padding-right: 0;
  color: #01684b;
}
.hs-form-search-menu-content {
  position: absolute;
  display: block !important;
  opacity: 0;
  pointer-events: none;
}
.hs-form-search-menu-hidden {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}
.hs-form-search-menu-initialized {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.hs-loader-wrapper {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  display: none;
  background-color: #fff;
  border-radius: 0.3125rem;
}
.hs-loader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.hs-unfold {
  position: relative;
  display: inline-block;
}
.hs-unfold-content {
  display: block !important;
  opacity: 0;
  pointer-events: none;
}
.hs-unfold-content-initialized {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.hs-unfold-hidden {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}
.hs-unfold-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(19, 33, 68, 0.25);
}
.hs-unfold-content.hs-unfold-reverse-y {
  top: auto;
  bottom: 100%;
  margin-top: 0;
}
.hs-unfold-has-submenu {
  top: 0;
  right: calc(100% + 0.75rem);
  left: auto;
  margin-top: 0;
}
.hs-unfold-has-submenu::after {
  position: absolute;
  top: 0;
  display: block;
  left: calc(100% - 0.0625rem);
  width: 1rem;
  height: 100%;
  content: "";
}
.hs-unfold-has-submenu-right {
  top: 0;
  right: auto;
  left: calc(100% + 0.75rem);
  margin-top: 0;
}
.hs-unfold-has-submenu-right::after {
  position: absolute;
  top: 0;
  display: block;
  right: calc(100% - 0.0625rem);
  width: 1rem;
  height: 100%;
  content: "";
}
.hs-nav-scroller-horizontal {
  position: relative;
}
.hs-nav-scroller-horizontal .nav {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  scroll-behavior: smooth;
}
.hs-nav-scroller-horizontal .nav .nav-item {
  white-space: nowrap;
}
.hs-nav-scroller-horizontal .nav::-webkit-scrollbar {
  display: none;
}
.hs-nav-scroller-horizontal .nav-tabs {
  padding-bottom: 2px;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next,
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev {
  position: absolute;
  height: 100%;
  z-index: 1;
  font-size: 1.3125rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link {
  width: 2.625rem;
  color: #677788;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-link:hover {
  color: #0052ea;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-prev {
  left: 0;
  margin-left: -0.125rem;
}
.hs-nav-scroller-horizontal
  .hs-nav-scroller-arrow-prev
  .hs-nav-scroller-arrow-link {
  padding: 0.75rem 0;
  background-image: linear-gradient(
    to right,
    #fff 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: repeat-x;
}
.hs-nav-scroller-horizontal .hs-nav-scroller-arrow-next {
  right: 0;
  margin-right: -0.125rem;
}
.hs-nav-scroller-horizontal
  .hs-nav-scroller-arrow-next
  .hs-nav-scroller-arrow-link {
  padding: 0.75rem 0;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    #fff 50%
  );
  background-repeat: repeat-x;
  text-align: right;
}
.hs-nav-scroller-horizontal
  .hs-nav-scroller-arrow-dark-next
  .hs-nav-scroller-arrow-link,
.hs-nav-scroller-horizontal
  .hs-nav-scroller-arrow-dark-prev
  .hs-nav-scroller-arrow-link {
  color: rgba(255, 255, 255, 0.7);
}
.hs-nav-scroller-horizontal
  .hs-nav-scroller-arrow-dark-next
  .hs-nav-scroller-arrow-link:hover,
.hs-nav-scroller-horizontal
  .hs-nav-scroller-arrow-dark-prev
  .hs-nav-scroller-arrow-link:hover {
  color: #fff;
}
.hs-nav-scroller-horizontal
  .hs-nav-scroller-arrow-dark-prev
  .hs-nav-scroller-arrow-link {
  background-image: linear-gradient(
    to right,
    #132144 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-repeat: repeat-x;
}
.hs-nav-scroller-horizontal
  .hs-nav-scroller-arrow-dark-next
  .hs-nav-scroller-arrow-link {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    #132144 50%
  );
  background-repeat: repeat-x;
}
.hs-nav-scroller-vertical {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.hs-nav-scroller-vertical::-webkit-scrollbar {
  width: 0.6125rem;
}
.hs-nav-scroller-vertical::-webkit-scrollbar-thumb {
  background-color: rgba(189, 197, 209, 0.6);
}
.hs-nav-scroller-unfold {
  position: static;
}
.hs-fullscreen {
  position: fixed !important;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh !important;
  max-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 0;
}
.hs-fullscreen .hs-fullscreen-icon-default {
  display: none;
}
.hs-fullscreen .hs-fullscreen-icon-active {
  display: block;
}
.hs-fullscreen-on {
  overflow: hidden;
}
.hs-fullscreen-icon-default {
  display: block;
}
.hs-fullscreen-icon-active {
  display: none;
}
.range-slider-custom {
  height: 1.25rem;
}
.range-slider-custom .irs {
  height: 1.25rem;
}
.range-slider-custom .irs-line {
  height: 0.25rem;
}
.range-slider-custom .irs-bar {
  height: 0.25rem;
  background-color: #01684b;
}
.range-slider-custom .irs-handle {
  width: 1.53125rem;
  height: 1.53125rem;
  top: 0.76562rem;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}
.range-slider-custom .irs-handle i:first-child {
  display: none;
}
.range-slider-custom .irs-handle.state_hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.range-slider-custom .irs-from,
.range-slider-custom .irs-single,
.range-slider-custom .irs-to {
  top: -2.25rem;
  display: inline-block;
  min-width: 2.5rem;
  background-color: #fff;
  color: #1e2022;
  font-size: 0.8125rem;
  text-shadow: none;
  text-align: center;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
  border-radius: 0.3125rem;
  padding: 0.5rem;
}
.range-slider-custom .irs-from::before,
.range-slider-custom .irs-single::before,
.range-slider-custom .irs-to::before {
  border-top-color: #fff;
}
.range-slider-grid .irs-grid-pol {
  top: 0.5rem;
  height: 0.75rem;
  background-color: #e7eaf3;
}
.range-slider-grid .irs-grid-pol.small {
  display: none;
}
.range-slider-grid .irs-grid-text {
  top: 2rem;
  font-size: 0.875rem;
}
.jvectormap-custom {
  width: 100%;
  height: 100%;
}
.jvectormap-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}
.jvectormap-tip,
.jvectormap-zoomin,
.jvectormap-zoomout {
  position: absolute;
  border-radius: 0.25rem;
}
.jvectormap-tip {
  z-index: 100;
  display: none;
  color: #1e2022;
  background-color: #fff;
  padding: 0.54688rem 0.875rem;
  margin: -0.875rem;
  box-shadow: 0 6px 24px 0 rgba(140, 152, 164, 0.125);
}
.jvectormap-tip::before {
  position: absolute;
  left: calc(50% - 0.5rem);
  bottom: -0.4375rem;
  width: 1rem;
  height: 0.5rem;
  content: "";
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMXJlbScgaGVpZ2h0PScwLjVyZW0nIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycgeD0nMHB4JyB5PScwcHgnIHZpZXdCb3g9JzAgMCA1MCAyMi40OSc+PHBhdGggZmlsbD0nI2ZmZicgZD0nTTAsMGg1MEwzMS44NywxOS42NWMtMy40NSwzLjczLTkuMzMsMy43OS0xMi44NSwwLjEzTDAsMHonLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1rem 0.5rem;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #677788;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  width: 1.53125rem;
  height: 1.53125rem;
  cursor: pointer;
}
.jvectormap-zoomin:focus,
.jvectormap-zoomin:hover,
.jvectormap-zoomout:focus,
.jvectormap-zoomout:hover {
  color: #0052ea;
}
.jvectormap-zoomin {
  top: 1rem;
  left: 1rem;
}
.jvectormap-zoomout {
  top: 2.84375rem;
  left: 1rem;
}
.leaflet-custom {
  z-index: 98;
}
.leaflet-bar,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  border: none;
  box-shadow: none;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 1.53125rem;
  height: 1.53125rem;
  line-height: 1.6;
  font-size: 0.8125rem;
  font-family: inter, sans-serif;
  color: #677788;
  background-color: #fff;
  border: 0.0625rem solid #e7eaf3;
  border-radius: 0.3125rem;
  text-indent: inherit;
}
.leaflet-bar a:first-child {
  margin-bottom: 0.25rem;
}
.leaflet-bar a:last-child {
  border-bottom: 0.0625rem solid #e7eaf3;
}
.leaflet-bar a:hover {
  color: #01684b;
}
.leaflet-popup {
  margin-bottom: 3rem;
}
.quill-custom {
  position: relative;
  width: 100%;
}
.quill-custom .ql-container {
  position: static;
}
.quill-custom .ql-container.ql-snow,
.quill-custom .ql-toolbar.ql-snow {
  border-color: #e7eaf3;
}
.quill-custom .ql-toolbar.ql-snow .ql-picker-label {
  border-width: 0;
}
.quill-custom .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #677788;
  border-radius: 0.25rem;
}
.quill-custom .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #677788;
}
.quill-custom .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-width: 0;
  border-radius: 0.75rem;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  padding: 0;
  overflow: hidden;
  margin-top: 0.5rem;
}
.quill-custom
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item {
  padding: 0.375rem 1.5rem;
}
.quill-custom
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item:hover {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}
.quill-custom .ql-toolbar.ql-snow {
  padding: 0.75rem 1.3125rem;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.quill-custom .ql-container.ql-snow {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.quill-custom.ql-toolbar-bottom .ql-toolbar.ql-snow {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.quill-custom.ql-toolbar-bottom .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border: 0.0625rem solid #e7eaf3;
}
.quill-custom .ql-toolbar-list {
  line-height: normal;
  margin-bottom: 0;
}
.quill-custom .ql-formats:first-child {
  padding-left: 0;
}
.quill-custom .ql-formats:first-child button {
  margin-right: 0.5rem;
}
.quill-custom .ql-formats:first-child button:first-child {
  margin-left: -0.25rem;
}
.quill-custom .ql-editor {
  position: relative;
  min-height: 10rem;
  padding: 1.3125rem;
}
.quill-custom .ql-editor > * {
  font-size: 0.875rem;
  font-family: inter, sans-serif;
  color: #1e2022;
}
.quill-custom .ql-editor-content {
  min-height: 10rem;
}
.quill-custom .ql-editor.ql-blank::before {
  left: 1.3125rem;
  color: #97a4af;
  font-family: inter, sans-serif;
  font-style: normal;
}
.quill-custom .ql-snow a.btn-primary {
  color: #fff;
}
.quill-custom .ql-snow.ql-toolbar .ql-fill {
  fill: #677788;
}
.quill-custom .ql-snow.ql-toolbar .ql-stroke {
  stroke: #677788;
}
.quill-custom .ql-snow.ql-toolbar button:hover {
  color: #01684b;
}
.quill-custom .ql-snow.ql-toolbar button:hover .ql-fill {
  fill: #01684b;
}
.quill-custom .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: #01684b;
}
.quill-custom .ql-snow .ql-toolbar button,
.quill-custom .ql-snow.ql-toolbar button {
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.25rem;
}
.quill-custom .ql-snow .ql-tooltip {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  z-index: 1;
  min-width: 20rem;
  border-width: 0;
  text-align: center;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175);
  padding: 1.5rem;
  border-radius: 0.3125rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.quill-custom .ql-snow .ql-tooltip::before {
  display: block;
  text-align: center;
  font-family: inter, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 0.0625rem solid #e7eaf3;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  margin-right: 0;
}
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}
.quill-custom .ql-snow .ql-tooltip a.ql-preview,
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type="text"] {
  min-width: 20rem;
  font-size: 1rem;
  line-height: normal;
  border: 0.0625rem solid #e7eaf3;
  padding: 0.875rem 1.09375rem;
  margin-bottom: 1.5rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}
.quill-custom .ql-snow .ql-tooltip a.ql-preview {
  display: block;
}
.quill-custom .ql-snow .ql-tooltip a.ql-preview:hover {
  border-color: #01684b;
}
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type="text"] {
  height: auto;
  display: block;
  font-family: inter, sans-serif;
}
.quill-custom .ql-snow .ql-tooltip.ql-editing input[type="text"]:focus {
  border-color: rgba(55, 125, 255, 0.4);
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
}
.quill-custom .ql-snow .ql-action {
  display: inline-block;
  color: #fff;
  background-color: #01684b;
  font-size: 0.875rem;
  line-height: normal;
  padding: 0.875rem 1.09375rem;
  border-radius: 0.3125rem;
  transition: 0.3s;
}
.quill-custom .ql-snow .ql-action:hover {
  background-color: #1366ff;
}
.quill-custom .ql-snow .ql-tooltip a.ql-action::after,
.quill-custom .ql-snow .ql-tooltip a.ql-remove::before {
  padding-right: 0;
  margin-left: 0;
  border-right: none;
}
.quill-custom .ql-snow .ql-tooltip a.ql-remove {
  border: 0.0625rem solid #e7eaf3;
  font-size: 0.875rem;
  padding: 0.875rem 1.09375rem;
  border-radius: 0.3125rem;
  margin-left: 0.5rem;
  transition: 0.3s;
}
.quill-custom .ql-snow .ql-tooltip a.ql-remove:hover {
  color: #0052ea;
  box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.select2-custom {
  position: relative;
}
.select2-custom .select2-custom-hide {
  display: none;
}
.select2-custom-right .select2-container--open {
  right: 0 !important;
  left: auto !important;
}
@media (min-width: 576px) {
  .select2-custom-sm-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}
@media (min-width: 768px) {
  .select2-custom-md-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}
@media (min-width: 992px) {
  .select2-custom-lg-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}
@media (min-width: 1200px) {
  .select2-custom-xl-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}
@media (min-width: 1400px) {
  .select2-custom-xxl-right .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}
.select2-dropdown {
  border-color: #e7eaf3;
}
.select2-container--open .select2-dropdown--below {
  border-radius: 0.3125rem;
  border-top: 0.0625rem solid #e7eaf3;
  margin-top: 0.5rem;
}
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single {
  border-radius: 0.3125rem;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: rgba(55, 125, 255, 0.4);
}
.select2-container--default .custom-select.select2-selection--multiple,
.select2-container--default .form-control.select2-selection--multiple {
  height: auto;
  min-height: calc(1.6em + 1.21875rem);
}
.select2-container--default .select2-selection--multiple {
  border-color: #e7eaf3;
  padding: 0 0.875rem;
}
.select2-container--default
  .custom-select
  .select2-search--inline
  .select2-search__field,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  margin: 0.25rem 0.25rem 0.25rem 0;
  padding: 0.3125rem 0.875rem;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  position: relative;
  background-color: #e7eaf3;
  border-color: transparent;
  padding-right: 1.6125rem;
  line-height: normal;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  position: absolute;
  right: 0.125rem;
  color: #71869d;
  margin-right: 0.25rem;
}
.select2-container--default .select2-search__field {
  padding-left: 0 !important;
  width: 100% !important;
}
.select2-container--default .select2-search__field::-webkit-input-placeholder {
  color: #97a4af;
}
.select2-container--default .select2-search__field::-moz-placeholder {
  color: #97a4af;
}
.select2-container--default .select2-search__field:-ms-input-placeholder {
  color: #97a4af;
}
.select2-container--default .select2-search__field::-ms-input-placeholder {
  color: #97a4af;
}
.select2-container--default .select2-search__field::placeholder {
  color: #97a4af;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #e7eaf3;
  background-color: #f9fafc;
  padding: 0.4375rem 0.65625rem;
  border-radius: 0.3125rem;
}
.select2-container--default
  .select2-search--dropdown
  .select2-search__field::-webkit-input-placeholder {
  color: #97a4af;
}
.select2-container--default
  .select2-search--dropdown
  .select2-search__field::-moz-placeholder {
  color: #97a4af;
}
.select2-container--default
  .select2-search--dropdown
  .select2-search__field:-ms-input-placeholder {
  color: #97a4af;
}
.select2-container--default
  .select2-search--dropdown
  .select2-search__field::-ms-input-placeholder {
  color: #97a4af;
}
.select2-container--default
  .select2-search--dropdown
  .select2-search__field::placeholder {
  color: #97a4af;
}
.select2-container--default
  .select2-search--dropdown
  .select2-search__field:focus {
  border-color: rgba(55, 125, 255, 0.4);
  box-shadow: 0 0 10px rgba(55, 125, 255, 0.1);
}
.select2-container--default
  .select2-selection.active
  .select2-selection__placeholder {
  color: #1e2022;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  display: block;
  padding-left: 0;
}
.select2-container--default .select2-results__option {
  padding: 0.5rem 3rem 0.5rem 0.5rem;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}
.select2-container--default .select2-results__option:first-child,
.select2-container--default .select2-results__option:first-child:hover {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.select2-container--default .select2-results__option:last-child,
.select2-container--default .select2-results__option:last-child:hover {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}
.select2-container--default .select2-results__option {
  position: relative;
}
.select2-container--default
  .select2-results__option[aria-selected="true"]::after {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 110 110' fill='%23377dff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3E%3C/svg%3E%0A")
    no-repeat right center/1rem 1rem;
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected]:not(
    [aria-selected="true"]
  ) {
  color: #1e2022;
  background-color: rgba(189, 197, 209, 0.3);
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #e7eaf3;
}
.select2-selection__placeholder {
  color: #97a4af;
}
.select2-selection--multiple .select2-selection__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: 0.75rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sortablejs-custom.sortable-ghost {
  opacity: 0.3;
}
.sortablejs-custom-rotate.sortable-fallback {
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
  opacity: 1 !important;
}
.sortablejs-custom-handle {
  cursor: pointer;
}
.tagify {
  --tag-bg: unset;
  --tag-hover: unset;
  -ms-flex-align: center;
  align-items: center;
  border-color: #e7eaf3;
  border-radius: 0.25rem;
}
.tagify:hover {
  border-color: #e7eaf3;
}
.tagify.tagify--focus {
  border-color: rgba(55, 125, 255, 0.4);
}
.tagify__tag--hide {
  height: 0;
}
.tagify__tag {
  margin: 0.25rem 0.25rem 0.25rem 0;
  background-color: #e7eaf3;
  border-radius: 0.25rem;
}
.tagify__tag:first-child {
  margin-left: 0;
}
.tagify__tag > div {
  color: #1e2022;
  padding: 0.3125rem 0.875rem;
  border-radius: 0.25rem;
}
.tagify__tag__removeBtn {
  color: #71869d;
  width: 0.875rem;
  height: 0.875rem;
  line-height: 0.875rem;
}
.tagify__tag__removeBtn:hover {
  background-color: #ed4c78;
}
.tagify__tag__removeBtn:hover + div::before {
  box-shadow: none !important;
}
.tagify__tag__removeBtn:hover + div .tagify__tag-text {
  opacity: 1;
}
.tagify-form-control {
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 0 0.25rem;
}
.tagify-form-control.tagify--empty {
  padding: 0 0.875rem;
}
.tagify__input:first-child {
  padding-left: 0;
  margin-left: 0;
}
.tagify__input::before {
  color: #97a4af;
  line-height: 1.4;
}
.tagify__input .tagify__tag > div {
  padding: 0.3125rem 0.875rem;
  line-height: normal;
}
.tagify__dropdown__menu {
  overflow: hidden;
  border: 0.0625rem solid #e7eaf3;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  margin-top: -1px;
}
.tagify__dropdown__wrapper {
  border: none;
  box-shadow: none;
  transition: none !important;
}
.tagify__dropdown__item {
  color: #1e2022;
  padding: 0.54688rem 0.875rem;
  margin: 0;
  border-radius: 0;
}
.tagify__dropdown__item--active {
  background-color: rgba(189, 197, 209, 0.3);
}
.tagify.tagify-form-control-list {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
