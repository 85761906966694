@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Open+Sans:wght@400;500;600&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap";
.form--control {
  background: 0 0;
  border: 1px solid rgba(255, 255, 255, 0.2509803922);
  border-radius: 5px;
  color: #99a7ba !important;
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.1098039216);
}
.h--45px ~ .select2 .select2-container .select2-selection--single,
.h--45px ~ .select2 .select2-container--default .select2-selection--single {
  height: 45px !important;
}
.form--control:focus {
  color: #fff !important;
  border-color: var(--base-clr);
  background: rgba(153, 167, 186, 0.2);
}
.form--control:-webkit-autofill,
.form--control:-webkit-autofill:hover,
.form--control:-webkit-autofill:focus {
  border: 1px solid var(--base-clr) !important;
  -webkit-text-fill-color: #fff !important;
  box-shadow: 0 0 0 1000px rgba(153, 167, 186, 0.2) inset !important;
  -webkit-box-shadow: 0 0 0 1000px rgba(153, 167, 186, 0.2) inset !important;
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
.sidebar--search-form {
  padding: 13px 10px 14px;
}
.sidebar--search-form .form--control {
  padding-left: 40px;
}
.sidebar--search-form .search--form-group {
  position: relative;
  transition: all ease 0.3s;
}
.sidebar--search-form .search--form-group .btn {
  position: absolute;
  left: 5px;
  top: 0;
  color: #99a7ba !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
aside .nav-item .nav-link {
  color: #e9f3ff !important;
}
aside .navbar-vertical-aside-has-menu .nav-sub .nav-link {
  font-size: 0.8rem;
}
aside .navbar-nav > .nav-item:not(:first-child) {
  margin-top: 15px !important;
}
aside .nav-link {
  color: #e9f3ff !important;
}
aside .navbar-vertical-aside-has-menu.active > .nav-link {
  padding-left: 27px !important;
}
.navbar-vertical-aside-mini-mode
  .navbar-vertical:not([class*="container"])
  .navbar-nav
  .nav-link {
  padding-left: 1.75rem !important;
}
.navbar-vertical-aside-mini-mode .search--form-group {
  display: none;
}
.navbar-vertical-content {
  background: #055;
}
.swal2-popup .swal2-styled.swal2-cancel {
  color: #fff !important;
  background: var(--secondary-clr) !important;
}
.swal2-popup .swal2-styled:focus,
.swal2-popup .swal2-styled.swal2-confirm {
  box-shadow: none !important;
}
.swal2-popup {
  border-radius: 10px !important;
  padding: 30px !important;
  width: 100% !important;
  max-width: 500px !important;
  font-size: 1em !important;
  font-family: inter, sans-serif;
}
.swal2-popup .swal2-icon {
  margin-top: 0 !important;
  margin-bottom: 1.5em !important;
}
.swal2-popup .swal2-styled {
  min-width: 170px;
  text-align: center;
}
@media screen and (min-width: 575px) {
  .swal2-popup .swal2-styled {
    margin: 0.3125em 0.8em !important;
  }
}
.swal2-popup .swal2-title {
  font-family: public sans, sans-serif;
  font-size: 1.5em !important;
}
.swal2-popup .swal2-actions {
  margin-top: 1.5em !important;
}
.swal2-popup .swal2-close {
  color: var(--title-clr) !important;
  right: 15px !important;
  top: 15px !important;
}
:root {
  --primary-clr: #24bac3;
  --primary-clr-2: #009faa;
  --secondary-clr: #99a7ba;
  --title-clr: #334257;
  --dark-clr: #005555;
  --base-clr: #2aa26a;
  --base-clr-2: #5affba;
  --border-clr: #99a7ba45;
  --warning-clr: #ff7500;
  --danger-clr: #ff6d6d;
  --success-clr: #00aa6d;
  --info-clr: #0096ff;
  --theameColor: #045cff;
  --title-2: #758590;
  --title-3: #107980;
  --hover-clr: #1b7fed;
}
.navbar-vertical:not([class*="container"]) .navbar-nav.navbar-nav-lg .nav-link {
  margin: 3px 0;
}
.sidebar--badge-container {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.sidebar--badge-container span:first-child {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}
.sidebar--badge-container .badge {
  font-size: 10px;
  min-width: 25px;
  padding: 3px;
  font-weight: 700;
}
.navbar-brand-logo {
  border-radius: 50%;
  height: 55px;
  width: 55px !important;
  border: 5px solid rgba(128, 128, 128, 0.0705882353);
}
.g-1 {
  margin: -0.35rem;
}
.g-1 > *[class*="col"] {
  padding: 0.35rem !important;
}
.g-3 {
  margin: -0.625rem;
}
.g-3 > *[class*="col"] {
  padding: 0.625rem !important;
}
.g-4 {
  margin: -0.875rem;
}
.g-4 > *[class*="col"] {
  padding: 0.875rem !important;
}
@media screen and (min-width: 1200px) and (max-width: 1200.99px) {
  .navbar-vertical-aside-show-xl .navbar:not(.navbar-vertical) {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  .cmn--media.right-dropdown-icon::before {
    display: none;
  }
  .cmn--media.right-dropdown-icon .media-body {
    display: none !important;
  }
}
.nav-tabs .nav-link {
  border: none;
}
.nav-link svg {
  opacity: 1 !important;
}
.navbar-vertical .navbar-nav.nav-tabs .active > .nav-link,
.navbar-vertical .navbar-nav.nav-tabs .active.nav-link {
  position: relative;
  min-height: 36px;
  padding-left: 22px;
  padding-right: 22px;
}
.navbar-vertical .navbar-nav.nav-tabs .active > .nav-link .text-truncate,
.navbar-vertical .navbar-nav.nav-tabs .active.nav-link .text-truncate {
  color: var(--base-clr-2) !important;
  opacity: 1;
}
.navbar-vertical .navbar-nav.nav-tabs .active > .nav-link svg,
.navbar-vertical .navbar-nav.nav-tabs .active.nav-link svg {
  color: #5affba;
}
.navbar-vertical .navbar-nav.nav-tabs .active > .nav-link::before,
.navbar-vertical .navbar-nav.nav-tabs .active.nav-link::before {
  content: "";
  position: absolute;
  inset: 0 10px;
  background: rgba(239, 246, 255, 0.1);
  border-radius: 5px;
}
.navbar-vertical
  .navbar-nav.nav-tabs
  .navbar-vertical-aside-has-menu
  li.active
  > .nav-link {
  padding-right: 28px;
  padding-left: 52px;
}
.nav-subtitle {
  color: #b1e3ce;
  font-weight: 700;
}
.nav-divider {
  border: none;
}
.navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-brand {
  display: none;
}
.side-logo {
  height: 50px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left center;
  object-position: left center;
}
.navbar-vertical-aside .navbar-brand-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer-offset {
    display: flex;
    flex-direction: column;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-vertical-footer-offset
    .navbar-vertical-content {
    flex-grow: 1;
    height: 0;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-nav-wrap-content-left {
    flex-grow: 1;
    text-align: center;
  }
  .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode
    .navbar-nav-wrap-content-left
    button {
    float: none;
    margin: 0 auto;
  }
  .navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-has-menu
    .nav-item
    .nav-link
    .text-truncate,
  .navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-has-menu
    .navbar-vertical-aside-has-menu
    .nav-link
    .text-truncate {
    color: #055 !important;
  }
  .navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-has-menu
    .nav-item
    .navbar-vertical-aside-mini-mode-hidden-elements.text-truncate,
  .navbar-vertical-aside-mini-mode
    .navbar-vertical-aside-has-menu
    .navbar-vertical-aside-has-menu
    .navbar-vertical-aside-mini-mode-hidden-elements.text-truncate {
    display: block !important;
  }
}
.navbar-vertical .navbar-nav.nav-tabs .nav-link:hover .text-truncate,
.navbar-vertical .navbar-nav.nav-tabs .nav-link:hover svg {
  color: var(--base-clr-2);
}
.close {
  color: #003e2c;
}
.navbar.navbar-container {
  border: none;
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.1);
}
.navbar.navbar-container .cmn--media {
  text-align: right;
}
.navbar.navbar-container .cmn--media .card-title {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
}
.navbar.navbar-container .cmn--media .card-text {
  font-size: 12px;
  font-weight: 700;
}
.navbar.navbar-container .navbar-nav .dropdown-menu {
  position: absolute;
}
.lang-country-flag {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #334257;
}
.lang-country-flag img {
  width: 22px;
  height: 22px;
  -o-object-fit: contain;
  object-fit: contain;
}
.lang-country-flag span {
  font-size: 14px;
  padding-left: 8px;
}
.lang-country-flag::after {
  width: 1.4rem;
  height: 1.4rem;
  background-size: 1.4rem 1.4rem;
  transform: translateX(-4px);
}
.lang-country-flag ~ ul .lang-country-flag img {
  width: 17px;
  height: 17px;
  -o-object-fit: contain;
  object-fit: contain;
}
.notify--icon [class^="tio-"],
.notify--icon [class*=" tio-"] {
  font-size: 21px;
  transform: translateY(4px);
}
.notify--icon .amount {
  font-size: 10px;
  width: 21px;
  height: 21px;
  line-height: 19px;
  border: 2px solid #fff;
  color: #fff;
  background: #ff6767;
  border-radius: 50%;
  right: 0;
  top: 4px;
  position: absolute;
}
.navbar-dropdown-account-wrapper:focus,
.navbar-dropdown-account-wrapper:hover {
  background: 0 0;
}
.right-dropdown-icon {
  position: relative;
}
.right-dropdown-icon::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border: 4px solid #055;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
  left: -17px;
  top: 2px;
}
.navbar-vertical .nav-link .nav-indicator-icon {
  color: #66a593;
}
.navbar-vertical .active > .nav-link .nav-indicator-icon,
.navbar-vertical .nav-link:hover .nav-indicator-icon {
  color: var(--base-clr-2);
}
.w-16rem {
  width: 16rem;
}
.grid-card {
  border: 2px solid rgba(0, 0, 0, 0.0705882353);
  border-radius: 10px;
  padding: 10px;
}
.label_1 {
  position: absolute;
  font-size: 10px;
  background: #ff4c29;
  color: #fff;
  width: 80px;
  padding: 2px;
  font-weight: 700;
  border-radius: 6px;
  text-align: center;
}
.center-div {
  text-align: center;
  border-radius: 6px;
  padding: 6px;
  border: 2px solid rgba(128, 128, 128, 0.368627451);
}
.text-107980 {
  color: var(--title-3) !important;
}
.page-header-title {
  font-weight: 700;
  font-size: 18px;
}
.welcome-msg {
  font-weight: 600;
  color: #334257;
  font-size: 14px;
}
.card-title {
  font-size: 16px;
  font-weight: 600;
}
.btn--container {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}
.table {
  width: 100%;
}
.table .btn--container {
  flex-wrap: nowrap;
}
.btn--container > * {
  margin: 8px;
}
.table .btn--container {
  margin: -5px;
}
.table .btn--container > * {
  margin: 5px;
}
.btn--container .file_upload {
  width: 120px !important;
  height: 120px !important;
  display: contents !important;
}
.btn--container .spartan_item_wrapper .file_upload img {
  width: 120px !important;
  height: 120px !important;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
  margin: 0 !important;
}
.btn--container .custom-select {
  font-size: 14px;
  color: rgba(51, 66, 87, 0.75);
}
.dashboard--card {
  position: relative;
  border-radius: 10px;
  padding: 28px 50px 15px 28px;
  border: 1px solid rgba(16, 121, 128, 0.2);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(16, 121, 128, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dashboard--card .title {
  font-size: 1.375rem;
  font-weight: 700;
  margin: 0;
  color: #334257;
  font-size: 24px;
  font-weight: 700;
}
.dashboard--card .subtitle {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #334257;
  margin-bottom: 10px;
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .dashboard--card .subtitle {
    font-size: 12px;
  }
}
.dashboard--card .dashboard-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  max-width: 30px;
  height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
}
.dashboard--card .dashboard-icon img {
  max-width: 100%;
}
@media screen and (min-width: 1450px) {
  .dashboard--card {
    padding: 35px 55px 20px 30px;
    min-height: 125px;
  }
  .dashboard--card .dashboard-icon {
    right: 20px;
    top: 25px;
  }
}
.order--card {
  background: rgba(110, 137, 175, 0.0509803922);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px 15px 15px 20px;
  min-height: 65px;
}
.order--card .card-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  margin-left: 10px;
}
.order--card .card-subtitle {
  color: var(--title-clr);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.order--card .card-subtitle img {
  max-width: 20px;
  max-height: 20px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-right: 10px;
}
.text-warning {
  color: #f5a200 !important;
}
.main .content {
  padding-left: 21px;
  padding-right: 21px;
}
.mb-10px {
  margin-bottom: 10px !important;
}
.card {
  border: 1px solid #f4f4f4;
  box-shadow: 0 5px 10px rgba(51, 66, 87, 0.05);
  border-radius: 10px;
}
.card .card-header:first-child,
.card .card-body:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card .card-header:last-child,
.card .card-body:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media (min-width: 1200px) {
  .col--xl-8 {
    width: calc(100% - 310px) !important;
    max-width: calc(100% - 310px) !important;
    flex-basis: 100% !important;
  }
  .col--xl-4 {
    width: 310px !important;
    max-width: 310px !important;
  }
}
@media (min-width: 1650px) {
  .col--xl-8 {
    width: calc(100% - 400px) !important;
    max-width: calc(100% - 400px) !important;
    flex-basis: 100% !important;
  }
  .col--xl-4 {
    width: 400px !important;
    max-width: 400px !important;
  }
}
.statistics-btn-grp {
  border-radius: 5px;
  border: 1px solid rgba(16, 121, 128, 0.2);
}
.statistics-btn-grp label {
  padding: 3px;
  margin: 0;
  display: block;
}
.statistics-btn-grp label span {
  font-size: 12px;
  color: var(--title-3);
  text-transform: capitalize;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 15px;
  display: block;
}
.statistics-btn-grp label input:checked ~ span {
  background: var(--title-3);
  color: #fff;
  font-weight: 700;
}
.btn--container .card-title {
  display: flex;
  align-items: center;
}
.btn--container .card-title .card-icon {
  margin-right: 5px;
}
.card-title {
  text-transform: capitalize;
}
.p-20px {
  padding: 20px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pie-chart .apexcharts-canvas {
  margin: 0 auto;
}
.pie-chart .apexcharts-legend .apexcharts-legend-series {
  margin-top: 10px !important;
}
@media (max-width: 345px) {
  .pie-chart #dognut-pie {
    transform: translateX(-5px);
  }
}
@media (max-width: 335px) {
  .pie-chart #dognut-pie {
    transform: translateX(-10px);
  }
}
.apex-legends {
  color: #758590;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 280px;
  margin: 0 auto;
}
.apex-legends div {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.apex-legends div::before {
  width: 9px;
  border-radius: 50%;
  height: 9px;
  content: "";
  margin-right: 6px;
}
.apex-legends div.before-bg-107980::before {
  background: #107980;
}
.apex-legends div.before-bg-56B98F::before {
  background: #56b98f;
}
.apex-legends div.before-bg-E5F5F1::before {
  background: #6a5acd;
}
.apex-legends div.before-bg-44aa7a::before {
  background: #44aa7a;
}
.apex-legends div.before-bg-f51414::before {
  background: #f51414;
}
.apex-legends div.before-bg-2d2dc5::before {
  background: #2d2dc5;
}
.apex-legends div.before-bg-cc54cc::before {
  background: #cc54cc;
}
.apex-legends div:not(:last-child) {
  margin-right: 15px;
}
.apex-legends div.before-bg-036BB7::before {
  background: #036bb7;
}
.apex-legends div.before-bg-0e0def::before {
  background: #0e0def;
}
.apex-legends div.before-bg-ff00ff::before {
  background: #f0f;
}
.apex-legends div.before-bg-ff00ff::before {
  background: #f0f;
}
.order-header-shadow {
  box-shadow: 0 3px 3px rgba(51, 66, 87, 0.05);
}
.total--orders {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.total--orders h3 {
  margin: 0;
  font-weight: 700 !important;
  font-size: 19px;
  line-height: 23px;
  color: #334257 !important;
}
@media screen and (max-width: 575px) {
  .total--orders h3 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 5px;
  }
}
.fz-12px {
  font-size: 12px;
}
.text-006AE5 {
  color: #006ae5;
}
.font-light {
  font-weight: 300 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-regular {
  font-weight: 400 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.recent--orders {
  padding: 0;
  margin: 0;
}
.recent--orders li {
  list-style: none;
}
.recent--orders li:not(:last-child) {
  border-bottom: 0.961003px solid rgba(82, 102, 208, 0.05);
}
.recent--orders li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
}
.recent--orders li a div h6 {
  font-family: open sans;
  font-style: normal;
  font-weight: 700;
  font-size: 13.454px;
  line-height: 18px;
  margin: 0;
  color: var(--title-2);
}
.recent--orders li a div span {
  font-family: open sans;
  font-style: normal;
  font-weight: 400;
  font-size: 11.532px;
  line-height: 16px;
  color: var(--title-2);
}
.recent--orders li a .status {
  font-weight: 700;
  font-size: 13.454px;
  line-height: 16px;
  font-family: inter, sans-serif;
}
.text-0661CB {
  color: #0661cb !important;
}
.text-00A3FF {
  color: #00a3ff !important;
}
.text-F5A200 {
  color: #f5a200 !important;
}
.text-56b98f {
  color: #56b98f !important;
}

.text-b9565e {
  color: #b9565e !important;
}


.p-10px {
  padding: 10px !important;
}
.grid--card {
  display: flex;
  flex-wrap: nowrap;
}
.grid--card img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
  object-fit: contain;
  border: 1px solid rgba(7, 59, 116, 0.3);
  border-radius: 5px;
}
.grid--card .cont {
  width: 0;
  flex-grow: 1;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: var(--title-clr);
  padding-left: 9px;
  max-width: 184px;
  padding-right: 9px;
}
.grid--card .badge {
  padding: 9px 10px 7px;
  min-width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inter, sans-serif;
}
@media (max-width: 450px) {
  .grid--card .badge {
    padding: 9px 10px 7px;
    min-width: 0;
    font-size: 10px;
    margin-left: 3px;
  }
}
.badge.badge-soft {
  background: rgba(16, 121, 128, 0.05);
  border-radius: 3px;
}
.top--selling .grid--card:not(:last-child) {
  margin-bottom: 20px;
}
.rated--products a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #f8f9fb;
  border-radius: 5px;
}
.rated--products a:not(:last-child) {
  margin-bottom: 8px;
}
.rated--products a .rated-media {
  width: 0;
  flex-grow: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-clr);
}
.rated--products a .rated-media img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  -o-object-fit: contain;
  object-fit: contain;
}
.rated--products a .rated-media span {
  padding-left: 10px;
}
.rated--products a .rating {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #ff6d6d;
}
.line--limit-1,
.line--limit-2,
.line--limit-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
}
.line--limit-1 {
  -webkit-line-clamp: 1;
}
.line--limit-2 {
  -webkit-line-clamp: 2;
}
.line--limit-3 {
  -webkit-line-clamp: 3;
}
.page-header-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .page-header-title {
    font-size: 18px;
  }
}
.page-header-title i {
  margin-right: 10px;
  font-size: 26px;
  line-height: 1.2;
}
.shadow--card {
  background: #fff;
  border: 1px solid #f4f4f4;
  box-shadow: 0 5px 5px rgba(51, 66, 87, 0.05);
  border-radius: 7px;
  padding: 20px;
}
a[class*="btn--"],
button[class*="btn--"] {
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 9px 24px;
  color: #fff;
  text-transform: capitalize;
  border: 1px solid;
  font-family: open sans, sans-serif;
}
@media screen and (min-width: 1450px) {
  a[class*="btn--"],
  button[class*="btn--"] {
    padding: 12px 24px;
  }
}
a[class*="btn--"].font--sm,
button[class*="btn--"].font--sm {
  font-size: 0.75rem;
}
a[class*="btn--"]:hover,
button[class*="btn--"]:hover {
  filter: brightness(0.9);
}
a[class*="btn--"]:hover {
  color: #fff;
}
.btn--secondary {
  color: #fff !important;
  background: var(--secondary-clr);
  border-color: var(--secondary-clr) !important;
}
.btn--reset {
  color: var(--title-clr) !important;
  background: #f4f5f7 !important;
  border-color: #f4f5f7 !important;
}
.h--37px {
  height: 37px !important;
  line-height: 37px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.btn-primary,
.btn--primary {
  background: #107980 !important;
  border-color: #107980 !important;
}
.btn--primary:hover,
.btn-primary:hover {
  color: #fff !important;
  background: #0c6970 !important;
}
.btn-outline-primary:not(:hover) {
  background: 0 0 !important;
  color: #107980;
}
.btn-outline-primary:hover {
  background: #107980;
  color: #fff !important;
}
.btn-outline-primary-2 {
  font-weight: 600;
}
.btn-outline-primary-2:not(:hover) {
  background: 0 0 !important;
  color: #00c5d2 !important;
  border-color: #00c5d2 !important;
}
.btn-outline-primary-2:hover {
  background: #00c5d2;
  color: #fff !important;
}
.btn--primary-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn--primary-2:not(.btn-outline-primary-2):not(:hover) {
  background: var(--primary-clr);
  color: #fff !important;
}
.btn--primary-2:not(.btn-outline-primary-2):hover {
  background: 0 0 !important;
  color: var(--primary-clr) !important;
}
.btn-outline-info:not(:hover) {
  background: 0 0 !important;
  color: var(--primary-clr);
  border-color: var(--primary-clr);
}
.btn-outline-info:hover {
  background: var(--primary-clr);
  color: #fff !important;
}
.btn--info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn--info:not(:hover) {
  background: var(--primary-clr);
  color: #fff !important;
}
.btn--info:hover {
  background: 0 0 !important;
  color: var(--primary-clr) !important;
}
.btn-outline-secondary:not(:hover) {
  background: 0 0 !important;
}
.btn-outline-secondary:hover {
  background: var(--secondary-clr);
  color: #fff !important;
}
.btn--warning {
  background: var(--warning-clr) !important;
  border-color: var(--warning-clr) !important;
}
.btn-outline-warning:not(:hover) {
  background: rgba(255, 134, 32, 0.1) !important;
  color: var(--warning-clr) !important;
}
.btn-outline-warning:hover {
  background: var(--warning-clr) !important;
  color: #fff !important;
}
.btn--danger {
  background: var(--danger-clr) !important;
  border-color: var(--danger-clr) !important;
}
.btn-outline-danger:not(:hover) {
  background: 0 0 !important;
  color: var(--danger-clr);
}
.btn-outline-danger:hover {
  background: var(--danger-clr) !important;
  color: #fff !important;
}
.export-btn {
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 12px !important;
}
.export-btn span {
  margin: 0 5px;
  display: inline-block;
}
.export-btn i {
  font-size: 0.9rem !important;
}
.action-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em !important;
  padding: 0 !important;
  color: #0177cd;
  border: 1px solid #0177cd;
  border-radius: 5px;
  transition: all ease 0.3s;
  box-shadow: none !important;
}
.action-btn:hover {
  color: #fff;
  background: #0177cd;
}
@media screen and (min-width: 1650px) {
  .action-btn {
    width: 32px;
    height: 32px;
  }
}
.action-btn.btn-outline-primary:not(:hover) {
  background: rgba(0, 134, 143, 0.0392156863) !important;
}
.action-btn.btn-outline-primary-2:not(:hover) {
  color: var(--primary-clr-2) !important;
  border-color: var(--primary-clr-2) !important;
}
.action-btn.btn-outline-primary-2:hover {
  border-color: var(--primary-clr-2) !important;
  background: var(--primary-clr-2) !important;
}
@media (min-width: 576px) {
  .btn--container button[type="submit"],
  .btn--container button[type="reset"] {
    min-width: 120px;
    height: 45px;
  }
}
.table {
  width: 100%;
}
.table .btn--container {
  flex-wrap: nowrap;
}
.btn--container > * {
  margin: 8px;
}
.table .btn--container {
  margin: -5px;
}
.table .btn--container > * {
  margin: 5px;
}
.page-header-title {
  margin-bottom: 8px;
}
.page-header-title .page-header-icon img {
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left center;
  object-position: left center;
}
.page-header-title > span:not(:first-child) {
  margin-left: 10px;
}
@media (max-width: 1199.98px) {
  aside .navbar-nav-wrap-content-left {
    display: none !important;
  }
}
.search--button-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -7px !important;
  flex-grow: 1;
}
.search--button-wrapper > .btn,
.search--button-wrapper > form,
.search--button-wrapper > button,
.search--button-wrapper .card-title,
.search--button-wrapper .hs-unfold,
.search--button-wrapper > div {
  margin: 7px !important;
}
.search--button-wrapper > .card-title {
  margin-right: auto !important;
}
.search--button-wrapper *[class*="btn--"] {
  padding: 10px 20px;
}
@media (min-width: 576px) {
  .search--button-wrapper .search-form {
    min-width: 260px;
  }
}
.search--button-wrapper {
  margin: 0 -4px;
}
@media screen and (max-width: 575px) {
  .search--button-wrapper .hs-unfold {
    flex-grow: 1;
  }
  .search--button-wrapper .hs-unfold .btn {
    justify-content: center;
  }
  .search--button-wrapper .btn--primary,
  .search--button-wrapper .btn-primary {
    margin-right: 0 !important;
    flex-grow: 1;
  }
  .search--button-wrapper > .btn,
  .search--button-wrapper > form,
  .search--button-wrapper > button,
  .search--button-wrapper .card-title,
  .search--button-wrapper .hs-unfold,
  .search--button-wrapper > div {
    margin: 4px !important;
    flex-grow: 1;
  }
}
.js-hs-unfold-invoker {
  display: block;
}
.js-hs-unfold-invoker.dropdown-toggle,
.js-hs-unfold-invoker.btn-icon {
  display: flex !important;
}
.js-hs-unfold-invoker:hover::after {
  filter: brightness(3);
}
.table .thead-light th {
  font-size: 14px;
  color: var(--title-clr);
  font-weight: 600;
}
.w--26 {
  width: 26px !important;
}
.w--22 {
  width: 22px !important;
}
.img--65,
.img--60,
.img--70,
.img--75,
.img--80,
.img--90,
.img--100,
.img--110,
.img--120,
.img--130,
.img--200 {
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
}
.img--75 {
  width: 75px;
}
.img--65 {
  width: 65px;
}
.img--130 {
  width: 130px;
}
.img--120 {
  width: 120px !important;
}
.img--200 {
  width: 200px;
}
.img--110 {
  width: 110px;
}
.img--100 {
  width: 100px;
}
.img--90 {
  width: 90px;
}
.img--80 {
  width: 80px;
}
.img--70 {
  width: 70px;
}
.img--60 {
  width: 60px;
}
.min--200 {
  min-width: 200px;
}
.min--220 {
  min-width: 220px;
}
.min--240 {
  min-width: 240px;
}
.min--250 {
  min-width: 250px;
}
.min--260 {
  min-width: 250px;
}
.min--270 {
  min-width: 250px;
}
.min--280 {
  min-width: 250px;
}
.img--ratio-3 {
  aspect-ratio: 3;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
}
.text--title {
  color: var(--title-clr) !important;
}
.nav-tabs {
  padding-bottom: 2px;
}
.nav-tabs .nav-link {
  color: var(--title-clr);
  border-bottom: 2px solid transparent;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 10px;
}
.nav-tabs li:not(:last-child) .nav-link {
  margin-right: 25px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--primary-clr);
  background-color: transparent;
  border-color: transparent transparent var(--primary-clr);
  font-weight: 600;
  border-width: 3px;
}
.form-control {
  height: 45px;
}
.custom-file,
.custom-file-input {
  height: 45px;
}
.custom-file-input {
  opacity: 1;
  padding: 1px 0 1px 11px;
  line-height: 34px;
  border-color: var(--border-clr) !important;
}
.custom-file-input:focus,
.custom-file-input:active,
.custom-file-input:hover,
.custom-file-input:focus-within {
  border-color: var(--border-clr) !important;
}
.custom-file-label {
  height: 45px;
  line-height: 27px;
  background: 0 0;
  z-index: 9;
}
.custom-file-label::after {
  height: 43px;
  line-height: 25px;
  right: unset;
  left: 1px;
  content: "Choose File" !important;
  background: #fff;
  border: none !important;
  top: 0;
}
.w-5px,
.w--5,
.w--5px {
  width: 5px !important;
}
.w-10px,
.w--10,
.w--10px {
  width: 10px !important;
}
.w-15px,
.w--15,
.w--15px {
  width: 15px !important;
}
.w-20px,
.w--20,
.w--20px {
  width: 20px !important;
}
.w-24px,
.w--24,
.w--24px {
  width: 24px !important;
}
.w-25px,
.w--25,
.w--25px {
  width: 25px !important;
}
.w-30px,
.w--30,
.w--30px {
  width: 30px !important;
}
.w-35px,
.w--35,
.w--35px {
  width: 35px !important;
}
.w-40px,
.w--40,
.w--40px {
  width: 40px !important;
}
.w-45px,
.w--45,
.w--45px {
  width: 45px !important;
}
.w-50px,
.w--50,
.w--50px {
  width: 50px !important;
}
.w-55px,
.w--55,
.w--55px {
  width: 55px !important;
}
.w-60px,
.w--60,
.w--60px {
  width: 60px !important;
}
.w-65px,
.w--65,
.w--65px {
  width: 65px !important;
}
.w-70x,
.w--7,
.w--70x {
  width: 70px !important;
}
.w-75px,
.w--75,
.w-75px {
  width: 75px !important;
}
.w-80px,
.w--80,
.w--80px {
  width: 80px !important;
}
.w-85px,
.w--85,
.w-85px {
  width: 85px !important;
}
.w-90px,
.w--90,
.w--90px {
  width: 90px !important;
}
.w-95px,
.w--95,
.w--95px {
  width: 95px !important;
}
.w-100px,
.w-1-00,
.w--100px {
  width: 100px !important;
}
.max-w--160px {
  max-width: 160px;
}
.lang_form {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
}
.form-label {
  text-transform: capitalize;
  color: var(--title-clr);
}
.img--105 {
  border: 1px solid #d0dbe9;
  width: 105px;
  height: 105px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}
.img--50 {
  border: 1px solid #d0dbe9;
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}
.mb--n-30 {
  margin-bottom: 25px;
}
@media (min-width: 576px) {
  .mb--n-30 {
    margin-bottom: -12px;
    transform: translateY(25px);
    margin-top: -30px;
  }
}
.card--header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.card--header .card-title {
  margin-right: auto;
}
.card--header > * {
  margin: 4px;
}
.card--header form {
  width: 100%;
}
@media (min-width: 576px) {
  .card--header form {
    max-width: 400px;
  }
}
.card--header form input {
  border-color: rgba(1, 119, 205, 0.3);
  height: 40px;
}
.card--header form .input-group-append {
  background: var(--title-3);
  border-color: var(--title-3);
  border-radius: 0 5px 5px 0 !important;
  height: 40px;
}
.card--header form .input-group-append .input-group-text {
  color: #fff;
  border-color: var(--title-3);
}
.table .thead-light th {
  background: rgba(0, 197, 210, 0.05);
  border-color: rgba(0, 197, 210, 0.05);
  padding: 0.932rem 1.3125rem;
  color: #4b5460;
}
.card-header {
  padding: 1rem 1.125rem;
}
@media (max-width: 575px) {
  .card-header {
    padding: 0.875rem;
  }
}
body {
  font-family: inter, sans-serif;
}
.table td {
  padding: 0.932rem 1.3125rem;
}
.toggle-switch {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.85rem;
  text-transform: capitalize;
}
.toggle-switch .toggle-switch-label {
  width: 44px;
  height: 26px;
  display: flex;
  margin: 0;
}
.toggle-switch .toggle-switch-indicator {
  width: 18px;
  height: 18px;
}
.toggle-switch
  .toggle-switch-input:checked
  + .toggle-switch-label
  .toggle-switch-indicator {
  transform: translate3d(100%, 50%, 0) !important;
}
.toggle-switch-input:checked + .toggle-switch-label {
  background-color: #009faa;
}
.product-list-page {
}
.product-list-page .switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;
  border: 0.125rem solid transparent;
}
.product-list-page .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.product-list-page .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.product-list-page .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  transition: 0.4s;
}
.product-list-page input:checked + .slider {
  background-color: var(--primary-clr-2);
}
.product-list-page input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-clr-2);
}
.product-list-page input:checked + .slider:before {
  transform: translateX(18px);
}
.product-list-page .slider.round {
  border-radius: 34px;
}
.product-list-page .slider.round:before {
  border-radius: 50%;
}
.product-list-page #banner-image-modal .modal-content {
  width: 1116px !important;
  margin-left: -264px !important;
}
@media (max-width: 768px) {
  .product-list-page #banner-image-modal .modal-content {
    width: 698px !important;
    margin-left: -75px !important;
  }
}
@media (max-width: 375px) {
  .product-list-page #banner-image-modal .modal-content {
    width: 367px !important;
    margin-left: 0 !important;
  }
}
@media (max-width: 500px) {
  .product-list-page #banner-image-modal .modal-content {
    width: 400px !important;
    margin-left: 0 !important;
  }
}
.product-list-media {
  display: flex;
  align-items: center;
}
.product-list-media img {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(7, 59, 116, 0.2);
  border-radius: 5px;
}
.product-list-media .name {
  font-weight: 400;
  color: var(--title-clr);
  font-family: inter, sans-serif;
  width: 0;
  flex-grow: 1;
  padding-left: 10px;
  margin: 0;
  white-space: initial;
  min-width: 100px;
}
.product-list-media:hover .name {
  color: var(--hover-clr);
}
.swal2-styled.swal2-confirm {
  background-color: var(--title-3) !important;
}
.max-85 {
  max-width: 85px;
}
.max-51 {
  max-width: 51px;
}
.min-height-40 {
  min-height: 40px;
}
@media (max-width: 575px) {
  .max--sm-grow > * {
    flex-grow: 1;
  }
  .max--sm-grow .btn-primary,
  .max--sm-grow .btn--primary,
  .max--sm-grow .dropdown-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .max--sm-grow .btn-primary i,
  .max--sm-grow .btn--primary i {
    margin-right: 5px;
  }
}
.jumbotron {
  font-size: 14px;
}
.jumbotron .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
.drag--area {
  border: 2px dashed #c5d2d2;
  height: 161px;
  max-width: 682px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.drag--area.active {
  border: 2px solid var(--primary-clr);
}
.drag--area .drag-header {
  font-size: 14px;
  font-weight: 500;
}
.drag--area span {
  font-size: 25px;
  font-weight: 500;
  color: var(--title-clr);
  margin: 10px 0 15px;
}
.drag--area .icon {
  height: 40px;
  width: 40px;
  -o-object-fit: contain;
  object-fit: contain;
}
.drag--area img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}
.text--primary-2 {
  color: var(--primary-clr) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.h--172px {
  height: 172px !important;
}
.custom-select {
  height: 45px;
}
.w-200 {
  width: 200px;
}
.min-h-116px {
  min-height: 104px;
}
.product--coba .spartan_item_wrapper {
  width: 50% !important;
  max-width: 162px !important;
  height: 162px !important;
  margin-bottom: 0 !important;
}
.gy-3,
.g-3 {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
.g-3 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.customer_choice_options .form-control {
  height: 40px;
}
.spartan__close {
  position: absolute;
  right: 25px;
  top: 25px;
  color: var(--danger-clr);
}
.img-150 {
  width: 150px;
  aspect-ratio: 1;
  -o-object-fit: contain;
  object-fit: contain;
}
.input-label {
  text-transform: capitalize;
}
.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.upload--squire {
  border-radius: 10px;
  border: 2px dashed var(--border-clr);
  cursor: pointer;
  width: 150px;
  aspect-ratio: 1;
  margin: 0 auto;
}
.upload--squire img {
  width: 146px;
  height: 146px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}
.upload--vertical {
  border-radius: 10px;
  border: 2px dashed var(--border-clr);
  cursor: pointer;
  max-width: 465px;
  margin: 0 auto;
  width: 100%;
}
.upload--vertical img {
  width: 100%;
  max-height: 165px;
  -o-object-fit: cover;
  object-fit: cover;
}
.img-vertical-150 {
  width: 150px;
  aspect-ratio: 3;
  border-radius: 5px;
  -o-object-fit: cover;
  object-fit: cover;
}
textarea.h--90px {
  height: 90px;
}
.short-media {
  display: flex;
  align-items: center;
  max-width: 190px;
}
.short-media img {
  width: 50px;
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid var(--border-clr);
}
.short-media .text-cont {
  width: calc(100% - 50px);
  padding-left: 15px;
  white-space: initial;
  color: #334257;
}
a.short-media:hover {
  color: #1b7fed;
}
a.short-media:hover .text-cont {
  color: #1b7fed;
}
.store-locationmap {
  height: 100%;
  min-height: 250px;
  border-radius: 10px;
  overflow: hidden;
}
.conv-active {
  background: #f3f3f3 !important;
}
.conv--sidebar {
  overflow-y: scroll;
  max-height: 600px;
  padding: 0;
}
@media (min-width: 768px) {
  .conv--sidebar {
    min-height: 500px;
  }
}
.view-conversation {
  color: rgba(113, 120, 133, 0.62);
}
.scroll-down {
  height: 400px;
  overflow-y: scroll;
}
.conv-open-user {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.conv-open-user img {
  width: 38px;
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.conv-open-user .info {
  width: 0;
  flex-grow: 1;
  font-size: 12px;
  padding-left: 10px;
}
.conv-open-user .info .subtitle {
  font-size: 14px;
  margin: 0 0 -3px;
  text-transform: capitalize;
}
.conv-open-card .card-header {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}
.conv-open-card .form-control {
  height: 50px;
  padding: 0 20px;
  border: none;
  box-shadow: none;
}
.conv-open-card .quill-custom_ {
  border: 1px solid var(--border-clr);
  border-radius: 5px;
  height: 101px;
}
.own--text {
  background: rgba(16, 121, 128, 0.1);
  color: #121111;
  border-radius: 5px;
  margin-left: auto;
  max-width: 85%;
  padding: 10px;
  display: inline-block;
}
.own--text p:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .own--text {
    padding: 10px 20px;
    max-width: 700px;
  }
}
.__gap-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.opponents--text {
  color: #121111;
  background: rgba(133, 148, 159, 0.1);
  border-radius: 5.14528px;
  max-width: 85%;
  padding: 10px;
  display: inline-block;
}
.opponents--text p:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .opponents--text {
    padding: 10px 20px;
    max-width: 700px;
  }
}
.opponents--text .max-350px {
  width: 200px !important;
}
.conv-upload-image {
  margin-bottom: 0 !important;
}
.conv-upload-image img {
  width: 100%;
  aspect-ratio: 1;
}
.conv-upload-image .spartan_item_loader {
  aspect-ratio: 1;
}
.conv-buttons {
  position: absolute;
  top: 51px;
  right: 15px;
}
.conv-buttons button {
  height: 37px;
  font-size: 14px;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
}
.conv--sidebar--card .form-control {
  background: #f3f4f5;
  border-radius: 5px;
  height: 40px !important;
}
.w-47px {
  width: 47px !important;
}
.max-350px {
  max-width: 350px;
}
.sidebar_primary_div {
  cursor: pointer;
  padding: 8px 19px;
  position: relative;
}
.sidebar_primary_div .status {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  background: #ddd;
  top: 41px;
  left: 57px;
}
.sidebar_primary_div .status.active {
  background: #24bac3;
}
.mx-20px {
  margin-left: 20px;
  margin-right: 20px;
}
.max-150px {
  max-width: 150px;
}
.table h6,
.table h5,
.table a span {
  color: #334257;
}
.short-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  white-space: initial;
}
.short-title ~ a,
.short-title ~ span {
  font-size: 12px;
}
.max-200px {
  max-width: 200px;
  white-space: initial;
}
a:hover .short-title {
  color: #1b7fed;
}
.food--media .avatar {
  -o-object-fit: cover;
  object-fit: cover;
}
@media screen and (min-width: 992px) and (max-width: 1550px) {
  .review--information-wrapper .food--media {
    flex-wrap: nowrap !important;
  }
  .review--information-wrapper .avatar-xxl {
    width: 110px;
    margin-right: 15px !important;
  }
  .review--information-wrapper .rating--review .title {
    font-size: 36px;
    line-height: 40px;
  }
  .review--information-wrapper .rating--review .title .out-of {
    font-size: 28px;
  }
  .review--information-wrapper .rating--review .info {
    font-size: 12px;
    font-weight: 400;
  }
}
@media screen and (min-width: 1440px) {
  .review--information-wrapper .food--media .avatar {
    width: 184px;
    height: 184px;
  }
}
@media screen and (max-width: 500px) {
  .review--information-wrapper .food--media {
    flex-wrap: nowrap !important;
  }
  .review--information-wrapper .food--media .avatar ~ .d-block {
    flex-grow: 1;
  }
  .review--information-wrapper .food--media p {
    margin-bottom: 0;
  }
  .review--information-wrapper .avatar-xxl {
    width: 80px;
    height: 85px;
    margin-right: 15px !important;
  }
  .review--information-wrapper .rating--review .title {
    font-size: 30px;
    line-height: 30px;
  }
  .review--information-wrapper .rating--review .title .out-of {
    font-size: 24px;
  }
  .review--information-wrapper .rating--review .info {
    font-size: 12px;
    font-weight: 400;
  }
}
.review--information-wrapper .progress-bar {
  background: var(--title-3);
}
.rating--review .title {
  font-weight: 600;
  font-size: 50px;
  line-height: 59px;
  letter-spacing: 0.007em;
  color: var(--primary-clr);
}
.rating--review .title .out-of {
  font-size: 35px;
  line-height: 41px;
  color: #758590;
  font-weight: 500;
}
.rating--review .info {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.007em;
  color: #758590;
}
.rating--review i {
  color: var(--title-3);
  font-size: 20px;
}
@media screen and (max-width: 575px) {
  .rating--review {
    width: 100%;
  }
  .rating--review .title {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.007em;
  }
  .rating--review .title .out-of {
    font-size: 28px;
    line-height: 35px;
  }
}
.rating--review-right {
  max-width: 940px;
}
.rating--review-right li:not(:last-child) {
  padding-bottom: 10px;
}
@media screen and (max-width: 1650px) and (min-width: 1350px) {
  .rating--review-right {
    max-width: 740px;
  }
}
.progress-name {
  width: 100px;
  margin-right: 23px;
  color: var(--title-clr);
}
.resturant--information-single {
  text-align: center;
}
.resturant--information-single .avatar-img,
.resturant--information-single .avatar {
  width: 70px;
  height: 70px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}
.resturant--information-single .avatar-img {
  border: 1px solid #f8923b;
}
.resturant--information-single .media-body {
  text-align: left;
  width: calc(100% - 70px);
  font-size: 12px;
  padding-left: 23px;
}
.rating {
  color: #f8923b;
}
.max-18rem {
  max-width: 18rem;
}
.max-300 {
  max-width: 300px;
}
.text-sm {
  font-size: 14px;
}
@media (max-width: 767px) {
  .product--desc-table {
    min-width: 800px;
  }
}
.h--92px {
  min-height: 92px !important;
}
.gx--3 {
  margin-left: -9px;
  margin-right: -9px;
}
.gx--3 > * {
  padding-left: 9px;
  padding-right: 9px;
}
@media (min-width: 1200px) {
  .p-xl-30 {
    padding: 30px;
  }
}
.input-date {
  position: relative;
  margin: 0;
  display: block;
  cursor: pointer;
}
.input-date::after {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  /* background: url(./img/calendar.png) no-repeat center center/contain; */
  z-index: 1;
}
.input-label {
  color: var(--title-clr);
}
.coupon-details {
  border-radius: 10px;
  overflow: hidden;
  max-width: 522px;
}
.coupon-details .close {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
  z-index: 2;
}
.coupon__details {
  display: flex;
  position: relative;
  position: relative;
}
.coupon__details-right {
  width: 142px;
  background: #004448;
}
.coupon__details-left {
  width: 0;
  flex-grow: 1;
  padding: 30px 29px 45px;
}
.coupon__details-left .title {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--title-3);
  margin-bottom: 7px;
}
.coupon__details-left .subtitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: var(--title-3);
  margin-bottom: 9px;
}
.coupon__details-left .coupon-info {
  font-size: 12px;
  line-height: 15px;
  margin-top: 47px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--title-clr);
}
.coupon__details-left .coupon-info-item:not(:last-child) {
  margin-bottom: 10px;
}
.coupon__details .coupon {
  outline: 4px solid #93cdd0;
  width: 157px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #073b74;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 65px;
  background: #fff;
}
.coupon__details .coupon h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #073b74;
  line-height: 1;
}
@media (max-width: 575px) {
  .coupon__details-right {
    width: 120px;
  }
  .coupon__details-right .coupon {
    width: 125px;
    right: 55px;
  }
  .coupon__details-right .coupon h4 {
    font-size: 24px;
  }
}
@media (max-width: 430px) {
  .coupon__details-left {
    padding: 30px 20px 38px;
  }
  .coupon__details-right {
    width: 45px;
  }
  .coupon__details-right .coupon {
    width: 85px;
    right: 10px;
  }
  .coupon__details-right .coupon h4 {
    font-size: 22px;
    margin-bottom: 0;
  }
}
.fixed--to-right {
  position: absolute;
  right: 5px;
  top: 0;
}
.custom--switch {
  display: flex;
}
.custom--switch input[switch] {
  display: none;
}
.custom--switch input[switch] + label {
  font-size: 1em;
  line-height: 1;
  width: 56px;
  height: 24px;
  background-color: #ced4da;
  background-image: none;
  border-radius: 2rem;
  padding: 0.16667rem;
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  position: relative;
  transition: all 0.1s ease-in-out;
  margin: 0;
}
.custom--switch input[switch] + label:before {
  color: #111;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  right: 1px;
  margin: 3px;
  top: -2px;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  text-transform: uppercase;
}
.custom--switch input[switch] + label:after {
  content: "";
  position: absolute;
  left: 3px;
  background-color: #e9ecef;
  box-shadow: none;
  border-radius: 2rem;
  height: 20px;
  width: 20px;
  top: 2px;
  transition: all 0.1s ease-in-out;
  text-transform: uppercase;
}
.custom--switch input[switch]:checked + label {
  background-color: var(--primary-clr);
}
.custom--switch input[switch]:checked + label {
  background-color: var(--primary-clr);
}
.custom--switch input[switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 3px;
}
.custom--switch input[switch]:checked + label:after {
  left: 33px;
  background-color: #e9ecef;
}
.custom--switch input[switch="primary"]:checked + label {
  background-color: var(--primary-clr);
}
.form-check {
  padding-left: 30px !important;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
}
.form-check .form-check-input {
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  float: left;
  margin-left: -1.5em;
  width: 0.75rem;
  height: 0.75rem;
  margin-top: 0.35em;
}
.form-check .form-check-input[type="radio"] {
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #c4c8d4;
}
.form-check .form-check-input[type="radio"]:checked {
  background-color: var(--primary-clr);
  box-shadow: 0 0 0 3px #fff, 0 0 0 4px #c4c8d4;
}
.form-check .form-check-input[type="checkbox"] {
  background: #f4f5f7;
  box-shadow: 0 0 0 2px #f4f5f7, 0 0 0 3px #c4c8d4;
}
.form-check .form-check-input[type="checkbox"]:checked {
  /* background: url("./img/check-mark.png") #f4f5f7 no-repeat center
    center/contain; */
  box-shadow: 0 0 0 3px #f4f5f7, 0 0 0 4px #c4c8d4;
}
.form-check label::after {
  position: absolute;
  inset: 0;
  z-index: 3;
  content: "";
}
.h--36px {
  height: 36px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.payment--gateway-img {
  margin-bottom: 30px;
  margin-top: 14px;
}
.payment--gateway-img img {
  width: 100%;
  height: 24px;
  -o-object-fit: contain;
  object-fit: contain;
}
.switch--custom-label-text {
  display: none;
  font-weight: 700;
  font-size: 0.75rem;
}
.switch--custom-label.checked .switch--custom-label-text.on {
  display: block;
}
.switch--custom-label:not(.checked) .switch--custom-label-text.off {
  display: block;
}
.check--item-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 30px -5px -30px 0;
}
.check-item {
  width: 50%;
  max-width: 248px;
  padding: 0 5px 30px;
  text-transform: capitalize;
}
.check-item .form-check-input {
  margin-left: 0;
  left: 0;
}
.check-item .form-check {
  margin: 0;
}
@media screen and (max-width: 1650px) {
  .check-item {
    font-size: 12px;
    line-height: 2;
    max-width: 215px;
  }
}
@media screen and (max-width: 767px) {
  .check-item {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .check-item {
    max-width: 200px;
  }
}
@media screen and (max-width: 400px) {
  .check-item {
    max-width: 180px;
  }
}
@media screen and (max-width: 375px) {
  .check-item {
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .check--item-wrapper {
    margin-bottom: -15px;
  }
  .check--item-wrapper .check-item {
    padding-bottom: 15px;
  }
}
.max-w-500px {
  max-width: 500px;
}
.max-w-300px {
  max-width: 300px;
}
.table--media {
  display: flex;
  align-items: center;
  color: var(--body-clr);
}
.table--media img {
  width: 50px;
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid var(--border-clr);
}
.table--media-body {
  width: calc(100% - 50px);
  padding-left: 11px;
}
.table--media-body .title {
  font-weight: 600;
}
.rounded-full {
  border-radius: 50% !important;
}
.table-align-middle td {
  vertical-align: middle;
}
.text-hover {
  color: var(--title-clr);
  transition: all ease 0.3s;
}
.text-hover:hover {
  color: var(--primary-clr);
}
.text-14px {
  font-size: 14px;
}
.text-14px .badge {
  font-size: 14px;
}
.initial-24 {
  height: 137px;
  width: 137px;
  border: 1px solid #99a7ba;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}
.product--coba .two__item {
  max-width: 50% !important;
}
.product--coba .two__item img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.existing-item {
  border-radius: 5px;
  border: 2px dashed var(--border-clr);
  overflow: hidden;
}
.existing-item img {
  height: 140px;
  width: 100%;
}
.max-h-140px {
  height: 140px;
}
@media (max-width: 575px) {
  .card--header > * {
    flex-grow: 1;
    text-align: center;
    justify-content: center;
  }
  .card--header > * .export-btn {
    text-align: center;
    justify-content: center;
  }
  .card--header .card-title {
    text-align: left;
  }
}
.custom-file-input {
  display: block !important;
}
.resturant-card {
  position: relative;
  border-radius: 10px;
  padding: 21px 50px 21px 28px;
}
.resturant-card .title {
  font-size: 1.375rem;
  font-weight: 700;
  margin: 0;
  margin-bottom: 5px;
}
.resturant-card .subtitle {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .resturant-card .subtitle {
    font-size: 12px;
  }
}
.resturant-card .resturant-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  max-width: 30px;
  height: 35px;
  -o-object-fit: contain;
  object-fit: contain;
}
.resturant-card .resturant-icon img {
  max-width: 100%;
}
@media screen and (min-width: 1450px) {
  .resturant-card {
    padding: 30px 55px 25px 30px;
  }
  .resturant-card .resturant-icon {
    right: 20px;
    top: 25px;
  }
}
.resturant-card.dashboard--card {
  min-height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.resturant-card.dashboard--card .subtitle {
  color: var(--title-clr);
}
.bg--1 {
  background: rgba(0, 150, 255, 0.15) !important;
}
.bg--2 {
  background: rgba(0, 170, 109, 0.15) !important;
}
.bg--3 {
  background: rgba(248, 59, 59, 0.1) !important;
}
.initial-22 {
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
  height: unset;
}
.rating--review-right {
  max-width: 940px;
}
.rating--review-right .progress-bar {
  background: var(--primary-clr);
}
.rating--review-right li:not(:last-child) {
  padding-bottom: 10px;
}
@media screen and (max-width: 1650px) and (min-width: 1350px) {
  .rating--review-right {
    max-width: 740px;
  }
}
.w-18rem {
  width: 18rem;
}
.badge-soft-primary {
  background: rgba(0, 197, 210, 0.05);
  color: #107980;
}
.quick--address-bar {
  display: flex;
}
.quick--address-bar .quick-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 50%;
}
.quick--address-bar .info {
  width: 0;
  flex-grow: 1;
  padding-left: 13px;
  font-size: 0.75rem;
}
.quick--address-bar .info h6 {
  text-transform: capitalize;
  margin: 0;
}
.w-120px {
  width: 120px;
}
.input-date-group {
  position: relative;
}
.input-date-group .input-label {
  position: absolute;
  padding: 0 15px 0 4px;
  background: #fff;
  left: 7px;
  top: -10px;
  z-index: 2;
  font-size: 12px;
}
.input-date-group .input-date {
  position: relative;
  z-index: 1;
}
.min-h-45px {
  min-height: 45px;
}
.order-top {
  padding: 20px 15px;
  background: #fcfcfc;
  border-radius: 5px 5px 0 0;
}
.text-0661CB {
  color: #0661cb !important;
}
.text-107980 {
  color: #107980 !important;
}
.text-00B2BE {
  color: #00b2be !important;
}
.table .badge {
  padding: 8px 8px 6px !important;
  min-width: 72px;
}
.mw-90 {
  max-width: 90px;
  text-align: right;
}
.mw-85 {
  max-width: 85px;
  text-align: right;
}
.alert--danger {
  background: rgba(255, 109, 109, 0.05) !important;
  border: 1px solid rgba(255, 109, 109, 0.5);
  border-left: 4px solid #ff6d6d;
  border-radius: 0 5px 5px 0;
  color: var(--title-clr);
  padding-top: 22px;
  padding-bottom: 25px;
}
.alert--danger .alert--icon {
  font-size: 22px;
  color: #ff6d6d;
  line-height: 1;
  margin-right: 5px;
}
.clean--database-checkgroup {
  margin-bottom: -25px;
}
.clean--database-checkgroup .check-item {
  text-transform: capitalize;
  padding-bottom: 25px;
}
@media screen and (min-width: 768px) {
  .clean--database-checkgroup .check-item {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
.clean--database-checkgroup .check-item .badge-secondary {
  color: var(--title-clr);
  background: #f3f4f5;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  min-width: 44px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clean--database-checkgroup .check-item .form-check-input {
  margin: 0;
  margin-top: 0.6em;
  left: 4px;
}
.clean--database-checkgroup .check-item .form-check-label {
  max-width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  word-break: break-all;
}
.border-primary-70 {
  border-color: rgba(16, 121, 128, 0.7) !important;
}
.w--17 {
  width: 17px;
}
.switch--label {
  width: 0;
  flex-grow: 1;
}
body *::-webkit-scrollbar-track {
  width: 4px;
  height: 4px;
}
body *::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
body *::-webkit-scrollbar-thumb {
  width: 4px;
  height: 4px;
}
.page-header {
  border-bottom: none;
  padding-bottom: 1rem;
  margin-bottom: 0;
}
.initial-38-1 {
  max-width: 404px !important;
  margin: 0 auto !important;
  padding-left: 15px;
  padding-right: 15px;
}
@media print {
  .initial-38-1 {
    margin: 0 !important;
  }
}
.initial-38-1 * {
  color: #000 !important;
  font-family: roboto mono, monospace !important;
}
.initial-38-1 > .pt-3:first-child {
  padding-top: 0 !important;
}
.initial-38-2 {
  width: 100%;
  height: 70px;
  -o-object-fit: contain;
  object-fit: contain;
}
.initial-38-3 {
  line-height: 1;
}
.initial-38-4 {
  font-size: 16px;
  font-weight: lighter;
}
.initial-38-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: nowrap;
}
.initial-38-6 {
  width: 10%;
  padding: 0.45rem 0.65rem;
  border-bottom: none;
}
.initial-38-7 {
  padding: 0.45rem 0.65rem;
  border-bottom: none;
}
.initial-38-9 {
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}
.initial-38-10 {
  font-size: 12px;
  font-weight: lighter;
  line-height: 1;
}
.initial-38 #printableArea div,
.initial-38 #printableArea span,
.initial-38 #printableArea i,
.initial-38 #printableArea u,
.initial-38 #printableArea b,
.initial-38 #printableArea strong,
.initial-38 #printableArea dt,
.initial-38 #printableArea dd {
  color: #000 !important;
  font-family: roboto mono, monospace !important;
}
@media print {
  .non-printable {
    display: none;
  }
  .printable {
    display: block;
  }
}
@page {
  size: auto;
  margin: 2px;
  font-family: roboto mono, monospace !important;
}
.select2-selection__rendered span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
.empty--data {
  text-align: center;
  padding: 20px;
}
.empty--data img {
  max-width: 145px;
  margin-bottom: 20px;
}
.empty--data h5 {
  text-transform: capitalize;
}
.font-20px {
  font-size: 20px;
}
.flex-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.word-nobreak {
  white-space: nowrap;
}
.word-break {
  white-space: initial;
}
.btn-35px {
  transition: all ease 0.3s;
  padding: 7px 12px !important;
  font-weight: 400 !important;
}
.dropdown-toggle::after {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='%2300c5d2' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E")
    no-repeat right center/1rem 1rem;
}
.w-260px {
  width: 260px;
}
.min-w-980px {
  min-width: 920px;
}
.bg--4 {
  background: rgba(248, 146, 59, 0.1) !important;
}
.h-18rem {
  height: 18rem;
}
.initial-block {
  display: block;
}
.add--customer-btn .btn,
.add--customer-btn .select2 {
  width: unset;
}
.add--customer-btn .select2 {
  width: calc(100% - 200px) !important;
}
.add--customer-btn .btn {
  margin-left: 15px;
  width: 180px;
  padding-left: 5px;
  padding-right: 5px;
}
@media screen and (max-width: 575px) {
  .add--customer-btn .btn,
  .add--customer-btn .select2 {
    width: 100% !important;
    margin: 0;
  }
  .add--customer-btn .btn {
    margin-top: 10px !important;
  }
}
.add--customer-btn .select2-container--default .select2-selection--single {
  height: 45px !important;
}
.order--item-box {
  height: unset !important;
  max-width: 170px;
  width: 100% !important;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .order--item-box {
    max-width: 33.3333333333% !important;
    width: 33.3333333333% !important;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1650px) {
  .order--item-box {
    max-width: 25% !important;
    width: 25% !important;
  }
}
@media screen and (min-width: 1651px) and (max-width: 1850px) {
  .order--item-box {
    max-width: 20% !important;
    width: 20% !important;
  }
}
@media screen and (min-width: 1851px) and (max-width: 2000px) {
  .order--item-box {
    max-width: 16.6666666667% !important;
    width: 16.6666666667% !important;
  }
}
@media screen and (max-width: 575px) {
  .order--item-box {
    max-width: 50% !important;
  }
}
@media screen and (min-width: 2001px) {
  .order--item-box {
    width: 170px !important;
    max-width: 182px;
    flex-grow: 1;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .order--item-box {
    width: 20% !important;
    max-width: 20%;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .order--item-box {
    max-width: 33.3333333333% !important;
    width: 33.3333333333% !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .order--item-box {
    width: 16.6666666667% !important;
    max-width: 16.6666666667%;
  }
}
@media screen and (max-width: 575px) {
  .row .order--item-box[class*="col"] {
    padding: 5px !important;
  }
}
.order--pos-left {
  width: calc(100% - 500px);
  margin-right: auto;
}
.order--pos-left .select2-container--default .select2-selection--single {
  height: 45px !important;
}
.order--pos-right {
  width: 100%;
  max-width: 475px;
}
.order--pos-right .select2-selection__placeholder {
  line-height: 45px;
}
.order--pos-right
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 9px;
}
.order--pos-right .select2-container--default .select2-selection--single {
  height: 45px !important;
}
.order--pos-right .select2-selection__rendered {
  line-height: 43px !important;
}
.order--pos-right dt {
  font-weight: 400;
  color: var(--body-clr);
}
@media (min-width: 992px) and (max-width: 1450px) {
  .order--pos-left {
    width: calc(100% - 395px);
  }
  .order--pos-right {
    max-width: 375px;
  }
}
@media screen and (min-width: 1650px) {
  .order--pos-left {
    width: calc(100% - 540px);
  }
  .order--pos-right {
    max-width: 520px;
  }
}
@media screen and (max-width: 1200px) {
  .order--pos-left {
    width: 100%;
    margin-bottom: 15px;
  }
  .order--pos-right {
    max-width: 100%;
  }
}
.cart--media .avatar {
  width: 50px;
  height: 50px;
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
}
.button--bottom-fixed {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding-top: 14px;
}
.table--vertical-middle td {
  vertical-align: middle !important;
}
.pos--payment-options ul {
  display: flex;
  flex-wrap: wrap;
  margin: -2.5px;
  padding: 0;
}
.pos--payment-options ul li {
  list-style: none;
  padding: 2.5px;
}
.pos--payment-options ul li label {
  margin: 0;
}
.pos--payment-options ul li label span {
  color: var(--title-clr);
  border: 1px solid #d0dbe9;
  border-radius: 3px;
  text-transform: capitalize;
  padding: 8px 12px;
  cursor: pointer;
}
.pos--payment-options ul li label input:checked ~ span {
  color: #fff;
  background: var(--title-clr);
  border-color: var(--title-clr);
}
.pos--payable-amount {
  color: var(--title-clr);
}
.pos--payable-amount .text-body {
  opacity: 0.8;
  margin-right: 10px;
  width: 25px;
  text-align: center;
  cursor: pointer;
}
.pos--delivery-options {
  padding: 9px 12px 19px;
}
.pos--delivery-options .delivery--edit-icon {
  width: 25px;
  text-align: center;
  cursor: pointer;
}
.pos--delivery-options .card-title {
  font-size: 16px;
  margin-bottom: 12px;
}
.pos--delivery-options .card-title i {
  font-size: 18px;
}
.pos--delivery-options-info {
  margin: 0 -5px -15px;
}
.pos--delivery-options-info ul,
.pos--delivery-options-info > .location {
  padding: 0 5px 15px;
  margin: 0;
}
.pos--delivery-options-info ul {
  width: 100%;
  max-width: 200px;
  font-size: 12px;
}
.pos--delivery-options-info ul li {
  list-style: none;
  display: flex;
  text-transform: capitalize;
}
.pos--delivery-options-info ul li span {
  width: 60px;
  display: flex;
  justify-content: space-between;
}
.pos--delivery-options-info ul li span::after {
  content: ":";
}
.pos--delivery-options-info ul li:not(:last-child) {
  margin-bottom: 5px;
}
.pos--delivery-options-info ul li strong {
  color: var(--title-clr);
  width: calc(100% - 60px);
  padding-left: 15px;
}
.pos--delivery-options-info .location {
  font-size: 12px;
  font-weight: 400;
  width: 200px;
  flex-grow: 1;
  display: flex;
}
.pos--delivery-options-info .location i {
  font-size: 24px;
  color: var(--title-clr);
  margin-right: 5px;
}
.delivery--method-radio {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.delivery--method-radio li {
  list-style: none;
}
.delivery--method-radio li label {
  margin: 0;
}
.delivery--method-radio li span {
  text-transform: capitalize;
  border-radius: 5px;
  padding: 10px 22px;
  color: #a2adbb;
  font-weight: 700;
  cursor: pointer;
}
.delivery--method-radio li span i {
  margin-right: 5px;
}
.delivery--method-radio li input:checked ~ span {
  background: #ff8200;
  color: #fff;
}
.search--no-found {
  max-width: 376px;
  text-align: center;
  margin: 30px auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #99a7ba;
}
.search--no-found img {
  width: 66px;
  height: 66px;
  -o-object-fit: contain;
  object-fit: contain;
  margin: 0 auto 20px;
}
.product-card {
  cursor: pointer;
}
.product-card .product-price .text-accent {
  color: #ff8200 !important;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.choice-input {
  width: 7rem;
}
.addon-input {
  height: 7rem;
  width: 7rem;
}
.addon-quantity-input {
  height: 2rem;
  width: 7rem;
  z-index: 9;
  bottom: 1rem;
  visibility: hidden;
}
.check-label {
  background-color: #f3f3f3;
  color: #000;
  border-width: 2px;
  border-color: #babfc4;
  font-weight: 700;
}
.btn-check:checked + .check-label {
  background-color: #0c6970;
  color: #fff;
  border: none;
}
.h-200 {
  height: 200px;
}
@media screen and (min-width: 1200px) {
  .order-print-area-left {
    max-width: calc(100% - 360px) !important;
    flex: 0 0 100%;
  }
  .order-print-area-left.col-lg-12 {
    max-width: 100% !important;
  }
  .order-print-area-right {
    width: 360px !important;
    max-width: 360px !important;
    flex-grow: 0;
  }
}
@media screen and (min-width: 1460px) {
  .order-print-area-left {
    max-width: calc(100% - 450px) !important;
  }
  .order-print-area-left.col-lg-12 {
    max-width: 100% !important;
  }
  .order-print-area-right {
    width: 450px !important;
    max-width: 450px !important;
    flex: 0 0 100%;
  }
}
.delivery--information-single {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  gap: 4px;
}
.delivery--information-single > span {
  display: block;
}
.delivery--information-single .name {
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delivery--information-single .name::after {
  content: ":";
  display: inline-block;
}
.delivery--information-single .info {
  width: calc(100% - 60px);
  padding-left: 20px;
  font-weight: 500;
  color: var(--title-clr);
}
.customer--information-single {
  align-content: inherit;
}
.customer--information-single .avatar-img,
.customer--information-single .avatar {
  width: 70px;
  height: 70px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.customer--information-single .media-body {
  text-align: left;
  width: calc(100% - 70px);
  font-size: 12px;
  padding-left: 23px;
}
.resturant--information-single {
  align-content: inherit;
}
.resturant--information-single .avatar-img,
.resturant--information-single .avatar {
  width: 70px;
  height: 70px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}
.resturant--information-single .avatar-img {
  border: 1px solid var(--base-clr);
}
.resturant--information-single .media-body {
  text-align: left;
  width: calc(100% - 70px);
  font-size: 12px;
  padding-left: 23px;
}
.order-invoice-right-contents h6 span:not(.badge) {
  font-weight: 400;
  color: var(--title-clr);
  font-size: 12px;
}
.order-invoice-right-contents h6 strong {
  color: var(--title-clr);
  font-weight: 600;
}
.order-invoice-left {
  font-size: 12px;
}
.order-invoice-left .btn--sm {
  font-size: 12px;
  padding: 5px 10px;
}
.order-invoice-left,
.order-invoice-right {
  max-width: 50%;
}
@media screen and (max-width: 575px) {
  .order-invoice-left,
  .order-invoice-right {
    max-width: 100%;
    width: 100%;
  }
}
.order--note {
  max-width: 540px;
}
.add--referal {
  font-size: 12px !important;
  padding: 4px 10px !important;
}
.line--limit-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 767px) {
  .media--sm {
    align-items: center;
  }
  .media--sm .avatar {
    width: 68px !important;
    height: 68px !important;
    margin-right: 10px !important;
  }
  .media--sm .media-body {
    width: calc(100% - 80px);
  }
  .media--sm .media-body .line--limit-1 {
    font-size: 0.75rem;
  }
  .media--sm .media-body .font-size-sm {
    font-size: 0.7rem;
  }
}
.media--sm .avatar img {
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
}
.w-0 {
  width: 0;
}
.flex-grow {
  flex-grow: 1;
}
.td-p-0 {
  padding: 0 !important;
}
.aspect-ratio-1 {
  aspect-ratio: 1;
}
.aspect-ratio-80 {
  aspect-ratio: 1.2;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.modal--media {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal--media-avatar {
  width: 120px;
}
.modal--media-avatar img {
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
}
.modal--media .details {
  padding-left: 20px;
  width: calc(100% - 120px);
}
.order--pos-right .select2-container--default .select2-selection--single {
  border-color: var(--border-clr);
}
.cart--table-scroll {
  max-height: 300px;
  overflow-y: auto;
}
.select2-selection--single {
  height: 100%;
}
.select2-selection__rendered {
  word-wrap: break-word !important;
  text-overflow: inherit !important;
  white-space: normal !important;
}
.middle-align {
  vertical-align: middle !important;
}
td .amount--input {
  width: 50px !important;
  height: 30px !important;
  margin: 0 auto;
  padding: 0;
}
@media (min-width: 1550px) {
  td .amount--input {
    width: 70px !important;
    height: 35px !important;
  }
}
.rounded-full {
  border-radius: 50%;
}
.order--pos-right .table td {
  padding: 0.875rem 0.7rem;
}
.order-select-btn-grp .form-control,
.order-select-btn-grp .select2-container {
  width: 100px !important;
  flex-grow: 1;
}
.order-select-btn-grp .btn {
  height: 35px;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
}
.order--pos-right .order-select-btn-grp .form-control,
.order--pos-right
  .order-select-btn-grp
  .select2-container--default
  .select2-selection--single {
  height: 35px !important;
}
.order--pos-right .order-select-btn-grp .select2-selection__rendered {
  line-height: 33px !important;
}
.order--pos-right
  .order-select-btn-grp
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  top: 37%;
}
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.table-responsive::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #000;
}
.modal-title {
  text-transform: capitalize;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 992px) and (max-width: 1459px) {
  .custom-select.custom-select.time_slote,
  .js-flatpickr.flatpickr-custom {
    font-size: 12px;
  }
}
.max-w-130px {
  max-width: 130px;
}
.tio-input-search {
  position: absolute;
  top: 13px;
  left: 9px;
  z-index: 9;
}
.pagination {
  flex-wrap: wrap;
  justify-content: flex-end;
}
.__select-attr .select2-container--default .select2-selection--multiple {
  padding: 0 1.275rem 0 0;
}
.__select-attr
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 6px;
  gap: 6px;
  display: flex;
  flex-wrap: wrap;
}
.__select-attr
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  * {
  margin: 0 !important;
}
.__select-attr
  .select2-container--default
  .select2-selection--multiple
  .select2-search--inline
  .select2-search__field {
  height: 28px !important;
}
.coupon__details-left {
  border-right: 12px solid #93cdd0;
}
.coupon__details-right {
  border-radius: 0 10px 10px 0;
}
.__img-120 {
  width: 120px;
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 8px;
}
.__descripiton-txt {
  position: relative;
  padding-bottom: 24px;
}
.__descripiton-txt.__not-first-hidden {
  margin-bottom: 25px;
  min-height: 86px;
  max-height: 110px;
  overflow: hidden;
  padding-bottom: 0;
}
.__descripiton-txt.__not-first-hidden .show-more {
  top: 73px;
  padding-top: 15px;
}
.__descripiton-txt.__not-first-hidden .show-more span {
  cursor: pointer;
}
.__descripiton-txt .show-more {
  display: block !important;
  font-size: 14px;
  text-transform: capitalize;
  position: absolute;
  top: calc(100% - 23px);
  width: 100%;
  left: 0;
  background: linear-gradient(0deg, #ffffff 15%, transparent 100%);
  background: -o-linear-gradient(0deg, #ffffff 15%, transparent 100%);
}
.__descripiton-txt .show-more span {
  cursor: pointer;
}
@media (1200px < width < 1600px) {
  .order-invoice-right .btn--container .btn {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
}
@media (min-width: 768px) {
  .rounded-left-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rounded-right-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.__right-eye {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
aside .navbar-nav > .nav-item.mt-0:not(:first-child),
aside .navbar-nav > .nav-item.mt-0 {
  margin-top: 0 !important;
}
.min-w-120px {
  min-width: 120px;
}
.min-w-160px {
  min-width: 160px;
}
.__choose-input {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  max-width: 457px;
  width: 100%;
}
.__choose-input .form-control {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background: 0 0;
  margin-left: -108px;
  height: 100% !important;
  padding-top: 0 !important;
}
.__choose-input::after {
  inset: 0;
  z-index: 1;
  content: "";
  position: absolute;
}
.__social-media-login {
  border: none;
  box-shadow: 0 0 10px rgba(51, 66, 87, 0.1);
  border-radius: 10px;
}
.__social-media-login-icon {
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  aspect-ratio: 1;
  -webkit-margin-end: 15px;
  margin-inline-end: 15px;
}
.__social-media-login-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.__social-media-login .sub-txt {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(91, 103, 119, 0.5);
}
@media (max-width: 575px) {
  .__social-media-login .sub-txt {
    font-size: 14px;
  }
}
.__social-media-login .switch--right {
  margin-left: auto;
}
@media (min-width: 768px) and (max-width: 900px) {
  .__social-media-login .btn--container .btn {
    font-size: 14px;
    font-size: 13px;
    padding: 5px 15px;
  }
  .__social-media-login .btn--container .btn.btn--primary {
    min-width: 100px;
  }
}
@media (max-width: 575px) {
  .__social-media-login .btn--container .btn {
    flex-grow: 1;
  }
}
.__input-grp {
  position: relative;
}
.__input-grp .form-control {
  padding-right: 45px;
}
@media (min-width: 576px) {
  .__input-grp .form-control {
    padding-right: 130px;
  }
}
.__input-grp .btn {
  position: absolute;
  height: 35px;
  top: 5px;
  right: 5px;
  padding: 0 15px;
  text-transform: uppercase;
}
@media (max-width: 575px) {
  .__input-grp .btn {
    width: 35px;
    padding: 0;
  }
}
.btn--primary-fades {
  background: rgba(0, 197, 210, 0.05) !important;
  border: 1px solid rgba(0, 197, 210, 0.3) !important;
  color: #107980 !important;
}
.__btn-row {
  display: flex;
  gap: 10px;
}
.__btn-row * {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575px) {
  .__btn-row {
    flex-wrap: wrap;
  }
}
.js-hs-unfold-invoker.btn-outline-primary-2 {
  justify-content: center;
}
.export-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 25px;
  flex-grow: 1;
}
.export-steps.style-2 .export-steps-item {
  width: 33.3333333333%;
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .export-steps.style-2 .export-steps-item {
    width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .export-steps.style-2 .export-steps-item {
    width: 100%;
  }
}
.export-steps-item {
  color: #8a92a6;
  padding: 5px;
  width: 50%;
  max-width: 400px;
}
.export-steps-item h5 {
  color: #8a92a6;
  margin: 0;
  margin-bottom: -5px;
  font-size: 18px;
  letter-spacing: 0.05em;
  font-weight: 500;
}
.export-steps-item .inner {
  background: #f7f7f7;
  padding: 15px;
  text-align: center;
  min-height: 57px;
  height: 100%;
}
.export-steps-item .inner p {
  font-size: 14px;
  margin: 0;
  margin-top: 10px;
}
@media screen and (max-width: 575px) {
  .export-steps-item {
    width: 100%;
  }
}
.export--template-btns .btn {
  width: 100%;
  max-width: 274px;
}
.export--template-title {
  letter-spacing: 0.05em;
}
.rating {
  color: var(--primary-clr);
}
.modal--media-avatar img {
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
}
.__see-more-txt-item {
  max-width: 340px;
  position: relative;
}
.__see-more-txt-item .text-right {
  transform: translateY(-22px);
}
.__see-more-txt-item .text-right span {
  cursor: pointer;
  transform: translateY(-19px);
  background: #fff;
}
.__language-card .dataTables_length {
  display: none;
}
.__language-card .dataTables_filter label {
  display: inline-flex;
  flex-direction: row-reverse;
  background: var(--title-3);
  color: #fff;
  position: relative;
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  padding-right: 1em;
}
.__language-card .dataTables_filter label::before {
  content: "";
  background: var(--title-3);
  position: absolute;
  left: calc(100% - 17px);
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 0 5px 5px 0;
}
.__language-card .dataTables_filter label input {
  border: none;
  outline: none;
  box-shadow: none;
  margin-left: 0 !important;
  margin-right: 1em;
  height: 40px;
  color: #000;
  border-radius: 5px 0 0 5px;
  border: 1px solid var(--title-3);
  padding: 0 15px;
}
.__language-card .table-responsive {
  padding-bottom: 15px;
}
@media (min-width: 576px) {
  .__language-card .dataTables_info {
    float: left;
    padding-top: 18px !important;
  }
  .__language-card .dataTables_paginate {
    float: right;
    padding-top: 18px;
  }
}
.__language-card td:nth-child(2) {
  vertical-align: middle;
}
.__language-card td:nth-child(2) label {
  margin-bottom: 0;
}
.__language-card .dataTables_paginate {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 19px;
}
.__language-card .dataTables_paginate span {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}
.__language-card .dataTables_paginate span a {
  padding: 0 4px;
}
.__language-card .dataTables_paginate a {
  cursor: pointer;
}
.__empty-table {
  text-align: center;
  margin-top: 20px;
}
.__empty-table img {
  max-width: 320px;
  width: 100%;
}
.btn--reset {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.auth-wrapper * {
  font-family: roboto, sans-serif;
}
.auth-wrapper-right {
  width: 100%;
  max-width: 660px;
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.auth-wrapper-right .auth-wrapper-form {
  max-width: 452px;
  width: 100%;
  margin: 0 auto;
}

.auth-wrapper-form .pharmacy-logo{
  max-height: 100px;
  margin-bottom: 70px;
  display: none;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 769px){
  .auth-wrapper-form .pharmacy-logo{
    display: block;
  }
}

@media (min-width: 1550px) {
  .auth-wrapper-right .auth-wrapper-form {
    transform: translateX(-30px);
  }
}
.auth-wrapper-right .auth-header {
  font-size: 20px;
  line-height: 1.4;
  color: #a0a0a0;
  margin-bottom: 60px;
  font-weight: 300;
}
.auth-wrapper-right .auth-header a {
  font-weight: 700;
  text-decoration: underline;
  color: #01684b;
}
.auth-wrapper-right .auth-header .title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.auth-wrapper-right .auth-header .badge {
  font-weight: 400;
  font-size: 14px;
  color: #01684b;
  background-color: rgba(1, 104, 75, 0.1);
  border-radius: 15px;
  padding: 7px 13px 6px;
}
.auth-wrapper-right .auto-fill-data-copy {
  padding-top: 26px;
}
.auth-wrapper-right .auto-fill-data-copy .action-btn {
  width: 45px;
  height: 45px;
}
.auth-wrapper-right .btn--primary {
  background: #01684b;
  font-weight: 500;
}
.auth-wrapper-right .btn-block {
  height: 45px !important;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 10px;
}
@media (min-width: 1550px) {
  .auth-wrapper-right {
    max-width: 718px;
  }
  .auth-wrapper-right .input-label {
    margin-bottom: 12px;
  }
  .auth-wrapper-right .form-group {
    margin-bottom: 35px;
  }
  .auth-wrapper-right .auto-fill-data-copy {
    color: #9b9b9b;
    font-size: 18px;
  }
  .auth-wrapper-right .auto-fill-data-copy strong {
    color: #111;
  }
  .auth-wrapper-right .btn-block,
  .auth-wrapper-right .form-control {
    height: 45px !important;
    border-radius: 10px;
  }
  .auth-wrapper-right .btn-block:not(.btn-white) {
    font-size: 20px;
  }
  .auth-wrapper-right .auto-fill-data-copy .action-btn {
    width: 45px;
    height: 45px;
    border-radius: 10px;
  }
  .auth-wrapper-right .auto-fill-data-copy .action-btn i {
    font-size: 24px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .auth-wrapper-right .custom-control-label::after,
  .auth-wrapper-right .custom-control-label::before {
    top: 0.1rem;
  }
}
.auth-wrapper-left {
  width: 0;
  flex-grow: 1;
  background: url(../assets/img/auth-bg.jpg) no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.auth-wrapper-left .auth-left-cont {
  margin-left: auto;
  margin-right: 172px;
}
.auth-wrapper-left .auth-left-cont img {
  max-width: 388px;
  width: 70%;
  max-height: 120px;
  margin-bottom: 20px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left center;
  object-position: left center;
}
@media (min-width: 1600px) {
  .auth-wrapper-left .auth-left-cont img {
    width: 100%;
  }
}
.auth-wrapper-left .auth-left-cont .title {
  font-size: 70px;
  line-height: 1.3;
  font-weight: 300;
}
.auth-wrapper-left .auth-left-cont .title strong {
  font-weight: 700;
}
@media (max-width: 1549px) {
  .auth-wrapper-left .auth-left-cont {
    margin-right: 134px;
  }
  .auth-wrapper-left .auth-left-cont .title {
    font-size: 55px;
  }
  .auth-wrapper-right .auth-header {
    font-size: 16px;
    margin-bottom: 35px;
  }
  .auth-wrapper-right .auth-header .title {
    font-size: 36px;
    margin-bottom: 0;
  }
  .auth-wrapper-right .auth-header .badge {
    margin-top: 10px;
  }
  .auth-wrapper .mt-xxl-3 {
    margin-top: 0 !important;
  }
  .auth-wrapper-right .btn--primary {
    height: 38px;
  }
}
@media (max-width: 1300px) {
  .auth-wrapper-left .auth-left-cont {
    margin-right: 84px;
  }
  .auth-wrapper-left .auth-left-cont .title {
    font-size: 45px;
  }
  .auth-wrapper-right {
    max-width: 560px;
  }
  .auth-wrapper-right .auth-header {
    font-size: 14px;
    margin-bottom: 1rem;
  }
  .auth-wrapper-right .auth-header .title {
    font-size: 28px;
  }
  .auth-wrapper-right .auth-header .badge {
    font-size: 11px;
  }
  .auth-wrapper-right .form-group {
    margin-bottom: 1rem;
  }
  .auth-wrapper-right .form-control {
    height: 38px;
    font-size: 12px;
  }
  .auth-wrapper-right .btn-block {
    height: 38px !important;
  }
  .auth-wrapper-right .custom-control-label {
    font-size: 12px;
  }
  .auth-wrapper-right .auto-fill-data-copy {
    padding-top: 10px;
  }
  .auth-wrapper-right .auto-fill-data-copy .action-btn {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 1199px) {
  .auth-wrapper-right {
    max-width: 450px;
    padding: 20px 40px;
  }
}
.auth-wrapper-right-logo {
  display: none;
}
@media (max-width: 991px) {
  .auth-wrapper-left {
    display: none;
  }
  .auth-wrapper-right-logo {
    display: block;
    width: 70%;
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .auth-wrapper-right-logo img {
    width: 100%;
    max-height: 70px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center center;
    object-position: center center;
  }
  .auth-wrapper-right {
    padding: 25px 40px;
    max-width: 100%;
    text-align: center;
  }
  .auth-wrapper-right .form-group {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .auth-wrapper-right {
    padding: 15px 30px;
    max-width: 100%;
  }
}
.text--039D55 {
  color: #01684b !important;
}
@media (min-width: 1650px) {
  .vendor-login .auth-wrapper-right .auth-header {
    font-size: 20px;
  }
  .vendor-login .auth-wrapper-right .auth-header .title {
    font-size: 36px;
  }
}
.toggle-login {
  text-align: center;
  margin-top: 15px;
}
.toggle-login .toggle-login-btn {
  cursor: pointer;
  color: #01684b;
}
.rating.text-info {
  color: var(--title-3) !important;
}
.__middle-align td {
  vertical-align: middle;
}
.d-flex.form-control.border {
  height: unset;
  min-height: 45px;
}
.product-card.card {
  height: 100%;
}
.product-card.card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
body:not(.navbar-vertical-aside-closed-mode)
  .navbar-vertical-aside-mobile-overlay,
body:not(.navbar-vertical-aside-closed-mode)
  .navbar-vertical-aside-sub-menu-overlay {
  min-width: 200vw !important;
  min-height: 200vh !important;
}
#location_map_div {
  min-height: 200px;
}
input.non-printable.btn-primary {
  margin: 5px 0;
}
input.non-printable.btn-primary ~ .non-printable {
  margin: 5px 0;
}
.store-report-content {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}
.store-report-content .left-content,
.store-report-content .right-content {
  width: 265px;
  flex-grow: 1;
}
@media (min-width: 1500px) {
  .store-report-content .left-content.expense--content {
    max-width: 450px;
  }
}
.store-report-content .center-chart-area {
  flex-grow: 1;
  width: 520px;
  padding: 24px;
  background: #fff;
  box-shadow: 0 10px 13px rgba(17, 38, 146, 0.05);
  border-radius: 8px;
}
.store-report-content .center-chart-area .center-chart-header {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.store-report-content .center-chart-area .center-chart-header .title {
  font-weight: 500;
  font-size: 19px;
  line-height: 175%;
  color: #232d42;
  margin: 0;
}
.store-report-content .center-chart-area .center-chart-header .subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 175%;
  color: #232d42;
  margin: 0;
}
@media (max-width: 575px) {
  .store-report-content .center-chart-area .center-chart-header {
    gap: 5px;
  }
}
@media (max-width: 1499px) {
  .store-report-content .center-chart-area {
    width: 450px;
  }
}
@media (max-width: 1335px) {
  .store-report-content .center-chart-area {
    width: 380px;
  }
}
@media (max-width: 1255px) {
  .store-report-content .center-chart-area {
    width: 300px;
  }
}
@media (max-width: 991px) {
  .store-report-content {
    flex-wrap: wrap;
  }
  .store-report-content .left-content,
  .store-report-content .center-chart-area {
    width: 100%;
  }
}
.store-report-content .left-content {
  gap: 11px;
  display: flex;
  flex-wrap: wrap;
}
.store-report-content .left-content-card {
  background: #fff;
  box-shadow: 0 10px 13px rgba(17, 38, 146, 0.1);
  border-radius: 5px;
  padding: 20px 30px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.store-report-content .left-content-card img {
  width: 35px;
}
.store-report-content .left-content-card .info {
  width: 0;
  flex-grow: 1;
  padding-left: 21px;
}
.store-report-content .left-content-card .info .subtitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  font-family: roboto, sans-serif;
}
.store-report-content .left-content-card .info .subtitle sub {
  font-size: 80%;
  bottom: 0;
}
.store-report-content .left-content-card .info .subtext {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}
.store-report-content .left-content-card .info .info-txt {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #00aa6d;
  text-align: right;
  gap: 5px;
}
@media (min-width: 992px) and (max-width: 1280px) {
  .store-report-content .left-content-card {
    padding: 20px;
  }
  .store-report-content .left-content-card .info .subtitle {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 991px) {
  .store-report-content .left-content {
    flex-direction: row;
  }
  .store-report-content .left-content-card {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .store-report-content .left-content-card {
    width: 100%;
  }
}
@media (max-width: 374px) {
  .store-report-content .left-content-card {
    padding: 20px;
  }
  .store-report-content .left-content-card .info .subtitle {
    font-size: 24px;
    line-height: 28px;
  }
}
.store-report-content .right-content .card-header {
  box-shadow: none;
}
.store-report-content .right-content .card-header .card-title {
  font-size: 18px;
}
.pie-chart .apexcharts-canvas {
  margin: 0 auto;
}
.pie-chart .apexcharts-legend .apexcharts-legend-series {
  margin-top: 10px !important;
}
.apex-legends {
  color: #758590;
  display: flex;
  flex-wrap: wrap;
  max-width: 310px;
  margin: 0 auto;
  padding: 0 10px;
}
.apex-legends div {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
}
.apex-legends div::before {
  width: 9px;
  border-radius: 50%;
  height: 9px;
  content: "";
  margin-right: 6px;
  display: block;
  transform: translateY(5px);
}
.apex-legends div.before-bg-004188::before {
  background: #004188;
}
.apex-legends div.before-bg-A2CEEE::before {
  background: #a2ceee;
}
.apex-legends div.before-bg-0177CD::before {
  background: #0177cd;
}
.apex-legends div:not(:last-child) {
  margin-right: 15px;
}
.total--orders {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 12px;
}
@media screen and (width < 1650px) {
  .total--orders {
    font-size: 10px;
  }
}
.total--orders h3 {
  margin: 0;
  font-weight: 700 !important;
  font-size: 19px;
  line-height: 23px;
  color: #334257 !important;
}
@media screen and (max-width: 575px) {
  .total--orders h3 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 1650px) {
  .total--orders h3 {
    font-size: 24px;
    line-height: 24px;
  }
}
.text-004188 {
  color: #004188;
}
.text-A2CEEE {
  color: #a2ceee !important;
}
.text-0177CD {
  color: #0177cd !important;
}
.text-F5A200 {
  color: #f5a200 !important;
}
.text-56b98f {
  color: #56b98f !important;
}
.payment-statistics-shadow {
  background: #fff;
  box-shadow: 0 10px 13px rgba(17, 38, 146, 0.1);
  border-radius: 5px;
  border: none;
}
.store-center-chart {
  height: 340px !important;
}
.store-center-chart.style-2 {
  height: 300px !important;
}
@media (max-width: 1650px) {
  .store-center-chart {
    height: 300px !important;
  }
}
@media (max-width: 575px) {
  .store-center-chart {
    height: 250px !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1255px) {
  .store-center-chart {
    height: 250px !important;
  }
}
.earning-statistics-content {
  text-align: center;
}
.earning-statistics-content .subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: #232d42;
  margin-bottom: 18px;
}
.earning-statistics-content .title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #334257;
  display: inline-block;
  margin-bottom: 15px;
}
.earning-statistics-content .subtxt {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8a92a6;
  margin-bottom: 30px;
}
@media (min-width: 1440px) {
  .earning-statistics-content .subtitle {
    font-size: calc(16px + 0.1vw);
  }
  .earning-statistics-content .title {
    font-size: calc(28px + 0.2vw);
  }
  .earning-statistics-content .subtxt {
    font-size: calc(12px + 0.1vw);
  }
}
.p-name {
  max-width: 150px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mw-145 {
  max-width: 145px;
}
.wishlisted-p-name {
  min-width: 240px;
}
.__badge {
  padding: 6px 10px 5px;
  border-radius: 15px;
}
.__badge[class*="soft-success"] {
  color: #16b559;
  background: rgba(22, 181, 89, 0.1);
}
.__badge[class*="soft-primary"] {
  color: #0286ff;
  background: rgba(2, 134, 255, 0.1);
}
.__badge[class*="soft-danger"] {
  color: #ff6767;
  background: rgba(255, 103, 103, 0.1);
}
.white-space-initial {
  white-space: initial !important;
}
@media (width < 575px) {
  .filter-btn {
    text-align: right;
  }
}
.tooltip.fade.in {
  opacity: 1;
}
.trx-y-2 {
  transform: translateY(-2px);
}
.trx-y-2 img {
  width: 15px !important;
}
.info-img {
  width: 15px !important;
}
.label-hide .apexcharts-tooltip-text-y-value {
  display: none !important;
}
.__coba-aspect img {
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
}
.__coba-aspect .spartan_item_wrapper {
  margin-bottom: 0 !important;
  height: unset !important;
}
.__coba-aspect .file_upload {
  height: unset !important;
}
.p-5px {
  padding: 5px;
}
.aspect-1 {
  aspect-ratio: 1;
  -o-object-fit: cover;
  object-fit: cover;
}
.word-break {
  white-space: initial;
}
.max-w-360px {
  max-width: 360px;
}
.sidebar--search-form {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
  background: #055;
}
aside .navbar-vertical-content {
  padding-top: 0;
}
.width-24 {
  max-width: 24px;
}
.max-content {
  width: max-content;
}
.gap-3 {
  gap: 16px;
}
.w-100px {
  width: 100px;
}
.mh-80px {
  max-height: 80px;
}
.inputDnD .form-control-file {
  position: relative;
  inline-size: 100%;
  block-size: 100%;
  min-block-size: 8rem;
  outline: none;
  visibility: hidden;
  cursor: pointer;
  border-radius: 10px;
}
.inputDnD .form-control-file:before {
  content: attr(data-title);
  position: absolute;
  inset-inline-start: 0;
  inline-size: 100%;
  min-block-size: 100%;
  line-height: 2em;
  opacity: 1;
  visibility: visible;
  text-align: center;
  border: 1px dashed currentColor;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.inputDnD .form-control-file:hover:before {
  border-style: solid;
}
.img-fit {
  max-height: 100%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.opacity-0 {
  opacity: 0 !important;
}
#offline_payment_top_part,
#offline_payment_bottom_part {
  background-color: #f0f1f1;
  cursor: not-allowed;
  opacity: 0.5;
  user-select: none;
}
#offline_payment_top_part.active,
#offline_payment_bottom_part.active {
  background-color: #dff2fa !important;
  cursor: default;
  opacity: 1;
  user-select: all;
  animation: zoomInOut 0.3s 0.2s ease-in-out 1;
}
#paymentInfoModalButton,
#bkashInfoModalButton {
  cursor: pointer;
  user-select: none;
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
#offline_payment_bottom_part .form-control[readonly] {
  background-color: #f8fafd;
}
.modal .owl-carousel .owl-item img {
  display: inline;
  width: auto;
}
.custom-radio {
  padding-right: 20px;
  margin-bottom: 10px;
}
.form-floating {
  margin-bottom: 15px;
}
[data-dismiss="modal"] {
  cursor: pointer;
}
.offline-payment--information-single .name {
  width: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.offline-payment--information-single .name::after {
  content: ":";
  display: inline-block;
}
.offline-payment--information-single .info {
  width: calc(100% - 60px);
  padding-left: 20px;
  font-weight: 500;
  color: var(--title-clr);
}
.offline-payment--information-single {
  align-content: inherit;
}
