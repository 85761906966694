@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Inter', sans-serif;
/* font-family: 'Roboto', sans-serif; */
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #1e2022;
}

.footer-offset {
  margin-bottom: 3.5rem;
}

.dropdown{
  position: absolute;
  top: 3.0rem;
  right: 2rem;
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
  margin: 10px 0;
  -webkit-box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.12);
  box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.12);
  background-color: white;
  min-width: 150px;
  width: max-content;
  
}

.dropdown ul li{
  padding: 10px;
  font-size: 13px;
  color: #2e2e2e;
  display: flex;
  align-items: center;
  gap: 6px;
  /* &:hover{
      background-color: #EEF8FF;
      cursor: pointer;
  } */
}

.notification-dropdown{
  min-width: 25rem!important;
}

.notification-dropdown.dropdown-menu-right{
  right: -186px;
}

@media (min-width:900px){
  .notification-dropdown{
    min-width: 30rem!important;
  }
  
  .notification-dropdown.dropdown-menu-right{
    right: -100px;
  } 
}

@media (min-width: 1200px){
  .footer {
    margin-left: 16.25rem;
  }
  .sidebar-close-icon{
    display: none;
  }
}
